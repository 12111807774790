import React, { useState, useEffect } from 'react';
import { post } from "../services/http-service";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import storageService from 'src/services/storage-service';
import SelectAllIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/Clear';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import { toast } from 'react-toastify';

const FIELDS = [
  { label: 'Ditt meddelande', required: true, multiline: true, rows: 4 },
  { label: 'E-postadress', required: true },
  { label: 'Namn', required: true },
  { label: 'Företag/Organisation' },
  { label: 'Avdelning/Förvaltning' },
  { label: 'Telefon' },
];

function ContactUsDialog({ open, onClose, initalOption, initialMessage }) {

  const [option, setOption] = useState('Support IT');
  const [errorMessage, setErrorMessage] = useState('');
  const [fields, setFields] = useState(FIELDS);

  const OPTIONS = [
    'Support IT',
    'Beställ dom',
    'Juridisk rådgivning',
    'Prisuppgift',
    'Förbättringsförslag',
    'Övrigt',
  ]

  useEffect(() => {
    setOption(initalOption);
    setErrorMessage('');
    const user = storageService.load();

    FIELDS[0].value = initialMessage || '';
    FIELDS[1].value = user.email && user.email.indexOf('@') > 0 ? user.email : '';
    FIELDS[2].value = user.displayName;
    FIELDS[3].value = user.organizationName;


  }, [initalOption, initialMessage, open])

  const changeField = (index, value) => {

    const newFields = [...fields];
    newFields[index].value = value;
    setFields(newFields);
  }

  const onSubmit = async () => {

    for (let i = 0; i < 3; i++) {
      if (!fields[i].value) {
        return setErrorMessage(`${FIELDS[i].label} är obligatoriskt`);
      }
    }

    const form = new FormData();
    form.append('subject', option);
    form.append('message', fields[0].value);
    form.append('email', fields[1].value);
    form.append('name', fields[2].value);
    form.append('company', fields[3].value);
    form.append('org', fields[4].value);
    form.append('phone', fields[5].value);

    await post('/contact', form, true);

    setErrorMessage('');

    toast.success("Ditt meddelande har skickats");

    onClose(true);
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose(true)}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Kontakta Oss</DialogTitle>
      <Box width={600} >
        <DialogContent dividers>

          {errorMessage && (
            <Box mb={4}>
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Box>
          )}


          <FormGroup>
            <FormControl size="small" margin='dense' fullWidth>
              <InputLabel >Vad gäller ditt ärende</InputLabel>
              <Select
                value={option}
                label="Vad gäller ditt ärende"
                onChange={(event) => setOption(event.target.value)}
              >
                {OPTIONS.map((option, index) =>
                  <MenuItem key={index} value={option}>{option}</MenuItem>
                )}
              </Select>
            </FormControl>
            
            {option === 'Juridisk rådgivning' && (
              <Typography variant="body2" color="text.secondary">
                Infosoc juridiska rådgivning omfattar rätten att ställa frågor och diskutera ärenden med våra jurister. Detta innebär att vi tittar på er fråga i upp till 30 minuter och ger er vägledning kring densamma utifrån vår databas innehåll. I praktiken innebär det att juristen tittar på tidigare praxis gällande frågeställningen eller om vägledning återfinns i t.ex. förarbeten och lagtext. Normal svarstid 1-2 arbetsdagar.
              </Typography>
            )}

            {fields.map((field, index) =>
            (
              <TextField
                key={index}
                onChange={(e) => changeField(index, e.target.value)}
                value={field.value}
                multiline={field.multiline}
                rows={field.rows || 1}
                margin="normal"
                required={field.required}
                fullWidth
                label={field.label}
              />
            ))}
          </FormGroup>

        </DialogContent>
      </Box>
      <DialogActions>
        <Button variant="contained" onClick={onSubmit}>Skicka</Button>
        <Button onClick={() => onClose(false)}>Avbryt</Button>
      </DialogActions>
    </Dialog >
  )

}

ContactUsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  initalOption: PropTypes.string,
  initialMessage: PropTypes.string,
};

export default ContactUsDialog;