import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tips from "./Tips";
import Banner from "./Banner";
import Experts from "./Experts";
import Courses from "./Courses";
import News from "./News";
import LoadingButton from '@mui/lab/LoadingButton';
import PreviewDialog from 'src/components/PreviewDialog';
import Sbt from 'src/components/Sbt';
import SblDialog from 'src/components/SblDialog';
import SblChangesDialog from 'src/components/SblChangesDialog';

import { get } from "../../services/http-service";

const HomePage = ({currentUser}) => {

  document.title = 'Infosoc Rättsdata';

  const [loading, setLoading] = useState(true);
  const [previewSbtId, setPreviewSbtId] = useState(-1);
  const [previewSblId, setPreviewSblId] = useState(-1);
  const [previewSbaId, setPreviewSbaId] = useState(-1);
  const [previewNews, setPreviewNews] = useState(null);
  
  const [previewTitle, setPreviewTitle] = useState("");

  const [data, setData] = useState({
    tips: [],
    experts: [],
    teachableCourses: [],
    databaseDisplay: [],
    postCollections: []
  });

  const load = async () => {
    let response = await get("/news");
    setData(response);
    setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }} ml={4} mt={12} mb={6}>
      <Grid container spacing={3}>
        <Grid item xs style={{ maxWidth: 1400 }}>
          <Typography variant="h5" gutterBottom>
            Välkommen {currentUser? currentUser.organizatonDisplayName: ''}
          </Typography>
          <Box mt={2}>
            <Typography variant="body1" gutterBottom>
              Infosoc juridiska tjänst, som innehåller databas, nyhetsbrev samt rådgivning, ger dig ett komplett stöd i din yrkesutövning. Tjänsten är användarvänlig och hjälper dig att fatta trygga och rättssäkra beslut. Besök menyn till vänster för att navigera bland databasens innehåll.
            </Typography>
          </Box>
          <Box mt={2}>
            <Banner entries={data.databaseDisplay} loading={loading} />
          </Box>
          <Box mt={2}>
            <News
              entries={data.postCollections}
              loading={loading}
              onSelectedSbl={(id, newDocument) => { newDocument ? setPreviewSblId(id) : setPreviewSbaId(id); }}
              onSelectedSbt={(id, title) => { setPreviewSbtId(id); setPreviewTitle(title) }}
              onSelectedNews={(title, text) => { setPreviewNews(text); setPreviewTitle(title) }}
            />
          </Box>
        </Grid>
        <Grid item style={{ width: 330 }}>
          <Box mr={2}>
            <Tips entries={data.tips} loading={loading} onSelected={(id, title) => { setPreviewSbtId(id); setPreviewTitle(title) }} />
            <Box mt={2}>
              <Experts entries={data.experts} loading={loading} />
            </Box>
            <Box mt={2}>
              <Courses entries={data.teachableCourses} loading={loading} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <PreviewDialog open={previewSbtId !== -1} onClose={() => setPreviewSbtId(-1)} title={previewTitle} link={`/rattsfall/${previewSbtId}`}>
        {previewSbtId !== -1 && <Box mr={4}><Sbt id={previewSbtId} /></Box>}
      </PreviewDialog>

      <PreviewDialog open={!!previewNews} onClose={() => setPreviewNews(null)} title={previewTitle} >
        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: previewNews }}></Typography>
      </PreviewDialog>

      <SblDialog open={previewSblId !== -1} onClose={() => setPreviewSblId(-1)} id={previewSblId} />
      <SblChangesDialog open={previewSbaId !== -1} onClose={() => setPreviewSbaId(-1)} id={previewSbaId} />


    </Box>
  );
}

export default HomePage;
