import React, { useState, useEffect, useRef, forwardRef } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import storageService from 'src/services/storage-service';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import OrgIcon from '@mui/icons-material/People';
import PropTypes from 'prop-types';
import {
	get,
	post,
	downloadFile,
	UI_URL,
	API_URL,
} from 'src/services/http-service';
import { Grid } from '@mui/material';

import WatchListIcon from '@mui/icons-material/Notifications';
import FavoriteIcon from '@mui/icons-material/Star';
import ShareIcon from '@mui/icons-material/Share';
import SearchIcon from '@mui/icons-material/Search';
import InvestigationIcon from '@mui/icons-material/ArticleOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import FullTextIcon from '@mui/icons-material/FormatAlignJustify';

import { TreeItem, SimpleTreeView } from '@mui/x-tree-view';

import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ShareDialog from './ShareDialog';
import WatchSbtDialog from './WatchSbtDialog';
import FavoriteDialog from './FavoriteDialog';
import HighlightSearch from './HighlightSearch';
import AddToInvestigationDialog from './AddToInvestigationDialog';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Loading from './Loading';
import { toast } from 'react-toastify';

import SbtPdf from './SbtPdf';
import { stripHtml } from 'src/services/html-utils';

import AttachmentViewer from './AttachmentViewer';

function Sbt({
	id,
	fileName,
	hideButtons,
	singleColumn,
	onLoaded,
	query,
	onTitleUpdate,
	matchPhrase,
}) {
	const [loading, setLoading] = useState(true);
	const [showShareDialog, setShowShareDialog] = useState(false);
	const [shareSubject, setShareSubject] = useState('');
	const [shareText, setShareText] = useState('');
	const [showFavoriteDialog, setShowFavoriteDialog] = useState(false);
	const [showInvestigationDialog, setShowInvestigationDialog] =
		useState(false);
	const [displaySearch, toggleSearch] = useState(false);

	const [watchSbtId, setWatchSbtId] = useState(-1);
	const [selectedInfoIndex, setSelectedInfoIndex] = useState(1);

	const isLoggedIn = storageService.isLoggedIn();
	const navigate = useNavigate();

	const anchor = useRef(null);

	const [data, setData] = useState({
		categories: {},
		content: {},
		lawplaces: {},
	});

	const load = async () => {
		setLoading(true);

		const ref = id || fileName;
		const response = await get(
			`/lawreports/${ref}/full${query ? `?q=${query}` : ''}`
		);
		setData(response);
		setShareSubject(response.content.titleFull);
		setShareText(
			stripHtml(response.content.preamble.trim()) +
				`\n\nLäs mer ${UI_URL}/rattsfall/${ref}`
		);
		setLoading(false);
		onLoaded(response);

		const isTitleUpdateFunction = typeof onTitleUpdate === 'function';
		const startPageTitle = response.content.startPageTitle?.trim();
		const preamble = response.content.preamble?.trim();

		if (isTitleUpdateFunction) {
			if (startPageTitle && startPageTitle !== preamble) {
				onTitleUpdate(startPageTitle);
			} else {
				onTitleUpdate('');
			}
		}
	};

	useEffect(() => {
		load();
	}, [id, fileName]);

	useEffect(() => {
		const handleKeyPress = (e) => {
			// Ctrl/cmd + F
			if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) {
				e.preventDefault();
				toggleSearch(!displaySearch);
			}
		};

		window.addEventListener('keydown', handleKeyPress);
		return () => {
			// cleanup
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [displaySearch]);

	if (loading) {
		return (
			<Box m={6}>
				<Loading />
			</Box>
		);
	}

	const LabelValue = ({ label, value }) => {
		if (!value) {
			return <></>;
		}

		return (
			<Grid item xs={5}>
				<Typography variant="body2">
					<span style={{ color: '#666' }}>{label}</span> {value}
				</Typography>
			</Grid>
		);
	};

	const saveFavorite = async (folderId, folderName) => {
		const form = new FormData();
		form.append('name', data.content.titleFull);
		form.append('loginFolderId', folderId);
		form.append('linkType', 'lawreport');
		form.append('linkId', data.content.id);

		await post('/favorites', form, true);

		setShowFavoriteDialog(false);

		toast.success('Sparat i ' + folderName);
	};

	const saveToInvestigation = async (investigationId, title, newTitle) => {
		const form = new FormData();
		form.append('name', data.content.titleFull);
		form.append('linkType', 'lawreport');
		form.append('linkId', data.content.id);

		if (newTitle) {
			form.append('investigationName', newTitle);
		}

		await post(`/investigations/${investigationId}/entries`, form);

		setShowInvestigationDialog(false);

		toast.success(`Sparat i ${newTitle || title}`);
	};

	const closeWatchlistDialog = (saved) => {
		setWatchSbtId(-1);
	};

	const onNodeSelect = (args, nodeId) => {
		window.open(`/rattsfallsokning/1?&arg=${nodeId}`);
		args.preventDefault();
	};

	const renderTree = (node) => (
		<TreeItem key={node.id} itemId={'' + node.id} label={node.title}>
			{Array.isArray(node.children)
				? node.children.map((node) => renderTree(node))
				: null}
		</TreeItem>
	);

	const HighlightSearcher = forwardRef((props, ref) => (
		<HighlightSearch
			anchor={ref}
			autoFocus
			onClose={() => toggleSearch(false)}
			{...props}
		/>
	));

	const findIds = (node, result) => {
		result.push('' + node.id);

		node.children.forEach((child) => findIds(child, result));
	};

	const clickShowShareDialog = () => {
		setShowShareDialog(true);
	};

	const getActualOrganizations = (orgs) =>
		orgs.filter((o) => o && o.length > 0);

	const allCategoryIds = [];
	findIds(data.categories, allCategoryIds);

	const showAttachment =
		data.content.showFullTextPdf && data.content.fullTextAttachmentId;

	return (
		<Grid container>
			<Grid item xs>
				<Box
					sx={
						showAttachment
							? {}
							: { marginRight: 2, paddingBottom: 10 }
					}
				>
					{!data.content.showFullTextPdf && (
						<Box>
							<Typography variant="h6">
								{data.content.isQuestion
									? 'Fråga'
									: data.content.titleFull}
							</Typography>

							{data.content.attachments.map((a) => (
								<a
									href={`${API_URL}/attachment/${a.id}`}
									target="_blank"
									key={a.name}
									rel="noreferrer"
								>
									{a.name}
								</a>
							))}
						</Box>
					)}

					{!data.content.hideLawReportDetails && (
						<Box mt={2}>
							<Grid container>
								<LabelValue
									label={
										data.content.isQuestionOrAnalysis
											? 'Datum'
											: 'Beslutsdatum'
									}
									value={data.content.decisionDate}
								/>
								<LabelValue
									label="Pub. beteckning"
									value={data.content.publication}
								/>
								<LabelValue
									label="Målnr/Dnr"
									value={data.content.caseNumber}
								/>
							</Grid>
						</Box>
					)}

					<Box mt={2}>
						<Stack
							direction="row"
							spacing={1}
							useFlexGap
							flexWrap="wrap"
						>
							{getActualOrganizations(
								data.content.organizations
							).map((org) => (
								<Link
									key={org}
									href={`/rattsfallsokning/6?arg=${org}`}
									target="_blank"
								>
									<Chip
										icon={<OrgIcon />}
										label={org}
										variant="filled"
										style={{ cursor: 'pointer' }}
									/>
								</Link>
							))}
						</Stack>
					</Box>

					{!hideButtons && (
						<Box mt={2}>
							<Toolbar disableGutters={true}>
								<Stack
									direction="row"
									spacing={1}
									useFlexGap
									flexWrap="wrap"
								>
									{data.content.showWatchListButton &&
										isLoggedIn &&
										!data.content.showFullTextPdf && (
											<Button
												size="small"
												startIcon={<WatchListIcon />}
												disableElevation
												variant="outlined"
												color="secondary"
												onClick={() =>
													setWatchSbtId(
														data.content.id
													)
												}
											>
												Bevaka
											</Button>
										)}

									{isLoggedIn && (
										<>
											<Button
												size="small"
												startIcon={<FavoriteIcon />}
												disableElevation
												variant="outlined"
												color="secondary"
												onClick={() =>
													setShowFavoriteDialog(true)
												}
											>
												Favorit
											</Button>
										</>
									)}

									{!data.content.showFullTextPdf && (
										<Button
											size="small"
											startIcon={<InvestigationIcon />}
											disableElevation
											variant="outlined"
											color="secondary"
											onClick={() =>
												setShowInvestigationDialog(true)
											}
										>
											Utredning
										</Button>
									)}

									{!data.content.showFullTextPdf && (
										<PDFDownloadLink
											document={<SbtPdf data={data} />}
											fileName={`${data.content.titleFull}.pdf`}
											style={{ textDecoration: 'none' }}
										>
											{({
												blob,
												url,
												loading,
												error,
											}) => (
												<Button
													size="small"
													startIcon={<DownloadIcon />}
													disableElevation
													variant="outlined"
												>
													Ladda ner
												</Button>
											)}
										</PDFDownloadLink>
									)}

									{data.content.showFullTextPdf && (
										<Button
											onClick={() =>
												downloadFile(
													`${API_URL}/attachment/${data.content.fullTextAttachmentId}`,
													`${data.content.titleFull}.pdf`
												)
											}
											size="small"
											startIcon={<DownloadIcon />}
											disableElevation
											variant="outlined"
										>
											Ladda ner
										</Button>
									)}

									<Button
										onClick={() => clickShowShareDialog()}
										size="small"
										startIcon={<ShareIcon />}
										disableElevation
										variant="outlined"
									>
										Dela
									</Button>

									{!data.content.showFullTextPdf && (
										<Link
											href={`/rattsfall/${data.content.id}/skrivut`}
											target="_blank"
											style={{ textDecoration: 'none' }}
										>
											<Button
												size="small"
												startIcon={<PrintIcon />}
												disableElevation
												variant="outlined"
											>
												Skriv ut
											</Button>
										</Link>
									)}
									{data.content.hasFullTextFile && (
										<Link
											href={`/rattsfall/${data.content.id}/fulltext`}
											target="_blank"
											style={{ textDecoration: 'none' }}
										>
											<Button
												size="small"
												startIcon={<FullTextIcon />}
												disableElevation
												variant="outlined"
											>
												Hela domen
											</Button>
										</Link>
									)}
									<Button
										onClick={() =>
											toggleSearch(!displaySearch)
										}
										size="small"
										startIcon={<SearchIcon />}
										disableElevation
										variant="outlined"
									>
										Sök
									</Button>
								</Stack>
							</Toolbar>
							{!data.content.showFullTextPdf && displaySearch && (
								<Box mt={1} alignItems={'right'}>
									<HighlightSearcher ref={anchor} />
								</Box>
							)}
						</Box>
					)}

					<Box ref={anchor}>
						{showAttachment && (
							<AttachmentViewer
								id={data.content.fullTextAttachmentId}
								offsetTop={260}
								query={query}
								matchPhrase={matchPhrase}
								showSearch={displaySearch}
								onSearchToggle={() =>
									toggleSearch(!displaySearch)
								}
							/>
						)}

						{!data.content.isAnalysis &&
							!data.content.showFullTextPdf && (
								<Box mt={1}>
									{!data.content.isAnalysis && (
										<Typography variant="h6">
											Sammanfattning
										</Typography>
									)}
									<Box mt={1}>
										<Typography
											variant="body2"
											dangerouslySetInnerHTML={{
												__html: data.content.preamble,
											}}
										/>
									</Box>

									<Box mt={2}>
										{data.content.isQuestion && (
											<Typography variant="h6">
												Svar
											</Typography>
										)}
										{!data.content.isQuestion && (
											<Typography variant="h6">
												Kommentar
											</Typography>
										)}
									</Box>
								</Box>
							)}

						{!data.content.showFullTextPdf && (
							<Box mt={1}>
								<Typography variant="body2">
									<span
										dangerouslySetInnerHTML={{
											__html: data.content.text,
										}}
									/>
								</Typography>
							</Box>
						)}

						{!data.content.showFullTextPdf &&
							data.content.isLegalComment &&
							data.content.responsibleLawyer && (
								<Box mt={2}>
									<LabelValue
										label="Produktansvarig jurist"
										value={data.content.responsibleLawyer}
									/>
								</Box>
							)}
					</Box>
				</Box>
				{singleColumn && (
					<Box>
						<Box mt={4}>
							<Typography variant="h6">Lagrum</Typography>
						</Box>
						{data.lawplaces.entries.map((lp) => (
							<Box mt={2} ml={1} key={lp.name}>
								<Typography variant="subtitle1">
									{lp.name}
								</Typography>
								<Box ml={1}>
									{lp.links.map((link) => (
										<Box m={1} key={link.description}>
											<Typography variant="subtitle2">
												- {link.description}
											</Typography>
										</Box>
									))}
								</Box>
							</Box>
						))}
					</Box>
				)}
			</Grid>
			{!singleColumn && !data.content.isSbtPlus && (
				<Grid item style={{ width: 300 }} xs={singleColumn ? 12 : null}>
					<Paper
						style={{ background: '#fff5d0' }}
						elevation={singleColumn ? 0 : 1}
					>
						<Box p={4} mt={4}>
							<Tabs
								value={selectedInfoIndex}
								onChange={(event, newValue) =>
									setSelectedInfoIndex(newValue)
								}
							>
								<Tab label="Lagrum" value={1} />
								{!data.content.isLegalComment && (
									<Tab label="Teman" value={2} />
								)}
							</Tabs>

							{selectedInfoIndex === 1 && (
								<>
									{data.lawplaces.entries.map((lp) => (
										<Box mt={2} key={lp.name}>
											<Typography variant="subtitle1">
												{lp.name}
											</Typography>
											<Box ml={1}>
												{lp.links.map((link, index) => (
													<Box m={1} key={index}>
														<Typography variant="subtitle2">
															<Link
																target="_blank"
																href={`/lag/${
																	link.sblDocumentId
																}?k=${
																	link.chapter ||
																	''
																}&p=${
																	link.paragraph ||
																	''
																}&r=${
																	link.additional ||
																	''
																}`}
															>
																{
																	link.description
																}
															</Link>
														</Typography>
													</Box>
												))}
											</Box>
										</Box>
									))}
								</>
							)}

							{selectedInfoIndex === 2 && (
								<Box mt={2} ml={-4}>
									<SimpleTreeView
										onItemFocus={onNodeSelect}
										aria-label="Associerade teman"
										defaultExpandedItems={allCategoryIds}
									>
										{data.categories.children.map((child) =>
											renderTree(child)
										)}
									</SimpleTreeView>
								</Box>
							)}
						</Box>
					</Paper>
				</Grid>
			)}

			<ShareDialog
				open={showShareDialog}
				onClose={() => setShowShareDialog(false)}
				templateSubject={shareSubject}
				templateText={shareText}
			/>
			<WatchSbtDialog
				id={watchSbtId}
				onClose={(saved) => closeWatchlistDialog(saved)}
			/>
			<FavoriteDialog
				open={showFavoriteDialog}
				onClose={(selectedFavoriteFolderId, title) =>
					selectedFavoriteFolderId
						? saveFavorite(selectedFavoriteFolderId, title)
						: setShowFavoriteDialog(false)
				}
			/>
			<AddToInvestigationDialog
				open={showInvestigationDialog}
				onClose={(investigationId, title, newTitle) =>
					investigationId
						? saveToInvestigation(investigationId, title, newTitle)
						: setShowInvestigationDialog(false)
				}
			/>
		</Grid>
	);
}

Sbt.propTypes = {
	id: (props, propName, componentName) => {
		console.log(props);
		if (!props.id && !props.fileName) {
			return new Error(
				`One of props 'id' or 'fileName' was not specified in '${componentName}'.`
			);
		}

		if (typeof props.id !== 'number') {
			return new Error(
				`Invalid prop '${propName}' of type '${typeof props.id}' supplied to '${componentName}', expected 'number'.`
			);
		}
	},

	fileName: (props, propName, componentName) => {
		if (!props.id && !props.fileName) {
			return new Error(
				`One of props 'id' or 'fileName' was not specified in '${componentName}'.`
			);
		}

		if (typeof props.fileName !== 'string') {
			return new Error(
				`Invalid prop '${propName}' of type '${typeof props.fileName}' supplied to '${componentName}', expected 'string'.`
			);
		}
	},
	query: PropTypes.string,
	hideButtons: PropTypes.bool,
	singleColumn: PropTypes.bool,
	onLoaded: PropTypes.func,
	onTitleUpdate: PropTypes.func,
};

Sbt.defaultProps = {
	hideButtons: false,
	singleColumn: false,
	onLoaded: () => {},
	query: '',
};

export default Sbt;
