import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { get } from "src/services/http-service";
import DenseList from 'src/components/DenseList';
import SblDialog from 'src/components/SblDialog';

function InformationMaterialPage() {

  const [lawDocuments, setLawDocuments] = useState([]);
  const [sblId, setSblId] = useState(-1);

  document.title = 'Informationsmaterial | Infosoc';

  const load = async () => {
    const response = await get('/lawdocuments/7/categories');
    setLawDocuments(response.entries);
  }

  const selectEntry = (entry) => {
    setSblId(entry.id);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }} mt={10} ml={2}>
      <DenseList entries={lawDocuments} offsetTop={120} showFilter={true} onSelect={(index, entry) => selectEntry(entry)} />
      <SblDialog open={sblId !== -1} onClose={() => setSblId(-1)} id={sblId} />
    </Box>
  );
}


export default InformationMaterialPage;