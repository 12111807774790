import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { get } from "src/services/http-service";
import CardView from 'src/components/CardView';
import SblDialog from 'src/components/SblDialog';
import Loading from 'src/components/Loading';

function LegalCommentsPage() {

  const [lawDocuments, setLawDocuments] = useState([]);
  const [sblId, setSblId] = useState(-1);
  const [loading, setLoading] = useState(true);

  document.title = 'Lagkommentarer | Infosoc';

  const load = async () => {
    const response = await get('/lawdocuments/legalcomments');
    setLawDocuments(response.entries);
    setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  if (loading) {
    return <Box mt={10} ml={2}><Loading /></Box>
  }

  const showLegalComments = (entry) => {
    window.open(`/rattsfallsokning/8?&lag=${entry.id}&index=-1&k=x`);
  }

  return (
    <Box sx={{ flexGrow: 1 }} mt={10} ml={2} mb={10}>
      <CardView entries={lawDocuments} showFilter={true} onSelect={(index, entry, secondaryAction) => secondaryAction? showLegalComments(entry) : setSblId(entry.id) } actionLabel="Visa lag" />
      <SblDialog open={sblId !== -1} onClose={() => setSblId(-1)} id={sblId} />
    </Box>
  );
}


export default LegalCommentsPage;