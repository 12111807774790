import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CardView from 'src/components/CardView';
import SblDialog from 'src/components/SblDialog';
import { post } from 'src/services/http-service';
import OfferDialog from 'src/components/OfferDialog';
import Loading from 'src/components/Loading';
import { getLawDocumentsByCategory } from 'src/services/api-utils';

function EBooksPage() {
	const [lawDocuments, setLawDocuments] = useState([]);
	const [sblId, setSblId] = useState(-1);
	const [token, setToken] = useState('');
	const [offerDialogSelected, setOfferDialogSelected] = useState(null);
	const [loading, setLoading] = useState(true);

	document.title = 'E-litteratur | Infosoc';

	const load = async () => {
		const response = await getLawDocumentsByCategory(9);
		setLawDocuments(response.entries);
		setLoading(false);
	};

	const viewEBook = async (entry) => {
		if (!entry.access) {
			setOfferDialogSelected(entry.title);
			return;
		}

		const response = await post('/categories/e-literature', {}, true);
		let token = await response.text();

		let encodedToken = '';

		for (var i = 0; i < token.length; i++) {
			let intValue = parseInt(token.substring(i, i + 1));
			let isEvent = i % 2 === 0;

			encodedToken =
				encodedToken + (isEvent ? intValue - 1 : intValue + 2);
		}

		setToken(encodedToken);

		setSblId(entry.id);
	};

	useEffect(() => {
		load();
	}, []);

	if (loading) {
		return (
			<Box mt={10} ml={2}>
				<Loading />
			</Box>
		);
	}

	return (
		<Box sx={{ flexGrow: 1 }} mt={10} ml={2}>
			<CardView
				isEBook={true}
				entries={lawDocuments}
				showFilter={true}
				onSelect={(index, entry) => viewEBook(entry)}
				actionLabel="Visa PDF"
			/>
			<SblDialog
				disableNewWindow={true}
				hideRightColumn={true}
				open={sblId !== -1}
				onClose={() => setSblId(-1)}
				id={sblId}
				token={token}
			/>
			<OfferDialog
				open={offerDialogSelected !== null}
				onClose={() => setOfferDialogSelected(null)}
				title="E-litteratur"
				selected={offerDialogSelected}
			/>
		</Box>
	);
}

export default EBooksPage;
