import { useState, useEffect, useLayoutEffect, useCallback, forwardRef, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import storageService from 'src/services/storage-service';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { get, post, UI_URL } from 'src/services/http-service';
import { Grid } from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Star';
import ShareIcon from '@mui/icons-material/Share';
import InvestigationIcon from '@mui/icons-material/ArticleOutlined';
import PrintIcon from '@mui/icons-material/Print';
import WatchListIcon from '@mui/icons-material/Notifications';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSearchParams } from 'react-router-dom';

import ShareDialog from './ShareDialog';
import FavoriteDialog from './FavoriteDialog';
import AddToInvestigationDialog from './AddToInvestigationDialog';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import AttachmentViewer from './AttachmentViewer';

import InfosocIcon from 'src/components/InfosocIcon';

import { getSblLink } from 'src/services/link-utils';
import { API_URL } from 'src/services/http-service';

import WatchSblDialog from './WatchSblDialog';

import { toast } from 'react-toastify';
import Loading from './Loading';
import HighlightSearch from './HighlightSearch';

function Sbl({
	idOrFileName,
	onLoaded,
	linkParams,
	offsetTop,
	token,
	hideRightColumn,
	selectMode,
	onSelectSection,
	query,
}) {
	const [loading, setLoading] = useState(true);
	const [showFavoriteDialog, setShowFavoriteDialog] = useState(false);
	const [search] = useSearchParams();
	const [id, setId] = useState(-1);
	const [title, setTitle] = useState('');
	const [changeCount, setChangeCount] = useState(0);
	const [futureChangeCount, setFutureChangeCount] = useState(0);
	const [legalCommentCount, setLegalCommentCount] = useState(0);
	const [text, setText] = useState('');
	const [tree, setTree] = useState([]);
	const [expandedChapterIndex, setExpandedChapterIndex] = useState([]);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [attachments, setAttachments] = useState([]);
	const [sbtDocumentEntries, setSbtDocumentEntries] = useState([]);
	const [sbtDocumentSpecialEntries, setSbtDocumentSpecialEntries] = useState([]);
	const [sbtDocumentAllEntry, setSbtDocumentAllEntry] = useState({});
	const [showShareDialog, setShowShareDialog] = useState(false);
	const [shareSubject, setShareSubject] = useState('');
	const [shareText, setShareText] = useState('');
	const [showInvestigationDialog, setShowInvestigationDialog] = useState(false);
	const [showWatchSblDialog, setShowWatchSblDialog] = useState(false);
	const [selectedPdfAttachmentId, setSelectedPdfAttachmentId] = useState(-1);

	let anchorRef = useRef(null);

	const isLoggedIn = storageService.isLoggedIn();

	useEffect(() => {

		const load = async () => {
			setLoading(true);

			const info = await get(`/lawdocuments/${idOrFileName}/info`);
			setId(info.id);

			if (info.attachmentIdToPreview > 0) {
				setSelectedPdfAttachmentId(info.attachmentIdToPreview);
			}

			onLoaded(info);

			setText(info.title);

			let response = await get(`/lawdocuments/${info.id}` + (query ? `?q=${query}` : ''));
			setText(response.text);
			setTitle(response.title);
			setChangeCount(response.changeCount);
			setFutureChangeCount(response.futureChangeCount);
			setLegalCommentCount(response.legalCommentCount);

			response = await get(`/lawdocuments/${info.id}/index/tree`);

			setTree(response.children);
			setAttachments(response.attachments);
			setLoading(false);
		};

		load();
	}, [idOrFileName, linkParams, onLoaded, query]);

	const isShowingPdf = selectedPdfAttachmentId > 0;

	const clearSelected = () => {
		const existingSelected = document.querySelectorAll('div.selected');
		for (let i = 0; i < existingSelected.length; ++i) {
			existingSelected[i].classList.remove('selected');
		}
	};

	const clickIndex = useCallback(
		(index) => {
			clearSelected();

			if (!isShowingPdf) {
				const lawDocumentContent = document.getElementById('lawDocumentContent');
				const clickedTextNode = lawDocumentContent.childNodes[index];

				clickedTextNode.classList.add('selected');
				clickedTextNode.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
			}

			setSelectedIndex(index);
		},
		[isShowingPdf]
	);

	const getSelectionInfo = useCallback(
		(sectionIndex) => {
			for (let i = 0; i < tree.length; i++) {
				const children = tree[i].children;

				if (tree[i].index === sectionIndex) {
					return { chapterIndex: i, selected: tree[i], chapter: tree[i] };
				}

				for (let j = 0; j < children.length; j++) {
					if (children[j].index === sectionIndex) {
						return { chapterIndex: i, paragraph: children[j], chapter: tree[i] };
					}
				}
			}
		},
		[tree]
	);

	useLayoutEffect(() => {
		const loadSelection = async () => {
			if (tree.length === 0) {
				return;
			}

			const qs = linkParams ? new URLSearchParams(linkParams) : search;
			const k = qs.get('k') || '';
			const p = qs.get('p') || '';
			const r = qs.get('r') || '';
			const index = await get(
				`/lawdocuments/${id}/index?chapter=${k}&paragraph=${p}&additional=${r}`
			);

			setTimeout(() => {
				clickIndex(index);
				setExpandedChapterIndex(getSelectionInfo(index).chapterIndex);
			}, 200);
		};

		loadSelection();
	}, [tree, id, linkParams, search, clickIndex, getSelectionInfo]);

	useEffect(() => {
		const loadSbt = async () => {
			if (id < 0 || selectedIndex < 0) {
				return;
			}

			const response = await get(`/lawdocuments/${id}/${selectedIndex}/summary`);

			// Entries includes: Summaries for Förvaltningsrätt, Högsta domstolen, Hovrätt, Kammarrätt, JO, osv.
			setSbtDocumentEntries(response.entries);
			// Special entries includes: Summaries for Lagkommentarer. Analyser and Propositioner
			setSbtDocumentSpecialEntries(response.specialEntries);
			// All entry includes: Summary for All entries
			setSbtDocumentAllEntry(response.allEntry);
		};

		loadSbt();
	}, [id, selectedIndex]);

	if (loading) {
		return (
			<Box m={6}>
				<Loading />
			</Box>
		);
	}

	const clickText = (target) => {
		clearSelected();

		if (!target.classList.contains('selectable')) {
			target = target.parentElement;
		}

		const sectionIndex = [...target.parentNode.childNodes].indexOf(target);

		setSelectedIndex(sectionIndex);

		target.classList.add('selected');

		const selectionInfo = getSelectionInfo(sectionIndex);
		setExpandedChapterIndex(selectionInfo.chapterIndex);
	};

	const toggleChapter = (chapterIndex, sectionIndex) => {
		setExpandedChapterIndex(chapterIndex === expandedChapterIndex ? -1 : chapterIndex);
		clickIndex(sectionIndex);
	};

	const clickShareDialog = () => {
		const selection = getSelectionInfo(selectedIndex);
		const linkInfo =
			selection && selection.chapter
				? getSblLink(selection.chapter.title, selection.paragraph ? selection.paragraph.title : '')
				: { title: '', url: '' };
		setShareSubject(`${title} - ${linkInfo.title}`);

		const selectedDiv = document.getElementsByClassName('selected');

		if (selectedDiv.length) {
			setShareText(`${selectedDiv[0].textContent}\n\nLäs mer ${UI_URL}/lag/${id}?${linkInfo.url}`);
		} else {
			setShareText(`Läs mer ${UI_URL}/lag/${id}`);
		}

		setShowShareDialog(true);
	};

	const saveFavorite = async (folderId, folderTitle) => {
		const selection = getSelectionInfo(selectedIndex);
		const linkInfo =
			selection && selection.chapter
				? getSblLink(selection.chapter.title, selection.paragraph ? selection.paragraph.title : '')
				: { title: '', url: '' };

		const form = new FormData();
		form.append('name', `${title} - ${linkInfo.title}`);
		form.append('loginFolderId', folderId);
		form.append('linkType', 'lawdocument');
		form.append('linkId', id);
		form.append('linkParams', selectedIndex);

		await post('/favorites', form, true);

		setShowFavoriteDialog(false);

		toast.success('Sparat i ' + folderTitle);
	};

	const saveToInvestigation = async (investigationId, investigationTitle, newTitle) => {
		const selection = getSelectionInfo(selectedIndex);
		const paragraphLabel = selection.paragraph ? selection.paragraph.title : '';
		const linkInfo =
			selection && selection.chapter
				? getSblLink(selection.chapter.title, paragraphLabel)
				: { title: '', url: '' };

		const form = new FormData();
		form.append('name', `${title} - ${linkInfo.title}`);
		form.append('linkType', 'lawdocument');
		form.append('linkId', id);
		form.append('linkParams', selectedIndex);
		if (newTitle) {
			form.append('investigationName', newTitle);
		}

		await post(`/investigations/${investigationId}/entries`, form);

		setShowInvestigationDialog(false);

		toast.success('Sparat i ' + (newTitle || investigationTitle));
	};

	const fixSearchPath = (path) => {
		return path.replace('rattsfall?', 'rattsfallsokning/8?');
	};

	const clickSelectSection = () => {
		const section = getSelectionInfo(selectedIndex);
		const chapterName = section.chapter ? section.chapter.title : null;
		const paragraphName = section.paragraph ? section.paragraph.title : null;
		onSelectSection({ selectedIndex, chapterName, paragraphName });
	};

	const HighlightSearcher = forwardRef((props, ref) => (
		<HighlightSearch anchor={ref} autoScoll {...props} />
	));

	return (
		<Grid container>
			{selectedPdfAttachmentId === -1 && (
				<Grid item style={{ width: 350 }}>
					<Box height={`calc(100vh - ${offsetTop}px)`} display='flex' flexDirection='column'>
						<Box flex={1} overflow='auto'>
							{tree.map((chapter, chapterIndex) => (
								<Accordion
									key={`${chapterIndex}-${chapter.index}`}
									disableGutters
									expanded={chapterIndex === expandedChapterIndex}
									onChange={() => toggleChapter(chapterIndex, chapter.index)}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography
											variant='subtitle2'
											dangerouslySetInnerHTML={{
												__html:
													chapterIndex === expandedChapterIndex
														? `<b>${chapter.title}</b>`
														: `${chapter.title}`,
											}}></Typography>
									</AccordionSummary>
									<AccordionDetails>
										{chapterIndex === expandedChapterIndex && (
											<List dense>
												{chapter.children.map((paragraph) => (
													<ListItem key={paragraph.title} disablePadding>
														<ListItemButton
															onClick={() => clickIndex(paragraph.index)}
															selected={paragraph.index === selectedIndex}>
															<Box>
																<ListItemText
																	primary={
																		<Typography
																			variant='subtitle2'
																			dangerouslySetInnerHTML={{
																				__html:
																					paragraph.index === selectedIndex
																						? `<b>${paragraph.title}</b>`
																						: `${paragraph.title}`,
																			}}
																		/>
																	}
																	selected={paragraph.index === selectedIndex}
																/>
															</Box>
														</ListItemButton>
													</ListItem>
												))}
											</List>
										)}
									</AccordionDetails>
								</Accordion>
							))}
						</Box>
					</Box>
				</Grid>
			)}
			<Grid item xs>
				<Box
					height={`calc(100vh - ${offsetTop}px)`}
					display='flex'
					flexDirection='column'
					style={{ borderLeft: '1px solid #bbb' }}>
					<Box flex={1} overflow={isShowingPdf ? 'hidden' : 'auto'} pb={10} ref={anchorRef}>
						{!isShowingPdf && (
							<Typography
								id='lawDocumentContent'
								onClick={(event) => clickText(event.target)}
								variant='body2'
								dangerouslySetInnerHTML={{ __html: text }}
							/>
						)}
						{isShowingPdf && (
							<Box flex={1} overflow='auto'>
								<AttachmentViewer token={token} id={selectedPdfAttachmentId} />
							</Box>
						)}
					</Box>
				</Box>
			</Grid>

			{selectMode && (
				<Grid item style={{ width: 350 }}>
					<Box m={2}>
						<Box m={2}>
							<Typography color='error' variant='h6'>
								Välj lagrum du vill bevaka till vänster.
							</Typography>
						</Box>
						<Button fullWidth variant='contained' onClick={() => clickSelectSection()}>
							Bevaka valt lagrum
						</Button>
					</Box>
				</Grid>
			)}

			{!hideRightColumn && !selectMode && (
				<Grid item style={{ width: 350 }}>
					<Box>
						<Box ml={2} mr={2}>
							{legalCommentCount > 0 && (
								<Box mb={1}>
									<Typography variant='h6'>Lagkommentarer</Typography>
									<Box ml={1}>
										<Link
											href={`/rattsfallsokning/8?lag=${id}&index=-1&k=x`}
											target='_blank'
											style={{ textDecoration: 'none' }}>
											<Button startIcon={<InfosocIcon type='legalcomment' />}>
												Alla ({legalCommentCount})
											</Button>
										</Link>
									</Box>
								</Box>
							)}

							{selectedPdfAttachmentId === -1 && (changeCount > 0 || futureChangeCount > 0) && (
								<Box mb={1}>
									<Typography variant='h6'>Ändringar</Typography>
									{changeCount > 0 && (
										<Link
											style={{ textDecoration: 'none' }}
											target='_blank'
											href={`/lag/${id}/andringar`}>
											<Button>Historik ({changeCount})</Button>
										</Link>
									)}
									{futureChangeCount > 0 && (
										<Link
											style={{ textDecoration: 'none' }}
											target='_blank'
											href={`/lag/${id}/andringar?framtida=true`}>
											<Button>Framtida ändringar ({futureChangeCount})</Button>
										</Link>
									)}
								</Box>
							)}

							{attachments.length > 0 && (
								<Box mb={2}>
									<Stack>
										<Typography variant='h6'>Ladda ned</Typography>
										{attachments.map((a, index) => (
											<Box ml={1} key={index}>
												<Link
													href={`${API_URL}/attachment/${a.id}`}
													target='_blank'
													style={{ textDecoration: 'none' }}
													key={index}>
													<Typography variant='body2'>{a.name}</Typography>
												</Link>
											</Box>
										))}
									</Stack>
								</Box>
							)}
							<Box mt={2}>
								<Stack spacing={1}>
									{isLoggedIn && (
										<Button
											fullWidth
											size='small'
											startIcon={<FavoriteIcon />}
											disableElevation
											variant='outlined'
											color='secondary'
											onClick={() => setShowFavoriteDialog(true)}>
											Favorit
										</Button>
									)}

									{!isShowingPdf && isLoggedIn && (
										<Button
											size='small'
											startIcon={<WatchListIcon />}
											disableElevation
											variant='outlined'
											color='secondary'
											onClick={() => setShowWatchSblDialog(true)}>
											Bevaka
										</Button>
									)}
									{!isShowingPdf && isLoggedIn && (
										<Button
											size='small'
											startIcon={<InvestigationIcon />}
											disableElevation
											variant='outlined'
											color='secondary'
											onClick={() => setShowInvestigationDialog(true)}>
											Utredning
										</Button>
									)}
									<Button
										fullWidth
										onClick={() => clickShareDialog()}
										size='small'
										startIcon={<ShareIcon />}
										disableElevation
										variant='outlined'>
										Dela
									</Button>
									{!isShowingPdf && (
										<Link
											href={`/lag/${id}/skrivut`}
											target='_blank'
											style={{ textDecoration: 'none' }}>
											<Button
												fullWidth
												size='small'
												startIcon={<PrintIcon />}
												disableElevation
												variant='outlined'>
												Skriv ut
											</Button>
										</Link>
									)}
									{!isShowingPdf && <HighlightSearcher ref={anchorRef} sx={{ maxWidth: 250 }} />}
								</Stack>
							</Box>
						</Box>
					</Box>

					{!isShowingPdf && isLoggedIn && (
						<Box m={2}>
							<Paper style={{ background: '#fff9e1' }}>
								<Box ml={2} pt={1}>
									<Typography variant='h6'>Valt stycke</Typography>
								</Box>
								<List dense>
									{sbtDocumentEntries.length === 0 
										&& sbtDocumentSpecialEntries.length === 0 
										&& (
										<Box m={2}>
											<Typography variant='body2'>Inga träffar</Typography>
										</Box>
									)}
									{sbtDocumentEntries && sbtDocumentEntries.map((sbt) => (
										<ListItem key={sbt.id} disablePadding>
											<ListItemButton
												component={Link}
												to={'/' + fixSearchPath(sbt.id)}
												target='_blank'>
												<ListItemIcon>
													<InfosocIcon type={sbt.icon} />
												</ListItemIcon>
												<Box>
													<ListItemText primary={sbt.name} />
												</Box>
											</ListItemButton>
										</ListItem>
									))}
									{sbtDocumentEntries && (<Box sx={{ mb: 3 }} />)}
									{sbtDocumentSpecialEntries && sbtDocumentSpecialEntries.map((sbt) => (
										<ListItem key={sbt.id} disablePadding>
											<ListItemButton
												component={Link}
												to={'/' + fixSearchPath(sbt.id)}
												target='_blank'>
												<ListItemIcon>
													<InfosocIcon type={sbt.icon} />
												</ListItemIcon>
												<Box>
													<ListItemText primary={sbt.name} />
												</Box>
											</ListItemButton>
										</ListItem>
									))}
									{sbtDocumentSpecialEntries && (<Box sx={{ mb: 3 }} />)}
									{sbtDocumentAllEntry && Object.keys(sbtDocumentAllEntry).length !== 0 && (
										<ListItem key={sbtDocumentAllEntry.id} disablePadding>
											<ListItemButton
												component={Link}
												to={'/' + fixSearchPath(sbtDocumentAllEntry.id)}
												target='_blank'>
												<ListItemIcon>
													<InfosocIcon type={sbtDocumentAllEntry.icon} />
												</ListItemIcon>
												<Box>
													<ListItemText primary={sbtDocumentAllEntry.name} />
												</Box>
											</ListItemButton>
										</ListItem>
									)}
								</List>
							</Paper>
						</Box>
					)}
				</Grid>
			)}

			<ShareDialog
				open={showShareDialog}
				onClose={() => setShowShareDialog(false)}
				templateSubject={shareSubject}
				templateText={shareText}
			/>
			<FavoriteDialog
				open={showFavoriteDialog}
				onClose={(selectedFavoriteFolderId, folderTitle) =>
					selectedFavoriteFolderId
						? saveFavorite(selectedFavoriteFolderId, folderTitle)
						: setShowFavoriteDialog(false)
				}
			/>
			<AddToInvestigationDialog
				open={showInvestigationDialog}
				onClose={(investigationId, title, newTitle) =>
					investigationId
						? saveToInvestigation(investigationId, title, newTitle)
						: setShowInvestigationDialog(false)
				}
			/>
			<WatchSblDialog
				open={showWatchSblDialog}
				onClose={() => setShowWatchSblDialog(false)}
				id={id}
				linkParams={selectedIndex}
			/>
		</Grid>
	);
}

Sbl.propTypes = {
	hideRightColumn: PropTypes.bool,
	selectMode: PropTypes.bool,
	idOrFileName: PropTypes.string,
	query: PropTypes.string,
	onLoaded: PropTypes.func,
	linkParams: PropTypes.string,
	offsetTop: PropTypes.number,
	token: PropTypes.string,
	onSelectSection: PropTypes.func,
};

Sbl.defaultProps = {
	hideRightColumn: false,
	selectMode: false,
	offsetTop: 80,
	onLoaded: () => {},
	onSelectSection: () => {},
	query: '',
};

export default Sbl;
