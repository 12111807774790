import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { get } from "src/services/http-service";
import DenseList from 'src/components/DenseList';
import Sbt from 'src/components/Sbt';
import Newsletter from 'src/components/Newsletter';
import Loading from 'src/components/Loading';
import PlainAppBar from 'src/components/PlainAppBar';
import { alertClasses } from '@mui/material';
import PropTypes from 'prop-types';

function NewsletterPage({plain}) {

  const [loading, setLoading] = useState(true);
  const [newsletter, setNewsletter] = useState(null);

  let { id } = useParams();

  const load = async () => {
    const response = await get(`/newsletters/${id}`);
    setNewsletter(response.newsletters[0]);
    document.title = response.newsletters[0].titleInContent;
    setLoading(false);
  }

  useEffect(() => {
    load();
  }, [id]);

  if (!newsletter) {
    return <Box mt={10} ml={2}><Loading /></Box>;
  }

  if(plain){
    return <Newsletter newsletter={newsletter} />
  }

  return (
    <Box mt={4}>
      <PlainAppBar />

      <Box mt={12}>
        <Newsletter newsletter={newsletter} />
      </Box>
    </Box>
  );
}

NewsletterPage.propTypes = {
  plain: PropTypes.bool,
}

NewsletterPage.defaultProps = {
  plain: false,
}


export default NewsletterPage;