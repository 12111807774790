import React, { useState, useEffect } from 'react';
import { get, post } from "../services/http-service";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Box, Alert } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import storageService from 'src/services/storage-service';
import SelectAllIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/Clear';
import OfferDialog from "src/components/OfferDialog";
import { toast } from 'react-toastify';
import { TextField } from '@mui/material';
import validator from "validator";
import LoadingButton from '@mui/lab/LoadingButton';

function SubscribeNewsletterDialog({ open, onClose }) {

  const [email, setEmail] = useState('');
  const [databases, setDatabases] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const load = async () => {
    let response = await get("/databases");

    const email = storageService.load().email;
    setEmail(email && email.indexOf('@') > 0 ? email : '');
    setErrorMessage('');

    response.databases.forEach(d => d.selected = false);

    setDatabases(response.databases);
  }

  useEffect(() => {
    if (open) {
      load();
    }
  }, [open]);

  const toggleDatabase = (index, checked) => {
    const updatedDatabases = [...databases];

    updatedDatabases[index].selected = checked;
    setDatabases(updatedDatabases);
  }

  const onSubmit = async () => {

    if (!validator.isEmail(email)) {
      return setErrorMessage("Ange en giltig e-postadress");
    }

    setLoading(true);

    const db = databases.filter(d => d.selected).map(d => d.key).join('');
    const form = new FormData();
    form.append("email", email);
    form.append("db", db);

    await post(`/newsletter/subscribe`, form, true);

    toast.success('Du prenumererar nu på vårat nyhetsbrev');
    onClose();
    setLoading(false);
  }

  const selectedCount = databases.filter(d => d.selected).length;
  const title = selectedCount === 1 ? "1 valt rättsområde" : `${selectedCount} valda rättsområden`;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
      >
        <DialogTitle id="scroll-dialog-title">Jag vill prenumerera på följande nyhetsbrev</DialogTitle>
        <DialogContent dividers>
          {errorMessage && (
            <Box mb={2}>
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Box>
          )}
          <Box>
            <TextField fullWidth label="Min e-post" value={email} onChange={(e) => setEmail(e.target.value)}></TextField>
          </Box>

          <Box mt={4}>
            <Typography variant="subtitle1">{title}</Typography>
          </Box>
          <FormGroup>
            <Grid
              container
              justifyContent="space-between"
            >
              {databases.map((db, index) => (
                <Grid item xs={6} key={index}>
                  <Box>
                    <FormControlLabel control={<Checkbox checked={db.selected} onChange={(event) => toggleDatabase(index, event.target.checked)} />} label={db.name} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} disabled={selectedCount === 0} variant="contained" onClick={onSubmit}>Prenumerera</LoadingButton>
          {!loading && <Button onClick={onClose}>Avbryt</Button>}
        </DialogActions>
      </Dialog>
    </>
  )

}

SubscribeNewsletterDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SubscribeNewsletterDialog;