import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { post, get } from "src/services/http-service";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Alert, { alertClasses } from "@mui/material/Alert";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import validator from "validator";

function NewsletterUnsubscribePage() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState("");
  const [databases, setDatabases] = useState([]);
  const [toastMessage, setToastMessage] = useState("");

  const navigate = useNavigate();

  document.title = "Avregistrera nyhetsbrev | Infosoc";

  useEffect(() => {
    async function fetchDatabases() {
      let response = await get("/databases/all");

      let databaseList = response.map((d) => ({
        ...d,
        selected: false,
      }));

      setDatabases(databaseList);
    }
    fetchDatabases();
  }, [setDatabases]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    if (!validator.isEmail(email)) {
      setErrorMessage("Ange en giltig e-postadress");
      setLoading(false);
      return;
    }

    let selectedDatabases = databases.filter((db) => db.selected);
    if (selectedDatabases.length === 0) {
      setErrorMessage("Du måste välja minst en databas");
      setLoading(false);
      return;
    }

    let selectedDbKeys = selectedDatabases.map((db) => db.key).join(",");
      
    let form = new FormData();

    form.append("email", email);
    form.append("dbs", selectedDbKeys);

    let unsubscribeResponse = await post('/newsletter/unsubscribe', form, true);

    setLoading(false);

    if (unsubscribeResponse.status) {
      setToastMessage('Du har avregistrerats från valda nyhetsbrev.');
      setTimeout(() => {
        navigate('/');
      }, 5000);
    } else {
      setErrorMessage("Något gick fel. Försök igen senare.");
    }
  };

  const toggleDatabase = (index, checked) => {
    const updatedDatabases = [...databases];

    updatedDatabases[index].selected = checked;
    setDatabases(updatedDatabases);
  };
  const handleSelectAll = () => {
    const updatedDatabases = [...databases];

    updatedDatabases.forEach((db) => (db.selected = true));
    setDatabases(updatedDatabases);
  };
  const handleDeselectAll = () => {
    const updatedDatabases = [...databases];

    updatedDatabases.forEach((db) => (db.selected = false));
    setDatabases(updatedDatabases);
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          mt={10}
          ml={-20}
          sx={{
            display: "flex",
            alignItems: "center",
            width: 700,
          }}
        >
          <Paper elevation={3} style={{ zIndex: 10 }}>
            <Box p={8}>
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <div style={{ textAlign: "center" }}>
                  <img
                    src="/logo.png"
                    alt="InfoSoc Logo"
                    style={{ margin: "0px auto" }}
                  />
                </div>

                <Typography variant="h5" align="center">Avregistrera nyhetsbrev</Typography>

                {toastMessage.length > 0 && (
                  <Box mb={4}>
                    <Alert variant="filled" severity="success">
                      {toastMessage}
                    </Alert>
                  </Box>
                )}

                {errorMessage && (
                  <Box mb={2}>
                    <Alert variant="filled" severity="error">
                      {errorMessage}
                    </Alert>
                  </Box>
                )}

                <Box mt={2}>
                  <Typography variant="body1" style={{fontWeight: 'bold'}}>Databaser</Typography>
                  <FormGroup>
                    <Grid container justifyContent="space-between">
                      {databases.map((db, index) => (
                        <Grid item xs={6} key={index}>
                          <Box>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={db.selected}
                                  onChange={(event) =>
                                    toggleDatabase(index, event.target.checked)
                                  }
                                />
                              }
                              label={db.name}
                            />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                    <ButtonGroup variant="outlined" size="medium" sx={{ mt: 2, mb: 1 }} fullWidth>
                      <Button startIcon={<AddIcon />} onClick={handleSelectAll}>Välj alla</Button>
                      <Button endIcon={<DeleteIcon />} onClick={handleDeselectAll} color="error" variant="outlined">Rensa val</Button>
                    </ButtonGroup>
                  </FormGroup>

                  <TextField
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="E-post"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                </Box>

                <LoadingButton
                  loading={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 0 }}
                >
                  Avregistrera
                </LoadingButton>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
      <div
        style={{
          background: "url(/login_background.jpg)",
          backgroundSize: "cover",
          position: "fixed",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          zIndex: 0,
        }}
      />
    </>
  );
}

export default NewsletterUnsubscribePage;
