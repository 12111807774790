import { Puff } from 'react-loading-icons';
import { Box, Typography, Stack } from '@mui/material';

function Loading({ message }) {

    return (
        <Box>
            <Stack direction="row">
                <Puff stroke="#06bcee" />
                <Box m={1}>
                    <Typography autoFocus color="primary" variant='h6'>{message}</Typography>
                </Box>

            </Stack>
        </Box>
    )

}

export default Loading;
