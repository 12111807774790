import React, { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function LegacySbtLinkPage() {

    const [search] = useSearchParams();
    const navigate = useNavigate();
    const id = search.get('id');

    useEffect(() => {
        navigate(`/rattsfall/${id}`);
    }, [id]);
}

export default LegacySbtLinkPage;