import React, { useState, useEffect } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import OrderIcon from '@mui/icons-material/LocalShipping';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';

import PropTypes from 'prop-types';
import { get } from 'src/services/http-service';

import ContactUsDialog from 'src/components/ContactUsDialog';
import AttachmentViewer from './AttachmentViewer';
import { API_URL } from 'src/services/http-service';
import Loading from './Loading';

import HtmlPdf from './HtmlPdf';

function SbtFullText({ id, hideButtons }) {
	const [loading, setLoading] = useState(true);
	const [showContactUsDialog, setShowContactUsDialog] = useState(false);

	const [text, setText] = useState('');
	const [title, setTitle] = useState('');
	const [pdfOnly, setPdfOnly] = useState(false);

	useEffect(() => {
    const load = async () => {
      setLoading(true);
      let response = await get(`/lawreports/${id}/fulltext`);
      setText(response.text);
      setTitle(response.title);
      setPdfOnly(response.text.trim() < 10);
      setLoading(false);
    };

		load();
	}, [id]);

	if (loading) {
		return (
			<Box m={6}>
				<Loading />
			</Box>
		);
	}

	if (pdfOnly) {
		const pdfUrl = `${API_URL}/lawreports/${id}/fulltext/pdf`;

		return (
			<Box mt={-2} ml={1}>
				<Box mb={1}>
					<Link href={pdfUrl} target='_blank' style={{ textDecoration: 'none' }}>
						<Button size='small' startIcon={<DownloadIcon />} disableElevation variant='outlined'>
							Ladda ner
						</Button>
					</Link>
				</Box>
				<AttachmentViewer url={pdfUrl} offsetTop={150} />
			</Box>
		);
	}

	return (
		<Box m={4} pb={8}>
			<Box>
				<Typography variant='h5'>{title}</Typography>
			</Box>

			{!hideButtons && (
				<Box mb={2} mt={2}>
					<Toolbar disableGutters={true}>
						<Stack direction='row' spacing={1}>
							<Button
								size='small'
								startIcon={<OrderIcon />}
								disableElevation
								variant='outlined'
								onClick={() => setShowContactUsDialog(true)}
							>
								Beställ original-pdf
							</Button>

							<PDFDownloadLink
								document={<HtmlPdf title={title} htmlString={text} />}
								fileName={`${title}.pdf`}
								style={{ textDecoration: 'none' }}
							>
								{({ blob, url, loading, error }) => (
									<Button
										size='small'
										startIcon={<DownloadIcon />}
										disableElevation
										variant='outlined'
									>
										Ladda ner
									</Button>
								)}
							</PDFDownloadLink>

							<Link
								href={`/rattsfall/${id}/fulltext/skrivut`}
								target='_blank'
								style={{ textDecoration: 'none' }}
							>
								<Button size='small' startIcon={<PrintIcon />} disableElevation variant='outlined'>
									Skriv Ut
								</Button>
							</Link>
						</Stack>
					</Toolbar>
				</Box>
			)}

			<Typography variant='body1'>
				<Box component={'span'} style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{__html: text }} />
			</Typography>

			<ContactUsDialog
				open={showContactUsDialog}
				onClose={() => {
					setShowContactUsDialog(false);
				}}
				initalOption='Beställ dom'
				initialMessage={`Jag skulle vilja beställa domen ${title}`}
			/>
		</Box>
	);
}

SbtFullText.propTypes = {
	id: PropTypes.string,
	hideButtons: PropTypes.bool,
};

SbtFullText.defaultProps = {
	hideButtons: false,
};

export default SbtFullText;
