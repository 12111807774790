

import React, { useEffect, useState } from 'react';
import { Stack, Box, AppBar, Paper, TextField, IconButton, Typography, FormControlLabel, Button, Alert, Link, alertClasses } from '@mui/material';
import DenseList from 'src/components/DenseList';
import { get, post } from "src/services/http-service";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import FolderIcon from '@mui/icons-material/Folder';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfosocIcon from 'src/components/InfosocIcon';
import PreviewDialog from 'src/components/PreviewDialog';
import Sbt from 'src/components/Sbt';
import Sbl from 'src/components/Sbl';
import SblDialog from 'src/components/SblDialog';
import { ConstructionOutlined } from '@mui/icons-material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import TextInputDialog from "src/components/TextInputDialog";
import ConfirmDialog from 'src/components/ConfirmDialog';
import InvestigationDialog from 'src/components/InvestigationDialog';

function InvestigationPage() {

  const [selectedFolder, setSelectedFolder] = useState({ name: 'Mina utredningar', id: 0, entries: [] });

  const [folders1, setFolders1] = useState([]);
  const [folders1SelectedIndex, setFolders1SelectedIndex] = useState(-1);

  const [folders2, setFolders2] = useState([]);
  const [folders2SelectedIndex, setFolders2SelectedIndex] = useState(-1);

  const [investigations, setInvestigations] = useState([]);
  const [selectedFolder1, setSelectedFolder1] = useState(null);
  const [selectedFolder2, setSelectedFolder2] = useState(null);

  const [previewSbtId, setPreviewSbtId] = useState(-1);
  const [previewSblId, setPreviewSblId] = useState(-1);

  const [linkParams, setLinkParams] = useState(null);

  const [textInputMode, setTextInputMode] = useState('');
  const [showTextInput, setShowTextInput] = useState(false);
  const [textInputTitle, setTextInputTitle] = useState(false);
  const [textInputLabel, setTextInputLabel] = useState(false);
  const [textInitalValue, setTextInitalValue] = useState('');
  const [textInputErrorMessage, setTextInputErrorMessage] = useState(false);

  const [reloadColumn, setReloadColumn] = useState(0);

  const [selectedParentFolderId, setSelectedParentFolderId] = useState(-1);

  const [showConfirm, setShowConfirm] = useState(false);

  const [loading, setLoading] = useState(true);

  const [selectedInvestigationId, setSelectedInvestigationId] = useState(-1);
  const [selectedInvestigationEditId, setSelectedInvestigationEditId] = useState(-1);

  document.title = 'Utredningar | Infosoc';

  const load = async () => {
    const response = await get('/investigations');

    response.folders.forEach(f => f.icon = 'folder');

    const allFolders = [{ name: 'Mina utredningar', id: 0, icon: 'investigation-root-folder' }].concat(response.folders);
    setFolders1(allFolders);
    setInvestigations(response.entries);

    setSelectedFolder(allFolders[0])
    setFolders1SelectedIndex(0);
    setFolders2SelectedIndex(-1);
    setFolders2([]);

    setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  const showNewFolder = (parentId) => {
    setReloadColumn(parentId === -1 ? 0 : 1)
    setSelectedParentFolderId(parentId);
    setTextInputMode('create');
    setTextInputTitle('Ny katalog');
    setShowTextInput(true);
    setTextInitalValue('');
  }

  const selectFolder1 = async (index, entry) => {

    if (index === 0) {
      return load();
    }

    setReloadColumn(0);

    setSelectedFolder(entry);
    setFolders1SelectedIndex(index);
    setFolders2SelectedIndex(-1);
    setSelectedFolder1(entry);
    setFolders2([]);

    const response = await get(`/loginfolders/${entry.id}/investigations`)
    response.folders.forEach(f => f.icon = 'folder');

    setFolders2(response.folders);

    setInvestigations(response.entries);
  }

  const selectFolder2 = async (index, entry) => {
    setReloadColumn(1);
    setFolders2SelectedIndex(index);
    setSelectedFolder2(entry);

    setSelectedFolder(entry);

    const response = await get(`/loginfolders/${entry.id}/investigations`)
    response.folders.forEach(f => f.icon = 'folder');

    setInvestigations(response.entries);
  }

  const renameFolder = (entry) => {
    setSelectedParentFolderId(entry.id);
    setTextInputMode('edit');
    setTextInputTitle('Ändra namn');
    setTextInputLabel('Katalognamn');
    setShowTextInput(true);
    setTextInitalValue(entry.name);
  }

  const renameInvestigation = (entry) => {
    setSelectedInvestigationEditId(entry.id);
    setTextInputMode('edit-investigation');
    setTextInputTitle('Ändra namn');
    setTextInputLabel('Utredningsnamn');
    setShowTextInput(true);
    setTextInitalValue(entry.name);
  }

  const deleteFolder = (entry) => {
    setSelectedParentFolderId(entry.id);
    setTextInputMode('delete');
    setShowConfirm(true);
  }

  const updateFolder = async () => {
    if (reloadColumn === 1) {
      selectFolder1(folders1SelectedIndex, selectedFolder1);
    }
    else {
      await load();
    }
  }

  const deleteFolderConfirmed = async () => {
    var form = new FormData();
    if (textInputMode === 'delete-investigation') {
      await post(`/investigations/${selectedParentFolderId}/delete`, form, true);
    }
    else {
      await post(`/loginfolders/${selectedParentFolderId}/delete`, form, true);
    }

    if (reloadColumn === 1) {
      selectFolder1(folders1SelectedIndex, selectedFolder1);
    }
    else {
      load();
    }
  }

  const saveTextInput = async (input) => {

    setShowTextInput(false);

    if (!input) {
      return;
    }

    var form = new FormData();
    if (textInputMode === 'create') {
      form.append('name', input);
      form.append('parentId', selectedParentFolderId);
      form.append('type', 'investigation');

      await post('/loginfolders', form, true);
    }
    else if(textInputMode === 'edit-investigation'){
      form.append('id', 'investigation_'+selectedInvestigationEditId);
      form.append('value', input);

      await post('/investigations/title', form, true)
    }
    else if (textInputMode === 'edit') {
      form.append('name', input);
      await post(`/loginfolders/${selectedParentFolderId}`, form, true);
    }
    else if (textInputMode === 'create-investigation') {
      form.append('name', input);
      form.append('loginFolderId', selectedParentFolderId);
      await post('/investigations', form, true);
    }

    if (reloadColumn === 1) {
      selectFolder1(folders1SelectedIndex, selectedFolder1);
    }
    else {
      await load();
    }

  }

  const deleteInvestigation = async (entry) => {
    setSelectedParentFolderId(entry.id);
    setTextInputMode('delete-investigation');
    setShowConfirm(true);
  }

  const selectInvestigation = (row) => {
    setSelectedInvestigationId(row.id);
  }

  const showNewInvestigation = (parentId) => {

    setReloadColumn(parentId === -1 ? 0 : 1)
    setSelectedParentFolderId(parentId);
    setTextInputMode('create-investigation');
    setTextInputTitle('Ny utredning');
    setShowTextInput(true);
    setTextInitalValue('');
  }

  const confirmMessage = textInputMode === 'delete-investigation' ? 'Är du säker?' : 'Alla utredningar i denna katalogen kommer att försvinna';
  const confirmTitle = textInputMode === 'delete-investigation' ? 'Radera utredning?' : 'Radera katalog?';

  return (
    <Box mt={8} ml={0}>
      <Box sx={{ flexGrow: 1 }} >
        <Stack direction="row">
          { }
          <Box minWidth={200} mt={2}>
            <Paper >
              <Box mt={1} ml={2}>
                <Button size="small" variant="outlined" onClick={() => showNewFolder(-1)} startIcon={<AddIcon />}>Ny katalog</Button>
              </Box>
              <DenseList offsetTop={130} entries={folders1} onSelect={(index, entry) => selectFolder1(index, entry)} selectedIndex={folders1SelectedIndex} />
            </Paper>
          </Box>
          {folders1SelectedIndex > 0 &&
            <Box minWidth={200} mt={2}>
              <Box mt={1} ml={2}>
                <Button size="small" variant="outlined" onClick={() => showNewFolder(selectedFolder1.id)} startIcon={<AddIcon />}>Ny katalog</Button>
              </Box>
              <DenseList offsetTop={70} entries={folders2} onSelect={(index, entry) => selectFolder2(index, entry)} selectedIndex={folders2SelectedIndex} />
            </Box>
          }
          <Box ml={2} mt={2}>

            <Stack direction="row">
              <Box pt={0.4} mr={1}>
                <FolderIcon color={selectedFolder.id===0? "secondary" : "primary"} />
              </Box>
              <Typography variant='h6'>{selectedFolder.name}</Typography>
            </Stack>

            <Box mt={2}>
              <Stack direction="row" spacing={2}>
                <Button variant="outlined" disabled={folders1SelectedIndex === 0} startIcon={<EditIcon />} onClick={() => renameFolder(selectedFolder)}>Ändra namn på katalog</Button>
                <Button variant="outlined" disabled={folders1SelectedIndex === 0} startIcon={<DeleteIcon />} onClick={() => deleteFolder(selectedFolder)}>Radera katalog</Button>
                <Button variant="outlined" startIcon={<AddIcon />} onClick={() => showNewInvestigation(selectedFolder.id)}>Ny utredning</Button>
              </Stack>

              {investigations.length > 0 && <Box mt={2}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 500 }} size="small">
                    <TableBody>
                      {investigations.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell><InfosocIcon type='investigation' /></TableCell>
                          <TableCell width={450}>
                            <Link href="#" onClick={() => selectInvestigation(row)}>
                              {row.name}
                            </Link>
                          </TableCell>

                          <TableCell align="right">
                          <IconButton onClick={() => renameInvestigation(row)}><EditIcon /></IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={() => deleteInvestigation(row)}><DeleteIcon /></IconButton>
                        </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              }
            </Box>
          </Box>
        </Stack>
      </Box >

      <PreviewDialog open={previewSbtId !== -1} onClose={() => setPreviewSbtId(-1)} link={`/rattsfall/${previewSbtId}`}>
        {previewSbtId !== -1 && <Box mr={4}><Sbt id={previewSbtId} /></Box>}
      </PreviewDialog>

      <SblDialog linkParams={linkParams} open={previewSblId !== -1} onClose={() => setPreviewSblId(-1)} id={previewSblId} />

      {showTextInput &&
        <TextInputDialog open={showTextInput}
          initalValue={textInitalValue}
          loading={loading}
          title={textInputTitle}
          inputLabel={textInputLabel}
          text=""
          saveButtonLabel="Spara"
          errorMessage={textInputErrorMessage}
          onClose={(input) => saveTextInput(input)}
        />
      }

      <ConfirmDialog
        open={showConfirm}
        title={confirmTitle}
        onClose={() => setShowConfirm(false)}
        onConfirm={() => deleteFolderConfirmed()}
        saveButtonLabel='Ta bort'
        color='error'
        text={confirmMessage}
      />

      <InvestigationDialog id={Number(selectedInvestigationId)} onClose={() => setSelectedInvestigationId(-1)} />

    </Box >
  );
}

export default InvestigationPage;
