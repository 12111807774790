
import React, { useEffect, useState } from 'react';
import { Box, Stack, Button, Typography } from '@mui/material';
import Sbl from "src/components/Sbl";
import AppBar from '@mui/material/AppBar';
import GoBackIcon from '@mui/icons-material/ArrowBack';
import SblChanges from 'src/components/SblChanges';
import { useParams, useSearchParams } from "react-router-dom";

function SblChangesPage() {

  let { id, sfs } = useParams();
  const [search, setSearch] = useSearchParams();
  const future = search.get('framtida')==='true';
  let [title, setTitle] = useState('');

  document.title = title;

  return (
    <>
      <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: "white", color: '#222' }}>

        <Box m={2}>
          <Stack direction="row">
            <Button variant="outlined" onClick={() => window.close()} startIcon={<GoBackIcon />}>Tillbaka</Button>
            <Box ml={2} mt={1}>
              <Typography variant="h5">{title} | Ändringar</Typography>
            </Box>
          </Stack>
        </Box>
      </AppBar>

      <Box sx={{ flexGrow: 1 }} mt={10}>
        <SblChanges id={id} sfs={sfs} offsetTop={100} onLoaded={setTitle} future={future}/>
      </Box>
    </>
  );
}

export default SblChangesPage;