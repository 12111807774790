import React from 'react';
import {  Page, Text, View, Document, StyleSheet, Link } from '@react-pdf/renderer';
import { UI_URL } from "src/services/http-service";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row'
  },
  header: {
    padding: '20px 30px',
    fontSize: 26,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    padding: '0 30px',
    lineHeight: '2rem'
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    padding: '0 30px',
    lineHeight: '2rem',
    marginBottom: 5,
    marginTop: 15,
  }
});

const InvestigationPdf = ({ data }) => {
  const formatText = (text) => {
    if (!text) return text;

    return text.replace(/<br\s*\/?>/gi, '\n').trimStart();
  }

  const buildUrl = (url) => {
    if (!url) return '';

    return encodeURI(`${UI_URL}/${url}`);
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.header}>{data.title}</Text>
          {data.entries.map((entry, index) => <div key={index} style={{ marginBottom: 10 }}>
            {entry.title &&
              <Text style={styles.title}>{ formatText(entry.title) }</Text>
            }
            {entry.text && 
              <Text style={styles.text}>{ formatText(entry.text) }</Text>
            }
            
            {entry.hasSource &&
              <>
                <Text>{'\n'}</Text>
                <Link src={ buildUrl(entry.link) }>
                  <Text style={styles.text}>Gå till källa</Text>
                </Link>
              </>
            }
          </div>)}
        </View>
      </Page>
    </Document>
  );
}


export default InvestigationPdf;