import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useParams } from "react-router-dom";
import Sbt from "src/components/Sbt";

export default () => {

  let { id } = useParams();

  const print = () => {
    setTimeout(() => window.print(), 100);
  }

  return (
    <Box sx={{ flexGrow: 1 }} m={2} >
      <Sbt id={id} hideButtons={true} singleColumn={true} onLoaded={() => print()} />
    </Box>
  );
}
