import React, { useState } from 'react';
import { post } from "../services/http-service";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import WatchlistIcon from '@mui/icons-material/Notifications';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import storageService from 'src/services/storage-service';
import { alertClasses, TextField } from '@mui/material';
import { toast } from 'react-toastify';

function WatchSblDialog({ open, id, linkParams, onClose }) {

  const [watchType, setWatchType] = useState('sbl');
  const [entire, setEntire] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [emails, setEmails] = useState('');

  const login = storageService.load();
  const isEmailLogin = login.email && login.email.indexOf('@') > 0;

  const onSubmit = async () => {
    var form = new FormData();
    form.append('linkType', 'lawdocument');
    form.append('linkId', id);
    form.append('linkParams', linkParams);
    form.append('entire', entire);
    form.append('watchType', watchType);
    form.append('sendEmail', sendEmail);
    form.append('emails', emails);

    await post('/watchlist', form, true);
    
    toast.success('Du bevakar nu ' + (entire==='true'? 'hela lagen' : 'valt stycke'));

    onClose(true);
  }

  return (
    <Dialog
      open={open}
      scroll="paper"
    >
      <Box minWidth={600}>
        <DialogTitle id="scroll-dialog-title">
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Box mt={1} mr={1}>
                <WatchlistIcon color="secondary" />
              </Box>
            </Grid>
            <Grid item color="secondary">
              <Typography variant="h6" color="secondary">Bevaka</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>

          <RadioGroup
            value={watchType}
            onChange={(event) => setWatchType(event.target.value)}
          >
            <FormControlLabel value="sbl" control={<Radio />} label="Lagändringar" />
            {watchType === 'sbl' && <Box ml={4} mt={1} mb={2}>

              <RadioGroup
                value={entire}
                onChange={(event) => setEntire(event.target.value)}
              >
                <FormControlLabel value={true} control={<Radio />} label="Bevaka hela lagen" />
                <FormControlLabel value={false} control={<Radio />} label="Bevaka valt lagrum" />
              </RadioGroup>
            </Box>
            }
            {watchType==='sbl' &&
              <Box ml={4} mt={2} mr={4} mb={2}>
                <Box mb={2} >
                  <Typography variant="body2">
                    Upplys följande e-post mottagare om ändringar (en e-postadress per rad)
                  </Typography>
                </Box>
                <TextField fullWidth multiline rows={4} value={emails} onChange={(e) => setEmails(e.target.value)} label="E-post adresser" />
              </Box>
            }
            {isEmailLogin &&
              <FormControlLabel value="sbt" control={<Radio />} label="Nya rättsfall under valt lagrum" />
            }
          </RadioGroup>



          {watchType!=='sbl' &&
            <Box mt={4} p={2} style={{ background: '#eee', borderRadius: 5 }}>
              <FormControlLabel control={<Checkbox checked={sendEmail} onChange={(event) => setSendEmail(event.target.checked)} />} label="Bevaka via min e-post" />
            </Box>
          }


        </DialogContent>
        <DialogActions>

          <Button variant="contained" onClick={onSubmit}>OK</Button>
          <Button onClick={() => onClose()}>Avbryt</Button>
        </DialogActions>
      </Box>
    </Dialog>
  )

}

WatchSblDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.number,
  linkParams: PropTypes.number,
};

WatchSblDialog.defaultProps = {
  onClose: () => { },
  id: -1,
};

export default WatchSblDialog;