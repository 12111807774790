import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import FilterIcon from '@mui/icons-material/FilterAlt';
import Highlight from './Highlight';
import Typography from '@mui/material/Typography';

import InfosocIcon from './InfosocIcon';

function DenseList({ entries, onSelect, selectedIndex, showFilter, title, offsetTop, onFilter, maxEntries, foldable}) {

  const [filter, setFilter] = useState('');
  const [filteredEntries, setFilteredEntries] = useState(entries.slice(0, maxEntries));
  const [totalHits, setTotalCount] = useState(entries.length);
  const [maxCount, setMaxCount] = useState(maxEntries);

  const filterEntries = useCallback((filterString, maxLength) => {
    let filteredList = entries;

    if (filterString) {
      filteredList = entries.filter(entry =>
        (entry.name || entry.title).toLocaleLowerCase().indexOf(filterString) >= 0 ||
        (entry.subtitle && entry.subtitle.toLocaleLowerCase().indexOf(filterString) >= 0));
    }

    setTotalCount(filteredList.length);
    
    return filteredList.slice(0, maxLength);
  }, [entries]);
  
  useEffect(() => {
    setFilter('');
    if (filteredEntries.length === 0 || entries.length !== totalHits) {
      setFilteredEntries(filterEntries('', maxEntries));
    }
  }, [entries, filterEntries]);

  const updateFilterBounced = useCallback((newValue) => {
    onFilter();
    const filter_lower = newValue.toLocaleLowerCase();

    setFilter(filter_lower);
    setFilteredEntries(filterEntries(filter_lower, maxEntries));
    setMaxCount(maxEntries);

  }, [onFilter, maxEntries, filterEntries]);

  const updateFilter = useCallback((newValue) => {
      updateFilterBounced(newValue);
  }, [updateFilterBounced]);

  // Default empty component if no entries
  if (entries.length === 0) {
    return <></>
  }

  const loadMoreEntires = () => {
    const newMaxCount = maxCount + 500;
    setMaxCount(newMaxCount);
    setFilteredEntries(filterEntries(filter, newMaxCount));
  }

  const findOriginalIndex = (entry) => {
    return entries.findIndex(e => e.id === entry.id);
  }
  
  const getText = (entry, index) => {
    let text = entry.name || entry.title;

    let textComponent = <Highlight text={text} query={filter} />;

    if (foldable) {
      textComponent = <div style={{ overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
        <Highlight text={text} query={filter} />
      </div>;
    }

    return selectedIndex === index ? <b>{textComponent}</b> : textComponent
  }

  const getSubtitle = (entry) => {
    if (!entry.subtitle) {
      return '';
    }

    return <Highlight text={entry.subtitle} query={filter} />;
  }

  return (
    <Box height={`calc(100vh - ${offsetTop}px)`} display="flex" flexDirection="column">
      {title &&
        <Box pt={1} pl={2}>
          <Typography variant="h6">{title}</Typography>
        </Box>
      }
      {showFilter &&
        <Box ml={2} mt={2}>
          <TextField
            autoFocus
            onChange={(e) => { updateFilter(e.target.value); }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FilterIcon />
                </InputAdornment>
              )
            }}
            variant="standard" fullWidth placeholder="Filtrera..." />
        </Box>
      }
      <Box flex={1} overflow={ 'auto' }>
        {filteredEntries.length === 0 && filter.length > 0 &&
          <Box m={2}>
            <Typography>Inga träffar på <b>{filter}</b></Typography>
          </Box>
        }

        <List component="nav" dense >
          {filteredEntries.map((entry, index) => (
            <ListItemButton
              key={index}
              selected={selectedIndex === index}
              onClick={() => onSelect(index, entry, findOriginalIndex(entry))}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <InfosocIcon type={entry.icon} />
              </ListItemIcon>
              <ListItemText
                primary={getText(entry, index)}
                secondary={getSubtitle(entry)}
              />
            </ListItemButton>
          ))}
          {filteredEntries && totalHits > filteredEntries.length && 
            <ListItemButton
              key={entries.length+1}
              onClick={() => loadMoreEntires()}
              sx={{
                textAlign: 'center',
                border: '0.5px solid', 
                borderColor: 'grey.400',
              }}
            >
              <ListItemText primary={'Se fler'} />
            </ListItemButton>
          }
        </List>
      </Box>
    </Box>
  )
}

DenseList.propTypes = {
  showFilter: PropTypes.bool,
  entries: PropTypes.array,
  selectedIndex: PropTypes.number,
  onSelect: PropTypes.func,
  onFilter: PropTypes.func,
  title: PropTypes.string,
  offsetTop: PropTypes.number,
  maxEntries: PropTypes.number,
};

DenseList.defaultProps = {
  selectedIndex: -1,
  showFilter: false,
  offsetTop: 168,
  onFilter: () => { },
  maxEntries: 1000,
};

export default DenseList;