import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import Box from '@mui/material/Box';
import LoginPage from './pages/login/LoginPage';
import HomePage from './pages/home/HomePage';
import SbtPage from './pages/sbt/SbtPage';
import SblPage from './pages/sbl/SblPage';
import SblPrintPage from './pages/sbl/SblPrintPage';
import InvestigationPrintPage from './pages/investigation/InvestigationPrintPage';
import SearchResultPage from './pages/search/SearchResultPage';
import SbtStructuredSearchPage from './pages/sbt/SbtStructuredSearchPage';
import SbtFullTextPage from './pages/sbt/SbtFullTextPage';
import SbtFullTextPrintPage from './pages/sbt/SbtFullTextPrintPage';
import SblListPage from './pages/sbl/SblListPage';
import UsersPage from './pages/users/UsersPage';
import SbtResultPage from './pages/sbt/SbtResultPage';
import SbtPrintPage from './pages/sbt/SbtPrintPage';
import InvestigationListPage from './pages/investigation/InvestigationListPage';
import NewsletterPage from './pages/newsletter/NewsletterPage';
import NewsletterListPage from './pages/newsletter/NewsletterListPage';
import NewsletterUnsubscribePage from './pages/newsletter/NewsletterUnsubscribePage';
import EBooksPage from './pages/ebooks/EBooksPage';
import FormsPage from './pages/forms/FormsPage';
import FavoritesPage from './pages/favorites/FavoritesPage';
import AutoLoginPage from './pages/login/AutoLoginPage';
import ConfirmationKeyLoginPage from './pages/login/ConfirmationKeyLoginPage';
import TopMenu from './components/TopMenu';
import LeftMenu from './components/LeftMenu';
import QuestionAnalysisPage from './pages/sbt/QuestionAnalysisPage';
import WatchlistPage from './pages/watchlist/WatchlistPage';
import { Routes, Route, useLocation } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import storageService from 'src/services/storage-service';
import LegalCommentsPage from './pages/legalcomments/LegalCommentsPage';
import InformationMaterialPage from './pages/informationmaterial/InformationMaterialPage';
import ForgotPasswordPage from './pages/login/ForgotPasswordPage';
import SblChangesPage from './pages/sbl/SblChangesPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Worker } from '@react-pdf-viewer/core';
import LegacySbtLinkPage from './pages/sbt/LegacySbtLinkPage';
import LegacySblLinkPage from './pages/sbl/LegacySblLinkPage';
import { get } from 'src/services/http-service';
import { LiveChatWidget } from '@livechat/widget-react';
import Loading from './components/Loading';
import ReactGA from 'react-ga4';

import Footer from './components/Footer';
import HelpPage from './pages/help/HelpPage';
import FaqPage from './pages/help/FaqPage';

const STAND_ALONE_PAGES = [
	'/nyhetsbrev/',
	'/nyhetsbrev/avregistrera',
	'/rattsfallsokning',
	'/lag/',
	'/glomtlosen',
	'/inloggning',
	'/loggain/personlig-inloggning',
	'/login.phtml',
	'/nyanvandare',
	'/hjalp',
	'/faq',
	'/utredningar/',
];

export const menuOptions = {
	rattsfall: 1,
	fragor_analyser: 2,
};

function App() {
	const ga4MeasurementId = process.env.REACT_APP_GA4_MEASUREMENT_ID;
	if (ga4MeasurementId) {
		ReactGA.initialize(ga4MeasurementId);
	}

	let login = storageService.load();

	const databasReferer =
		document.referrer &&
		document.referrer.indexOf(process.env.REACT_APP_BASE_URL) >= 0;
	const redirectToMobile = isMobile && !databasReferer;

	function setUserOrganization(organizationId) {
		ReactGA.gtag('set', 'user_properties', {
			organization_id: organizationId,
		});

		localStorage.setItem('organizationId', organizationId);
	}

	const load = async () => {
		login = storageService.load();

		// Google Analytics - set identity
		if (login.accessKey && ga4MeasurementId) {
			setUserOrganization(login.organizationName);
		}

		if (login.accessKey && !currentUser) {
			const response = await get('/currentuser/basic');
			setCurrentUser(response);
		}
	};

	const path = window.location.pathname;

	useEffect(() => {
		if (redirectToMobile) {
			window.location.href = `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_MOBIL_URL}${path}`;
			return;
		} else {
			load();
		}
	}, [path, redirectToMobile]);

	const [currentUser, setCurrentUser] = useState(null);
	const [isLoggedIn, setIsLoggedIn] = useState(!!login.accessKey);
	const location = useLocation();

	const trackPageViewWithOrganizationId = (path, organizationId) => {
		ReactGA.send({
			hitType: 'pageview',
			page: path,
			nonInteraction: true,
			organization_id: organizationId,
		});
	};

	useEffect(() => {
		const organizationId = localStorage.getItem('organizationId');

		if (organizationId && ga4MeasurementId) {
			trackPageViewWithOrganizationId(
				`${location.pathname + location.search}`,
				organizationId
			);
		}
	}, [location]);

	const loginSuccess = () => {
		setCurrentUser(null);
		setIsLoggedIn(true);
		load();
	};

	const isPrintPage = location.pathname.indexOf('skrivut') >= 0;
	const isStandAlone =
		STAND_ALONE_PAGES.some(
			(page) => location.pathname.indexOf(page) === 0
		) || isPrintPage;
	const hideNavigation = isStandAlone || !isLoggedIn;

	if (redirectToMobile) {
		return <Loading />;
	}

	return (
		<>
			{!hideNavigation && <TopMenu currentUser={currentUser} />}
			<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js">
				<Box sx={{ display: 'flex' }} m={0}>
					{!hideNavigation && <LeftMenu currentUser={currentUser} />}
					<Box style={{ width: '100%' }}>
						<Routes>
							<Route
								path="/"
								element={
									<ProtectedRoute>
										<HomePage currentUser={currentUser} />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/hemsida"
								element={
									<ProtectedRoute>
										<HomePage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/rattsfall"
								element={
									<ProtectedRoute>
										<SbtStructuredSearchPage
											currentUser={currentUser}
											menuOption={menuOptions.rattsfall}
										/>
									</ProtectedRoute>
								}
							/>
							<Route
								path="/rattsfall/:ref"
								element={
									<ProtectedRoute>
										<SbtPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/rattsfall/:id/fulltext"
								element={
									<ProtectedRoute>
										<SbtFullTextPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/rattsfall/:id/fulltext/skrivut"
								element={
									<ProtectedRoute>
										<SbtFullTextPrintPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/rattsfall/:id/skrivut"
								element={
									<ProtectedRoute>
										<SbtPrintPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/loggain"
								element={
									<LoginPage
										onSuccess={() => setIsLoggedIn(true)}
									/>
								}
							/>
							<Route
								path="/loggain/personlig-inloggning"
								element={
									<LoginPage
										requestLogin
										onSuccess={() => setIsLoggedIn(true)}
									/>
								}
							/>
							<Route
								path="/loggain/:confirmationKey"
								element={
									<ConfirmationKeyLoginPage
										onSuccess={() => setIsLoggedIn(true)}
									/>
								}
							/>
							<Route
								path="/lagar"
								element={
									<ProtectedRoute>
										<SblListPage />
									</ProtectedRoute>
								}
							/>
							<Route path="/lag/:id" element={<SblPage />} />
							<Route
								path="/lag/:id/andringar"
								element={<SblChangesPage />}
							/>
							<Route
								path="/lag/:id/andringar/:sfs"
								element={<SblChangesPage />}
							/>
							<Route
								path="/lag/:id/skrivut"
								element={<SblPrintPage />}
							/>
							<Route
								path="/fragor-analyser"
								element={
									<QuestionAnalysisPage
										menuOption={menuOptions.fragor_analyser}
									/>
								}
							/>
							<Route
								path="/rattsfallsokning/:searchType"
								element={
									<ProtectedRoute>
										<SbtResultPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/lagkommentarer/:lawDocumentId"
								element={
									<ProtectedRoute>
										<SbtResultPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/nyhetsbrev/:id"
								element={<NewsletterPage />}
							/>
							<Route
								path="/nyhetsbrev/avregistrera"
								element={<NewsletterUnsubscribePage />}
							/>
							<Route
								path="/nyhetsbrev/:id/email/2"
								element={<NewsletterPage plain={true} />}
							/>
							<Route
								path="/nyhetsbrev"
								element={
									<ProtectedRoute>
										<NewsletterListPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/lagkommentarer"
								element={
									<ProtectedRoute>
										<LegalCommentsPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/blanketter"
								element={
									<ProtectedRoute>
										<FormsPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/informationsmaterial"
								element={
									<ProtectedRoute>
										<InformationMaterialPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/e-litteratur"
								element={
									<ProtectedRoute>
										<EBooksPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/anvandare"
								element={
									<ProtectedRoute>
										<UsersPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/favoriter"
								element={
									<ProtectedRoute>
										<FavoritesPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/bevakningar"
								element={
									<ProtectedRoute>
										<WatchlistPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/sokning"
								element={
									<ProtectedRoute>
										<SearchResultPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/utredningar"
								element={
									<ProtectedRoute>
										<InvestigationListPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/utredningar/:id/skrivut"
								element={
									<ProtectedRoute>
										<InvestigationPrintPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/glomtlosen"
								element={
									<LoginPage
										forgotPassword
										onSuccess={() => setIsLoggedIn(true)}
									/>
								}
							/>
							<Route
								path="/glomtlosen/:token"
								element={
									<ForgotPasswordPage
										type="forgotpassword"
										onSuccess={loginSuccess}
									/>
								}
							/>
							<Route
								path="/nyanvandare/:token"
								element={
									<ForgotPasswordPage
										type="newuser"
										onSuccess={loginSuccess}
									/>
								}
							/>
							<Route
								path="/inloggning"
								element={
									<AutoLoginPage onSuccess={loginSuccess} />
								}
							/>
							<Route
								path="/login.phtml"
								element={
									<AutoLoginPage onSuccess={loginSuccess} />
								}
							/>
							<Route
								path="/lawReportSingleViewer.phtml"
								element={<LegacySbtLinkPage />}
							/>
							<Route
								path="/lawDocumentViewer.phtml"
								element={<LegacySblLinkPage />}
							/>

							<Route path="/hjalp" element={<HelpPage />} />
							<Route path="/faq" element={<FaqPage />} />
						</Routes>
					</Box>
					<ToastContainer position="top-center" autoClose={3000} />
					{!isStandAlone && <Footer />}
				</Box>
				{!isStandAlone && currentUser && (
					<LiveChatWidget
						license="13295766"
						visitor={{
							name: currentUser ? currentUser.name : '',
							email: currentUser ? currentUser.email : '',
						}}
					/>
				)}
			</Worker>
		</>
	);
}

export default App;
