import React from 'react';
import { Page, Text, Link, Document, StyleSheet, Font } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { buildParagraphs, paginateParagraphs } from '../services/pdf-utils';

// Import and register Roboto font
import RobotoRegular from '../fonts/Roboto-Regular.ttf';
import RobotoItalic from '../fonts/Roboto-Italic.ttf';
import RobotoBold from '../fonts/Roboto-Bold.ttf';
import RobotoBoldItalic from '../fonts/Roboto-BoldItalic.ttf';

Font.register({
	family: 'Roboto',
	fonts: [
		{ src: RobotoRegular, fontStyle: 'normal', fontWeight: 'normal' },
		{ src: RobotoItalic, fontStyle: 'italic', fontWeight: 'normal' },
		{ src: RobotoBold, fontStyle: 'normal', fontWeight: 'bold' },
		{ src: RobotoBoldItalic, fontStyle: 'italic', fontWeight: 'bold' },
	],
});

const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		padding: 30,
		paddingBottom: 40,
		fontFamily: 'Roboto',
		fontSize: 12,
		lineHeight: 1.2,
		textAlign: 'justify',
	},
	title: {
		fontSize: 18,
		marginBottom: 12,
		lineHeight: 1.5,
	},
	header: {
		fontSize: 14,
		marginBottom: 8,
		lineHeight: 1.5,
	},
	text: {},
	paragraph: {
		marginBottom: 12,
	},
	blockquote: {
		margin: 10,
		paddingLeft: 20,
		fontStyle: 'italic',
	},
	bold: {
		fontWeight: 'bold',
	},
	italic: {
		fontStyle: 'italic',
	},
	underline: {
		textDecoration: 'underline',
	},
	pageNumber: {
		position: 'absolute',
		fontSize: 12,
		bottom: 20,
		left: 0,
		right: 0,
		textAlign: 'center',
		color: 'grey',
		fontFamily: 'Roboto',
	},
	link: {
		color: 'blue',
		textDecoration: 'underline',
		cursor: 'pointer',
	},
});

function HtmlPdf({ htmlString, title, subject, language }) {
	const htmlTitle = `<h1>${title}</h1><br />`;
	const paragraphs = buildParagraphs(htmlTitle + htmlString, styles);
	const avgPageMargin = (styles.page.padding + styles.paragraph.marginBottom) / 2;
	const pages = paginateParagraphs(paragraphs, styles.paragraph.marginBottom, avgPageMargin);

	return (
		<Document
			title={title}
			subject={subject}
			author='Infosoc Rättsdata'
			language={language}
			creator='Infosoc Rättsdata'
		>
			{pages.map((page, pageIndex) => (
				<Page key={`page-${pageIndex}`} size='A4' style={styles.page}>
					<view>
						{page.map((block, blockIndex) => (
							<Text key={blockIndex} style={styles.paragraph}>
								{block.map((item, itemIndex) => {
									if (item.type === 'link') {
										return (
											<Link key={`block-${itemIndex}`} src={item.link} style={item.style}>
												{item.content}
											</Link>
										);
									}

									return (
										<Text key={`block-${itemIndex}`} style={item.style}>
											{item.content}
										</Text>
									);
								})}
							</Text>
						))}
					</view>
					<Text
						style={styles.pageNumber}
						render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
						fixed
					/>
				</Page>
			))}
		</Document>
	);
}

HtmlPdf.propTypes = {
	htmlString: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	subject: PropTypes.string,
	language: PropTypes.string,
};

HtmlPdf.defaultProps = {
	subject: '',
	language: 'sv',
};

export default HtmlPdf;
