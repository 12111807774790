import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { get } from 'src/services/http-service';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Sbt from 'src/components/Sbt';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import BackIcon from '@mui/icons-material/ArrowCircleLeft';
import GoBackIcon from '@mui/icons-material/ArrowBack';
import NextIcon from '@mui/icons-material/ArrowCircleRight';
import InfosocIcon from 'src/components/InfosocIcon';
import Loading from 'src/components/Loading';

const SEARCH_PATHS = {
	1: 'Tema',
	2: 'Källa-tema',
	3: 'Publikationsbecteckning',
	5: 'Beslutsdatum',
	6: 'Part',
	7: 'Lagrum',
	8: 'Lagkommentarer',
};

function SbtResultPage() {
	let { searchType } = useParams();
	const [search, setSearch] = useSearchParams();
	const [entries, setEntries] = useState([]);
	const [pathNames, setPathNames] = useState([]);
	const [selectedId, setSelectedId] = useState(-1);
	const [sources, setSources] = useState([]);
	const [selectedSource, setSelectedSource] = useState('');
	const [loading, setLoading] = useState(true);

	const options = search.getAll('arg');
	const source = encodeURIComponent(search.get('k') || '');
	const lawDocumentId = search.get('lag') || '';
	const lawDocumentIndex = search.get('index') || '';

	const load = async () => {
		const optionsQS = options
			.map((o) => `option=${encodeURIComponent(o)}`)
			.join('&');

		const isLegalDocumentSearch = searchType === '8';
		const url = isLegalDocumentSearch
			? `/lawdocuments/${lawDocumentId}/${lawDocumentIndex}/lawreports?source=${source}`
			: `/lawreports?searchType=${searchType}&source=${source}&${optionsQS}`;

		const response = await get(url);

		setEntries(response.entries);
		setPathNames(response.pathNames);

		if (response.entries.length > 0) {
			setSelectedId(response.entries[0].id);
		}

		const totalCount = response.sources.reduce(
			(total, obj) => total + obj.count,
			0
		);
		setSources(
			response.sources.concat([
				{ codeFormatted: '-', label: '- Alla -', count: totalCount },
			])
		);
		setSelectedSource(source || '-');
		document.title = `Rättsfall (${response.entries.length}) | Infosoc`;

		if (isLegalDocumentSearch) {
			const response = await get(`/lawdocuments/${lawDocumentId}/info`);
			setPathNames([response.title]);
		}

		setLoading(false);
	};

	useEffect(() => {
		load();
	}, [searchType, source]);

	const currentIndex = entries.findIndex((e) => e.id === selectedId);

	const next = () => {
		const nextIndex =
			currentIndex === entries.length - 1 ? 0 : currentIndex + 1;
		setSelectedId(entries[nextIndex].id);
	};

	const prev = () => {
		const nextIndex =
			currentIndex === 0 ? entries.length - 1 : currentIndex - 1;
		setSelectedId(entries[nextIndex].id);
	};

	const changeSource = (event) => {
		setSelectedSource(event.target.value);
		const newSource = event.target.value === '-' ? '' : event.target.value;
		setSearch({
			k: newSource,
			arg: options,
			lag: lawDocumentId,
			index: lawDocumentIndex,
		});
	};

	if (entries.length === 0) {
		return (
			<>
				<AppBar
					sx={{
						zIndex: (theme) => theme.zIndex.drawer + 1,
						bgcolor: 'white',
						color: '#222',
					}}
				>
					<Box m={2}>
						<Stack direction="row">
							<Button
								variant="outlined"
								onClick={() => window.close()}
								startIcon={<GoBackIcon />}
							>
								Tillbaka
							</Button>
							<Box ml={2} mt={1}></Box>
						</Stack>
					</Box>
				</AppBar>
				<Box mt={10} ml={2}>
					{loading && (
						<Box m={4} sx={{ display: 'flex' }}>
							<Loading />
						</Box>
					)}
					{!loading && (
						<Typography variant="h5" gutterBottom>
							Inga träffar
						</Typography>
					)}
				</Box>
			</>
		);
	}

	return (
		<>
			<AppBar
				sx={{
					zIndex: (theme) => theme.zIndex.drawer + 1,
					bgcolor: 'white',
					color: '#222',
				}}
			>
				<Box m={2}>
					<Stack direction="row">
						<Button
							variant="outlined"
							onClick={() => window.close()}
							startIcon={<GoBackIcon />}
						>
							Tillbaka
						</Button>
						<Box ml={2} mt={1}>
							<Breadcrumbs aria-label="breadcrumb">
								<Typography variant="h6" color="text.primary">
									{SEARCH_PATHS[searchType]}
								</Typography>
								{pathNames.map((path, index) => (
									<Typography variant="h6" key={index}>
										{path}
									</Typography>
								))}
							</Breadcrumbs>
						</Box>
					</Stack>
				</Box>
			</AppBar>
			<Grid container>
				<Grid item style={{ width: 400 }}>
					<Box
						height="calc(100vh)"
						display="flex"
						flexDirection="column"
						style={{ borderRight: '2px solid #eee' }}
					>
						<Box mt={10}>
							<FormControl
								sx={{ m: 1, minWidth: '96%' }}
								size="small"
								margin="dense"
							>
								<InputLabel id="demo-select-small">
									Källa
								</InputLabel>
								<Select
									value={selectedSource}
									label="Källa"
									onChange={(event) => changeSource(event)}
								>
									{sources.map((source, index) => (
										<MenuItem
											value={source.codeFormatted}
											key={index}
										>
											{source.label} ({source.count})
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<Grid
								container
								direction="row"
								justifyContent="space-around"
								alignItems="center"
							>
								<Grid item>
									<IconButton
										aria-label="Föregående"
										size="large"
										onClick={() => prev()}
									>
										<BackIcon />
									</IconButton>
								</Grid>
								<Grid item>
									<Typography variant="h6">
										{currentIndex + 1} / {entries.length}
									</Typography>
								</Grid>
								<Grid item>
									<IconButton
										aria-label="Nästa"
										size="large"
										onClick={() => next()}
									>
										<NextIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Box>
						<Box flex={1} overflow="auto">
							<List
								sx={{
									width: '100%',
									maxWidth: 400,
									bgcolor: 'background.paper',
								}}
							>
								{entries.map((entry, index) => (
									<Box key={index}>
										<ListItemButton
											onClick={() =>
												setSelectedId(entry.id)
											}
											m={0}
											selected={selectedId === entry.id}
											autoFocus={selectedId === entry.id}
										>
											<ListItem alignItems="flex-start">
												<ListItemAvatar>
													<InfosocIcon
														type={entry.icon}
													/>
												</ListItemAvatar>
												<ListItemText
													primary={
														selectedId ===
														entry.id ? (
															<b>{entry.title}</b>
														) : (
															entry.title
														)
													}
													secondary={
														<span
															dangerouslySetInnerHTML={{
																__html: entry.text,
															}}
														/>
													}
												/>
											</ListItem>
										</ListItemButton>
										<Divider />
									</Box>
								))}
							</List>
						</Box>
					</Box>
				</Grid>
				<Grid item xs>
					<Box
						height="calc(100vh)"
						display="flex"
						flexDirection="column"
					>
						<Box flex={1} overflow="auto" p={4} mt={8}>
							<Sbt id={selectedId} />
						</Box>
					</Box>
				</Grid>
			</Grid>
		</>
	);
}

export default SbtResultPage;
