
import React, { useState } from "react";

import PlainAppBar from "src/components/PlainAppBar";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Typography from "@mui/material/Typography";

function FaqPage() {

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const questions = [
    { q: 'Hur gör jag för att se ändringar i en författning?', a: 'När du är inne i en lag eller någon annan författning så kan du klicka på ”Ändringar” längst upp till höger på sidan. Då får du fram en ny sida med tre kolumner. I den vänstra kolumnen listas de olika ändringarna i kronologisk ordning med den senaste ändringen överst. I mittenkolumnen beskrivs exakt hur lydelsen har ändrats i den ändring du markerat i vänsterkolumnen. Ändringarna anges med ny text i grön färg respektive upphävd text i röd färg. I högerkolumnen beskrivs vilka lagrum som berörs av ändringen. När det gäller särskilt viktiga ändringar kan här även anges en sammanfattande beskrivning av vad ändringen innebär i sak.' },
    { q: 'Hur använder jag lagbevakningsfunktionen?', a: 'När du är inne i en lag eller någon annan författning kan du genom att klicka på ”Bevaka” längst ut till höger bredvid författningstexten skapa en lagbevakning. Då har du möjlighet att få information via e-post om när de författningar du har lagt in för bevakning ändras. Du kan även kommentera den egna lagbevakningen och skriva ut din egen lista över bevakade författningar. För att se vilka författningar du har under bevakning klickar du på ”Lagbevakningar” i huvudmenyn till vänster när du är inloggad.' },
    { q: 'Kan jag få fram alla rättsfall som handlar om ett visst ämne där just vår kommun varit inblandad?', a: 'Ja, detta är mycket enkelt. Klicka bara på ”Rättsfall” i huvudmenyn och därefter på ”part”. Träfflistan som kommer fram är sorterad i alfabetisk ordning. Scrolla till namnet på den aktuella kommunen och därefter framgår av undermenyn till höger de olika ämnesområden där kommunen har varit inblandad i ett rättsfall.' },
    { q: 'Varför hittar jag inte fulltexten till en viss dom?', a: 'För de allra flesta rättsfall i rättsdatabasen finns det en ikon som heter Hhela domen” längst upp till höger som man kan klicka på för att få fram den fullständiga ursprungstexten till rättsfallet. Detta är standard sedan åtskilliga år. För en mindre andel äldre rättsfall är dock fulltext inte tillgänglig.' },
    { q: 'Hur gör jag för att hitta alla rättsfall som handlar om en specifik paragraf i en lag?', a: 'Klicka först på ”Rättsfall” i huvudmenyn och därefter på ”Lagrum” längst till höger i den liggande menyn. Du får då samtliga rättsfall i rättsdatabasen sorterade efter de författningar respektive rättsfall är kopplat till. Scrolla till lagen du eftersöker och klicka därefter på den aktuella paragrafen i undermenyn till höger så får du fram alla rättsfall som handlar om den paragrafen. Det är också möjligt att hitta rättsfall från specifika paragrafer i en lag eller annan författning genom att söka upp aktuell lag eller författning och klicka på aktuell paragraf. Till höger finns nu en lista från olika instanser där paragrafen har tillämpats.' },
    { q: 'Vilken är instansordningen för de allmänna domstolarna?', a: 'För närvarande gäller instansordningen: 1. Tingsrätt 2. Hovrätt 3. Högsta domstolen' },
    { q: 'Vilken är instansordningen för förvaltningsdomstolarna?', a: '1. Förvaltningsrätt 2. Kammarrätt 3. Högsta förvaltningsdomstolen' },
    { q: 'Varför får inte alla mål prövningstillstånd i högsta instans?', a: 'Högsta domstolens och Högsta förvaltningsdomstolens främsta uppgift är att skapa prejudikat (vägledande avgöranden), varför inte alla mål som överklagats ges prövningstillstånd. I undantagsfall prövas även ärenden där det finns grund för resning efter domvilla, eller om målets utgång i underinstansen berott på grovt förbiseende/misstag. Mark- och miljööverdomstolen ger prövningstillstånd om det kan finnas anledning att ändra mark- och miljödomstolens avgörande eller i syfte att bedöma om mark- och miljödomstolen dömt rätt. Mark- och miljööverdomstolen prövar även mål i syfte att skapa prejudikat eller om det finns andra synnerliga skäl att pröva ett överklagande.' },
    { q: 'Hur organiserar Infosoc rättsfall i fråga om domar som gått genom flera instanser?', a: 'Infosoc organiserar domarna efter högsta instans. Om en dom som redan finns i vår rättsdatabas överklagas ersätts denna av överinstansen så att ärendekedjan enbart finns på ett ställe. Detta innebär dock inte att underinstansen inte längre finns i rättsdatabasen, denna finns nu i stället att läsa i fulltextfilen hos överinstansen.' },
    { q: 'Hur kan jag söka rättsfall från en viss instans?', a: 'Det finns flera olika sätt du kan göra detta på. Det mest användbara sättet är att söka via kombinationen källa och teman. Du hittar detta genom att välja ”Rättsfall” i menyn till vänster och sedan klicka på ”Källa-teman” högst upp under sökrutan. Välj instansen du söker i listan och gå sedan vidare via teman för att hitta den typen av ärende du är intresserad av. Du kan även söka via ”Målnr/Dnr” om du vet exakt vilken dom du söker, eller via ”Beslutsdatum” om du känner till när domen avgjordes. Ett ytterligare sätt att söka rättsfall från en viss instans är genom fritextsökning. I rutan för fritext kan du söka på rättsfall bl.a. genom ett eller flera ord. När du gjort sökningen kan du filtrera denna genom att markera ”Rättsfall” till vänster. Högst upp i den mittersta kolumnen är det nu möjligt att välja instans. Klicka i den instans du är intresserad av och du får endast träffar utifrån det ord du sökt och från den valda instansen.' },
    { q: 'Vad händer med underinstansdomar i rättsdatabasen efter eventuell överprövning?', a: 'Överinstansdomen kommer att ersätta domen från underinstans. En underinstansdom kommer således tas bort ur rättsdatabasen om det kommer en överinstansdom efter överprövning.' },
    { q: 'Hur vet jag om en dom är överklagad?', a: 'Du kan skicka ett e-postmeddelande till Infosoc med en förfrågan om detta. Infosoc kan då ge svar på om den är överklagad eller inte. För vissa utvalda instanser inom vissa rättsområden gör Infosoc dock en kontinuerlig uppföljning. I dessa fall skriver Infosoc längst ner i kommentarerna, efter domskälen, om domen är överklagad eller inte.' },
    { q: 'När kontrolleras om domar är överprövade eller ej?', a: 'För vissa utvalda instanser inom vissa rättsområden gör Infosoc en kontinuerlig uppföljning. Tiden för överklagande är normalt tre veckor. Sedan får man alltid lägga till ett par dagar till för handläggning. Infosoc kontrollerar således om domen blivit överklagad efter ungefär fyra veckor. Om domen är äldre än detta finns informationen vid publicering av domen. Om domen är nyare kompletteras detta efter hand.' },
    { q: 'Hur vet jag om en dom har fått prövningstillstånd (PT)?', a: 'Du kan skicka ett e-postmeddelande till Infosoc med en förfrågan om detta. Infosoc kan då ge svar på frågan. För vissa utvalda instanser inom vissa rättsområden gör Infosoc dock en kontinuerlig uppföljning. I dessa fall skriver Infosoc längst ner i kommentarerna om domen har fått PT eller inte. Om det i domen endast står att den är överklagad har beslut om PT ännu inte tagits. Alternativt har inte Infosoc uppdaterat texten ännu. Kontroll om domar har fått PT görs kontinuerligt. Det är svårt att på den exakta dagen uppdatera texten då tiden för domstolen att bedöma om en dom skall få PT eller inte varierar kraftigt. Du kan skicka ett e-postmeddelande till Infosoc med en förfrågan för en extra kontroll.' },
    { q: 'Hur lång tid tar det till ett beslut om prövningstillstånd (PT)?', a: 'För Högsta förvaltningsdomstolen varierar tiden till beslut om PT kraftigt. Det kan ta upp till ett halvår plus minus ett flertal månader. Mark- och miljööverdomstolen har som målsättning att avgöra PT i merparten av målen inom två månader. Detta följs relativt väl och endast ett fåtal domar tar längre tid.' },
    { q: 'Gör ni ett urval av domar?', a: 'Ja, våra jurister gör ett urval av domar från underinstans. Vad gäller domar från högsta instans, exempelvis AD, MÖD, HFD och HD, sker dock inget urval utan därifrån tar vi med allt som är relevant för respektive rättsdatabas.' },
    { q: 'Varför sker ett urval? Varför lägger ni inte in allt?', a: 'Ett urval görs i syfte att göra våra rättsdatabaser mer användarvänliga. Det finns ingen anledning att ta med allt från underinstans, då det skulle bli allt för svårsökt för våra kunder. Alla våra rättsfall är dessutom bearbetade, dvs vi lägger inte enbart in domen som PDF utan har bedömt utifrån våra kunders önskan, att det är mer betydelsefullt att få alla de domar som läggs in sammanfattade och kommenterade.' },
    { q: 'Hur gör ni urval från underinstans?', a: 'Varje fall är självklart unikt men har man en eller flera domar som säger en viss sak finns det ingen anledning att lägga in ytterligare en dom från underinstans som säger samma sak och som således bara bekräftar det som redan finns inlagt.' },
    { q: 'Hur lång tid tar det från det att domen kom tills den finns inlagd i era rättsdatabaser?', a: 'Vi får listor från alla domstolar varje dag på nya avgöranden och beställer då omgående veckans nya domar. Så snart vi fått dessa påbörjas bearbetningen och så snart domen är sammanfattad och kommenterad blir den synlig för kund och kommer därefter med i nyhetsbrevet.' },
    { q: 'Om jag vill ha en dom från en underinstans och inte hittar den i era rättsdatabaser antar jag att ni inte valt ut den av en eller annan anledning. Kan jag genom er ändå få tillgång till en sådan underinstansdom?', a: 'Absolut. Vår service sträcker sig längre än bara det ni ser och har tillgång till i rättsdatabasen. Vi har ett arkiv av underinstansdomar hos oss, vilket innebär att om en kund vill ha en underinstansdom skickar vi den via e-post till kunden omgående och kan därefter, om kunden önskar, också lägga in den i rättsdatabasen.' },
    { q: 'Är gamla domar aktuella?', a: 'Domar, oavsett hur gamla de är, kan utgöra gällande rätt. Även om det i domen anges lagrum som inte längre motsvaras av nu gällande lagstiftning finns domen kvar i vår rättsdatabas under förutsättning att då gällande lagrum motsvarar dagens bestämmelser. Detta innebär att den gamla domen är omlänkad till nu gällande lagrum. Argumentationen i domen kan, p.g.a. att lagrummet fortfarande har en motsvarighet i gällande rätt, då vara relevant.' },
    { q: 'Varför finns det en typ av träff som benämns "proposition" när jag söker bland rättsfall?', a: 'När man söker rättsfall finns förutom domar i vissa fall även så kallade propositionsutdrag med. Söker du exempelvis rättsfall utifrån en viss paragraf kan du alltså fram även ett utdrag ur propositionen som handlar om just denna paragraf. Ibland saknar ju ett visst tema eller en viss paragraf relevanta rättsfall men möjligheten finns då att detta har behandlats i propositionen och då kan detta utdrag finnas inlagt.' },
    { q: 'Varför finns det en sökväg som heter lagrum när jag befinner mig under rättsfall?', a: 'Denna sökväg är till för att du ska kunna hitta rättsfall genom att ange lag och därefter paragraf i den lag du valt. Du kan alltså få fram rättsfall och propositionsutdrag som handlar om en viss lag och paragraf.' },
    { q: 'Hur fungerar Favorit-funktionen?', a: 'Både rättsfall och lagar kan sparas som favoriter för att lättare och snabbare hitta dem nästa gång. Man skapar favoritkataloger som ett första steg genom att via startsidan välja Favoriter och därefter klicka på plus-tecknet till höger om Kataloger. Den nya katalogen namnges i den ruta som kommer upp. Underkataloger kan också skapas genom att markera den katalog man vill göra en underkatalog till och göra på samma sätt i nästa kolumn. När man skapat sina kataloger på önskat sätt går det bra att spara ner rättsfall och lagar i den katalog som önskas. Välj Spara i favoriter när du befinner dig i exempelvis ett rättsfall och vill lägga in detta under favoriter. Det som har sparats ner på detta sätt återfinns i dina favoritkataloger via rättsdatabasens startsida nästa gång du vill komma åt dem.' },
    { q: 'Vad är det för material som återfinns under informationsmaterial?', a: 'Under "Informationsmaterial" återfinns exempelvis handböcker, vägledningar och checklistor. Det är material av mer vägledande karaktär. Materialet återfinns under ”Informationsmaterial” men är också sökbart via fritextsökning.' },
    { q: 'Hur ofta utkommer era nyhetsbrev?', a: 'Samtliga Infosoc nyhetsbrev utkommer varje vecka. Två gånger per år skickar vi dessutom ut lagbrev. Dessa utkommer vid varje hel- och halvårsskifte och innehåller information om samtliga nya/ändrade författningar som skett i samband med varje halv- eller helårsskifte. Viktiga författningar beskrivs mer utförligt. Länkar till samtliga nya/ändrade författningar återges i dessa båda brev.' },
    { q: 'Hur fungerar utredningsfunktionen?', a: 'Funktionen gör det möjligt att kopiera valfri text från rättsdatabasen olika delar och sammanställa i ett och samma dokument. När du gör en textmarkering i rättsdatabasen kommer det att öppnas en ruta där du har möjlighet att skapa en utredning. Utredningsfunktionen finns även i olika menyer i rättsdatabasen. I dessa fall behöver du inte göra någon markering. Du kan exempelvis snabbt spara en sammanfattning i ett rättsfall eller kanske ett helt lagrum. Du kan givetvis välja att göra en markering även i de fall som utredningsfunktionen förekommer i menyn. Dina utredningar hittar du på startsidan där du kan utföra viss redigering i efterhand. Du kan även skriva egen text samt klistra in text från externt material eller externa hemsidor. En stor fördel med utredningsfunktionen jämfört med olika ordbehandlingsprogram är att du inte behöver redigera texten i efterhand. Texten anpassas automatiskt till utredningens utformning. Utredningen kan du sedan skriva ut alternativt kopiera till annat ordbehandlingsprogram genom att markera texten.' },
  ]

  return (
    <Box mt={0} mb={8}>
      <Box mt={0} ml={2} maxWidth={1400}>

        {questions.map((entry, index) => (
          <Accordion disableGutters expanded={index === selectedIndex} onChange={() => setSelectedIndex(index === selectedIndex ? -1 : index)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant="subtitle2">
                {index === selectedIndex ? <b>{entry.q}</b> : entry.q}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{entry.a}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}

      </Box>
    </Box>
  );
}

export default FaqPage;