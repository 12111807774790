
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

function ReadMore({ text, maxLength }) {

    const linkStart = text.indexOf('<a ');
    const shortenedText = linkStart > 0? text.substring(0, linkStart) : text;
    
    const length = shortenedText.length;
    const overflow = length > maxLength;

    const preview = overflow ? shortenedText.substring(0, maxLength) + '...' : shortenedText;

    const previewWithoutHtml = preview.replace(/(<([^>]+)>)/ig, '');

    return <Typography variant="body2" >{previewWithoutHtml}</Typography>;
}

ReadMore.propTypes = {
    text: PropTypes.string,
    maxLength: PropTypes.number,
}

ReadMore.defaultProps = {
    maxLength: 300
}

export default ReadMore;

