// pdf-utils.js
const A4_PAGE_HEIGHT = 842; // Height in points for A4 size
const A4_PAGE_WIDTH = 595; // Width in points for A4 size
const AVERAGE_CHAR_WIDTH = 5.9; // Average character width in points
const SCALING_FACTOR = 1.05; // Scaling factor to adjust for font rendering differences

function getEffectiveStyle(style) {
    // Provide a robust way to handle missing or undefined values and ensure all inputs are treated as numbers
    const convert = (value, defaultValue) => value !== undefined ? Number(value) : defaultValue;

    const lineHeight = Number(style.lineHeight || 1.2);
    const fontSize = Number(style.fontSize || 12);

    // Apply default values where none are provided and ensure all are converted to points
    const padding = convert(style.padding, 0);
    const margin = convert(style.margin, 0);

    const paddingTop = convert(style.paddingTop, padding);
    const paddingBottom = convert(style.paddingBottom, padding);
    const paddingLeft = convert(style.paddingLeft, padding);
    const paddingRight = convert(style.paddingRight, padding);
    const marginTop = convert(style.marginTop, margin);
    const marginBottom = convert(style.marginBottom, margin);
    const marginLeft = convert(style.marginLeft, margin);
    const marginRight = convert(style.marginRight, margin);

    return {
        paddingTop, paddingBottom, paddingLeft, paddingRight,
        marginTop, marginBottom, marginLeft, marginRight,
        lineHeight, fontSize
    };
}

function estimateTextHeight(text, style) {
    const {
        fontSize,
        lineHeight,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight
    } = getEffectiveStyle(style);

    // Calculate the available width for text within the margins
    const effectiveWidth = A4_PAGE_WIDTH - marginLeft - marginRight - paddingLeft - paddingRight;

    // Ensure the width is positive to avoid errors
    if (effectiveWidth <= 0) {
        console.error("Effective width is too small or negative, check your margin settings.");
        return 0;  // Prevent calculations when width is non-positive
    }

    if (text === '\n') {
        return (lineHeight * fontSize * SCALING_FACTOR) + paddingTop + paddingBottom + marginTop + marginBottom
    }

    // Split the text by new lines to handle multi-paragraphs or manual breaks
    const lines = text.split('\n');
    let totalHeight = 0;

    // Calculate height for each line segment
    lines.forEach((line, index) => {
        if (['','\n'].includes(line) && index === lines.length - 1) {
            return;  // Skip the last line if it's an empty line
        }

        const charWidth = AVERAGE_CHAR_WIDTH;
        const charsPerLine = Math.max(1, Math.floor(effectiveWidth / charWidth));
        const numLines = Math.max(1, Math.ceil(line.length / charsPerLine));

        totalHeight += ((lineHeight * fontSize * SCALING_FACTOR) * numLines);
    });

    // Add padding and margins to the total height
    return totalHeight + paddingTop + paddingBottom + marginTop + marginBottom;
}

// Processing text blocks and organizing them into pages
export function paginateTextBlocks(textBlocks, margin = 30) {
    let pages = [];
    let currentPageContent = [];
    let currentHeight = 0;

    textBlocks.forEach(item => {
        const itemHeight = estimateTextHeight(item.content, item.style);

        if (currentHeight + itemHeight > (A4_PAGE_HEIGHT - margin)) {
            pages.push(currentPageContent);
            currentPageContent = [];
            currentHeight = 0;
        }

        currentPageContent.push({...item, height: itemHeight});
        currentHeight += itemHeight;
    });

    if (currentPageContent.length > 0) {
        pages.push(currentPageContent);
    }

    return pages;
}

export function paginateParagraphs(paragraphs, paragraphMargin = 8, pageMargin = 30) {
    let pages = [];
    let currentPageContent = [];
    let currentHeight = pageMargin;  // Start each page with the initial top margin

    paragraphs.forEach(paragraph => {
        let paragraphHeight = paragraphMargin;
        let paragraphContent = [];

        paragraph.forEach(textBlock => {
            const blockHeight = estimateTextHeight(textBlock.content, textBlock.style);
            paragraphHeight += blockHeight;
            paragraphContent.push({...textBlock, height: blockHeight});
        });

        if (currentHeight + paragraphHeight > (A4_PAGE_HEIGHT - pageMargin)) {
            if (currentPageContent.length > 0) {
                pages.push(currentPageContent);
                currentPageContent = [];
                currentHeight = pageMargin;
            }
        }

        // Add the whole paragraph to the current page
        currentPageContent.push(paragraphContent);
        currentHeight += paragraphHeight;
    });

    // Check if there's content left for the last page
    if (currentPageContent.length > 0) {
        pages.push(currentPageContent);
    }

    return pages;
}

function replaceElementsWithPlaceholders(htmlString) {
    // Parse the HTML string into a DOM object
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Object to hold the elements and their contents
    const elementsMap = {};
    let placeholderIndex = 0;

    // Traverse all elements in the DOM
    doc.body.querySelectorAll('*').forEach((element) => {
        const tagName = element.tagName.toLowerCase();
        const key = `${tagName}${placeholderIndex}`;
        elementsMap[key] = element.outerHTML;

        // Replace the element with a placeholder
        const placeholder = `{{${key}}}`;
        element.replaceWith(placeholder);

        placeholderIndex++;
    });

    // Serialize the modified DOM back to an HTML string
    const modifiedHtmlString = doc.body.innerHTML;

    return { modifiedHtmlString, elementsMap };
}

export function buildParagraphs(htmlString, styles = {}) {
    const cleanString = htmlString.replace(/<br\s*\/?>/g, '\n');
    
    const { modifiedHtmlString, elementsMap } = replaceElementsWithPlaceholders(cleanString);
    const paragraphs = modifiedHtmlString.split('\n');
    const placeholders = Object.entries(elementsMap);

    return paragraphs.reduce((acc, paragraph) => {
        let text = paragraph.trimStart();
        if (text.length > 0) {
            // Replace the placeholders with the original elements if they exist
            placeholders.forEach(([key, value]) => {
                text = text.replace(new RegExp(`{{${key}}}`, 'g'), value);
            });


            acc.push(parseStyledHtml(text, styles));
        }
        return acc;
    }, []);
}

export function parseStyledHtml(htmlString, styles) {    
    const container = document.createElement('div');
	container.innerHTML = htmlString;

	function traverseNodes(node, inheritedStyle, isFirstChild = true, isLastChild = true) {
		if (node.nodeType === Node.TEXT_NODE && node.textContent.trim()) {
            let style = { ...inheritedStyle, ...getStyleByTag('span', styles)};
			
            return { content: node.textContent, style, type: 'text' };
		} else if (node.nodeType === Node.ELEMENT_NODE) {
			const tagName = node.tagName.toLowerCase();
			const nodeStyle = { ...inheritedStyle, ...getStyleByTag(tagName, styles) };
			const children = Array.from(node.childNodes);

			if (tagName === 'a') {
				return [{
					content: node.textContent,
					style: nodeStyle,
					type: 'link',
					link: node.href,
				}];
			}

			return children.flatMap((child, idx) =>
				traverseNodes(child, nodeStyle, idx === 0, idx === children.length - 1)
			);
		}

		return [];
	}

	function getStyleByTag(tagName, styles = {}) {
		switch (tagName) {
			case 'h1':
				return styles.title;
			case 'h2':
			case 'h3':
			case 'h4':
			case 'h5':
			case 'h6':
				return styles.header;
			case 'blockquote':
				return styles.blockquote;
			case 'b':
			case 'strong':
				return styles.bold;
            case 'br':
                return styles.break;
			case 'i':
			case 'em':
				return styles.italic;
			case 'u':
				return styles.underline;
			case 'a':
				return styles.link;
			case 'span':
			case 'p':
            case 'div':
				return styles.text;
			default:
				return {};
		}
	}

	return Array.from(container.childNodes).flatMap((node) => traverseNodes(node, styles.text));
}