import React, { useEffect, useState } from 'react';
import { Box, Stack, Button, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import Sbl from 'src/components/Sbl';
import AppBar from '@mui/material/AppBar';
import GoBackIcon from '@mui/icons-material/ArrowBack';
import { get } from '../../services/http-service';
import { useWidgetState } from '@livechat/widget-react';

const SblPage = () => {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [sblDocument, setSblDocument] = useState({});

  useEffect(() => {
    document.title = title;
  }, [title]);

  const loadSBLDocumentInfo = (id) => {
    const response = get(`/lawdocuments/${id}`);
    
    response.then((sblDocumentData) => {
      setSblDocument(sblDocumentData);
    });
  }

  useEffect(() => {
    loadSBLDocumentInfo(id);
  }, []);

  return (
    <>
      <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: 'white', color: '#222' }}>
        <Box m={2}>
          <Stack direction="row">
            <Button variant="outlined" onClick={() => window.close()} startIcon={<GoBackIcon />} sx={{ minWidth: 'fit-content'}}>
              Tillbaka
            </Button>
            <Box ml={2} mt={0} sx={{ display: 'inline-grid' }}>
              <Typography variant="h6" title={title} component="div" sx={{ whiteSpace: 'nowrap', marginTop: '2px', textOverflow: 'ellipsis', overflow: 'hidden', height: '28px'}}>{title}</Typography>
            </Box>
            <Box ml={1} mt={0} sx={{ display: 'inline-grid' }}>
              <Typography variant="h6" title={title} component="div" sx={{ minWidth: '136px', marginTop: '2px', height: '28px'}}>{`(${sblDocument.sfsNumber})`}</Typography>
            </Box>
          </Stack>
        </Box>
      </AppBar>

      <Box sx={{ flexGrow: 1 }} mt={10}>
        <Sbl idOrFileName={ String(id) } onLoaded={ (info) => setTitle(info.title) } />
      </Box>
    </>
  );
};

export default SblPage;