import React, { useState, useEffect } from 'react';
import { get, post } from "../services/http-service";
import { Button, Select, MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import WatchlistIcon from '@mui/icons-material/Notifications';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import storageService from 'src/services/storage-service';
import { toast } from 'react-toastify';
import InfosocIcon from './InfosocIcon';
import Loading from './Loading';
import LocalStorageService from 'src/services/local-storage-service'
import DialogClose from './DialogClose';
import SblDialog from './SblDialog';

const SELECTED_TYPE_SBT_CATEGORY = 0;
const SELECTED_TYPE_SBT_LAWPLACE = 1;
const SELECTED_TYPE_SBA = 2;
const SELECTED_TYPE_SBA_ENTIRE = 3;

function AddWatchDialog({ open, onClose }) {

  const [selectedType, setSelectedType] = useState(SELECTED_TYPE_SBT_CATEGORY);
  const [sendEmail, setSendEmail] = useState(false);

  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState([]);

  const [sblId, setSblId] = useState(-1);
  const [selectedSblIndex, setSelectedSblIndex] = useState(-1);
  const [selectedSblIndexName, setSelectedSblIndexName] = useState('');

  const [register1, setRegister1] = useState([]);
  const [register2, setRegister2] = useState([]);
  const [register3, setRegister3] = useState([]);

  const [fetchAll, setFetchAll] = useState(false);

  const [step, setStep] = useState(1);

  const login = storageService.load();
  const isEmailLogin = login.email && login.email.indexOf('@') > 0;
  const localStorageService = new LocalStorageService();

  useEffect(() => {
    if (open) {

      setStep(1);
      setSelectedType(isEmailLogin ? SELECTED_TYPE_SBT_CATEGORY : SELECTED_TYPE_SBA);
      setSendEmail(false);
    }
  }, [open]);

  useEffect(() => {

    // If ALL_LAWS_KEY is set to true then circumvent DB access for register 1 and 2
    let allLawsSelected = localStorageService.get(localStorageService.ALL_LAWS_KEY());

    if (allLawsSelected == null) {
      allLawsSelected = true;
    }

    if (typeof allLawsSelected === 'boolean') {
      setFetchAll(allLawsSelected);
    }
  }, []);

  const onSubmit = async () => {

    if (selectedType === SELECTED_TYPE_SBT_CATEGORY) {
      const form = new FormData();
      const selectedCategories = categories.filter(e => e.selected && !e.disabled);
      form.append('sbtCategoryIds', selectedCategories.map(e => e.id).join(','));
      form.append('sendEmail', sendEmail);

      await post('/watchlist/sbt/category', form, true);

      toast.success('Du bevakar nu: ' + selectedCategories.map(e => e.name).join(', '));

      onClose('sbt');
    }
  }

  const toggleCategory = (index, checked) => {
    const updatedCategories = [...categories];

    updatedCategories[index].selected = checked;
    setCategories(updatedCategories);
  }


  const nextStep = async (selectedType) => {

    if (selectedType === SELECTED_TYPE_SBT_CATEGORY) {
      setLoading(true);
      const response = await get(`/watchlist/sbt/categories`);
      setCategories(response.entries);
      setStep(2);
      setLoading(false);
    }
    else {
      setLoading(true);
      const response = await get('/lawdocuments/watchlist/categories?fetchAll=' + fetchAll);

      setRegister1([{ id: -1, title: '- Välj lag -' }].concat(response.register1));
      setRegister2([{ id: -1, title: '- Välj föreskrifter & råd -' }].concat(response.register2));
      setRegister3([{ id: -1, title: '- Välj EU-bestämmelser -' }].concat(response.register3));

      setStep(2);
      setLoading(false);
    }
  }

  const getSblFormData = (id) => {
    const form = new FormData();
    form.append('linkType', 'lawdocument');
    form.append('linkId', id || sblId);
    form.append('sendEmail', sendEmail);
    return form;
  }

  const selectLawPlace = async (id) => {

    if (selectedType === SELECTED_TYPE_SBT_LAWPLACE || selectedType === SELECTED_TYPE_SBA) {
      setSblId(id);
    }
    else if (selectedType === SELECTED_TYPE_SBA_ENTIRE) {
      const form = getSblFormData(id);
      form.append('entire', true);
      form.append('watchType', 'sbl');

      await post('/watchlist', form, true);
      toast.success("Du bevakar nu ändringar i hela lagen");
      onClose('sba');
    }
  }

  const onSelectSection = async ({ selectedIndex, chapterName, paragraphName }) => {

    const isSbt = selectedType === SELECTED_TYPE_SBT_LAWPLACE;

    const form = getSblFormData();
    form.append('linkParams', selectedIndex);
    form.append('entire', false);
    form.append('watchType', isSbt ? 'sbt' : 'sbl');

    await post('/watchlist', form, true);

    setSblId(-1);

    const title = [chapterName || '', paragraphName || ''].join(' ');

    toast.success((isSbt ? "Du bevakar nu nya rättsfall under " : "Du bevakar ändringar under ") + title);
    onClose(isSbt ? 'sbt' : 'sba');
  }

  if (loading) {
    <Loading />
  }

  const disableOk = categories.filter(c => c.selected).length === 0;

  return (
    <Dialog
      open={open}
      scroll="paper"
    >
      <Box minWidth={600}>
        <DialogTitle>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Box mt={1} mr={1}>
                <WatchlistIcon color="secondary" />
              </Box>
            </Grid>
            <Grid item color="secondary">
              <Typography variant="h6" color="secondary">Ny bevakning</Typography>
            </Grid>
          </Grid>
          <DialogClose onClose={() => onClose()} />
        </DialogTitle>
        <DialogContent dividers>


          {step === 1 && <>
            <RadioGroup
              value={selectedType}
              onChange={(event) => setSelectedType(parseInt(event.target.value))}
            >
              <FormControlLabel disabled={!isEmailLogin} value={SELECTED_TYPE_SBT_CATEGORY} control={<Radio />} label={`Nya rättsfall under valt tema ${!isEmailLogin ? ' (endast personling inloggning)' : ''}`} />
              <FormControlLabel disabled={!isEmailLogin} value={SELECTED_TYPE_SBT_LAWPLACE} control={<Radio />} label={`Nya rättsfall under valt lagrum ${!isEmailLogin ? ' (endast personling inloggning)' : ''}`} />
              <FormControlLabel value={SELECTED_TYPE_SBA} control={<Radio />} label="Lagändringar under valt lagrum" />
              <FormControlLabel value={SELECTED_TYPE_SBA_ENTIRE} control={<Radio />} label="Lagändringar under hela lagen" />
            </RadioGroup>
          </>}

          {step === 2 && isEmailLogin &&
            <Box mb={2} p={2} style={{ background: '#eee', borderRadius: 5 }}>
              <FormControlLabel control={<Checkbox checked={sendEmail} onChange={(event) => setSendEmail(event.target.checked)} />} label="Bevaka via e-post" />
            </Box>
          }

          {step === 2 && selectedType === SELECTED_TYPE_SBT_CATEGORY && <>
            <Box mb={2}>
              <Typography variant="h6">Välj de teman du vill bevaka</Typography>
            </Box>
            {categories.map((entry, index) => (
              <Box key={index}>
                <FormControlLabel control={<Checkbox disabled={entry.disabled} checked={entry.selected} onChange={(event) => toggleCategory(index, event.target.checked)} />} label={entry.name} />
              </Box>
            ))}
          </>}

          {step === 2 && selectedType !== SELECTED_TYPE_SBT_CATEGORY && <>
            <Box mb={2}>
              <Typography variant="h6">Välj vilken lag du vill bevaka</Typography>
            </Box>

            <Select fullWidth value={sblId} onChange={(event) => { selectLawPlace(event.target.value); }}>
              {register1.map((r, index) => <MenuItem key={index} value={r.id}>{r.title}</MenuItem>)}
            </Select>

            <Select fullWidth value={sblId} onChange={(event) => { selectLawPlace(event.target.value); }}>
              {register2.map((r, index) => <MenuItem key={index} value={r.id}>{r.title}</MenuItem>)}
            </Select>

            <Select fullWidth value={sblId} onChange={(event) => { selectLawPlace(event.target.value); }}>
              {register3.map((r, index) => <MenuItem key={index} value={r.id}>{r.title}</MenuItem>)}
            </Select>

          </>}


        </DialogContent>
        <DialogActions>

          <Box m={2}>
            {step === 1 && <>
              <Button endIcon={<InfosocIcon type="arrowright" />} disabled={loading} variant="contained" onClick={() => nextStep(selectedType)}>Nästa</Button>
            </>}

            {step === 2 && <>
              <Button variant="contained" onClick={onSubmit} disabled={disableOk}>OK</Button>
            </>}

          </Box>

        </DialogActions>
      </Box >

      <SblDialog id={sblId} open={sblId > 0} onClose={() => setSblId(-1)} selectMode={true} onSelectSection={onSelectSection} />
    </Dialog >
  )

}

AddWatchDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

AddWatchDialog.defaultProps = {
  onClose: () => { },
  id: -1,
};

export default AddWatchDialog;