import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import NewIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import ReadMore from 'src/components/ReadMore';

function News({ entries, loading, onSelectedSbt, onSelectedSbl, onSelectedNews }) {

  const [selected, setSelected] = useState(0);
  const [showMore, setShowMore] = useState(false);

  const selectedEntries = entries.length > 0 ? entries[selected] : { posts: [] };
  const minHeight = selected === 1 ? 300 : 350;

  const postsToShow = showMore ? selectedEntries.posts.concat(selectedEntries.extraPosts) : selectedEntries.posts;

  const changeTab = (index) => {
    setSelected(index);
    setShowMore(false);
  }

  const selectNews = (entry) => {

    const href = entry.href.startsWith('/') ? entry.href.substring(1) : entry.href;

    const tokens = href.split("/");

    if (tokens[0] === 'rattsfall') {
      onSelectedSbt(tokens[1], entry.title);
    }
    if (tokens[0] === 'lag') {
      onSelectedSbl(tokens[1], entry.newDocument);
    }
    if (tokens[0] === 'nyhet') {
      onSelectedNews(entry.title, entry.preamble);
    }
  }

  return (
    <>
      <Tabs value={selected} onChange={(event, newValue) => changeTab(newValue)} aria-label="Nya rättsfall, Lagnyheter, Omvärldsbevakning">
        <Tab label="Nya rättsfall" value={0} />
        <Tab label="Lagnyheter" value={1} />
        <Tab label="Omvärldsbevakning" value={2} />
      </Tabs>
      <Box mt={2} style={{ background: '#f2f2f2', padding: 15, borderRadius: '10px' }}>
        <Grid
          container
          spacing={2}
        >
          {postsToShow.map((entry, index) => (
            <Grid item sm={12} md={6} xl={4} key={index}>
              <Card style={{ cursor: 'pointer', minHeight }} onClick={() => selectNews(entry)}>

                <CardContent className='news-card'>
                  {selected === 1 && <Box mb={2}>
                    {entry.newDocument && <Chip label="Ny författning" variant="outlined" color="success" icon={<NewIcon />} />}
                    {selected === 1 && !entry.newDocument && <Chip label="Ändrad författning" variant="outlined" color="secondary" icon={<EditIcon />} />}
                  </Box>
                  }
                  <Typography variant="h6" gutterBottom>
                    {entry.title}
                  </Typography>
                  <Typography variant="h5" component="div">

                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {entry.topcorner}
                  </Typography>
                  {/* <Typography variant="body2" dangerouslySetInnerHTML={{ __html: entry.preamble }}>

                  </Typography> */}
                  {(selected === 0 || selected === 2) &&
                    <ReadMore text={entry.preamble} />
                  }

                </CardContent>
              </Card>
            </Grid>
          ))}
          {!showMore && !loading && selectedEntries.extraPosts.length > 0 && <Grid item sm={12} md={12} xl={4} key={-1}>
            <Button fullWidth startIcon={<NewIcon />} onClick={() => setShowMore(true)} disableElevation variant="outlined">Visa {selectedEntries.extraPosts.length - selectedEntries.posts.length} fler...</Button>
          </Grid>
          }
          {showMore && !loading && selectedEntries.extraPosts.length > 0 && <Grid item sm={12} md={12} xl={4} key={-1}>
            <Button fullWidth startIcon={<RemoveIcon />} onClick={() => setShowMore(false)} disableElevation variant="outlined">Visa färre</Button>
          </Grid>
          }
        </Grid>
      </Box>

    </>
  );
}

News.propTypes = {
  entries: PropTypes.array,
  loading: PropTypes.bool,
  onSelectedSbt: PropTypes.func,
  onSelectedSbl: PropTypes.func,
  onSelectedNews: PropTypes.func,
};


export default News;