import React from 'react';

function Highlight({ text, query }) {
  if (!query) return text || '';

  const matchStart = text.toLowerCase().indexOf(query);
  const queryLength = query.length;

  if (matchStart < 0) return text;

  return (
    <span>
      {text.substring(0, matchStart)}
      <span style={{ background: '#f2f2ab' }}>
        {text.substring(matchStart, matchStart + queryLength)}
      </span>
      {text.substring(matchStart + queryLength, text.length)}
    </span>
  );
}

export default Highlight;
