import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import Sbt from 'src/components/Sbt';

const SbtPage = () => {
	const { ref } = useParams();

	const id = Number(ref);
	const filename = String(ref);

	const onLoaded = (entry) => {
		if (entry) {
			document.title = entry.content.titleFull;
		}
	};

	return (
		<Box mt={12} ml={4} mr={4}>
			<Sbt id={id} fileName={filename} onLoaded={onLoaded} />
		</Box>
	);
};

export default SbtPage;
