import React, { useEffect, useState } from 'react';
import { Box, Badge } from '@mui/material/';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { get } from "src/services/http-service";
import StructuredSearch from 'src/components/StructuredSearch';

const HEADER_MAPPINGS = {
  1: ["Tema 1", "Tema 2", "Tema 3", "Tema 4"],
  2: ["Källa", "Tema 1", "Tema 2", "Tema 3"],
  3: ["Källa", "Publikation"],
  4: ["Källa", "Målnummer"],
  5: ["Källa", "Beslutsdatum"],
  6: ["Part", "Tema 1", "Tema 2", "Tema 3"],
  7: ["Lag", "Lagrum"],
}

const SbtStructuredSearchPage = ({ currentUser, menuOption }) => {

  const [selected, setSelected] = useState(1);

  document.title = 'Rättsfall | Infosoc';

  const selectEntry = (url) => {
    window.open(url);
  }

  const onSelectCategory = (listIndex, index, entry) => {
    return listIndex > 2 && selected === 4;
  }

  const headers = HEADER_MAPPINGS[selected];

  /* TODO: Remove me if permanent 
  
  const sbtPlusBadge = <Badge badgeContent={'+'} overlap="circular" color={"success"} ml={4}>
    <Box pr={4}>Målnummer</Box>
  </Badge>;

  const caseNumberLabel = currentUser && currentUser.featureSbtPlus ? sbtPlusBadge : 'Målnummer'; */

  return (
    <Box sx={{ flexGrow: 1 }} mt={10} ml={2}>
      <Tabs value={selected} onChange={(event, newValue) => setSelected(newValue)}>
        <Tab label="Tema" value={1} />
        <Tab label="Publikationsbeteckning" value={3} />
        <Tab label="Målnummer" value={4} />
        <Tab label="Beslutsdatum" value={5} />
        <Tab label="Part" value={6} />
        <Tab label="Lagrum" value={7} />
      </Tabs>

      <StructuredSearch
        currentUser={currentUser}
        onSelectCategory={onSelectCategory}
        headers={headers}
        baseUrl={`/lawreports/categories?searchType=${selected}`}
        showFilter={selected > 5}
        showSecondaryFilter={selected === 4 || selected === 3 || selected === 5}
        onSelectResult={(entry) => selectEntry(entry)}
        menuOption={menuOption}
      />
    </Box>
  );
}

export default SbtStructuredSearchPage;
