import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import PropTypes from 'prop-types';
import { UI_URL } from "src/services/http-service";

function Newsletter({ newsletter, showTitle }) {


  const replaceLinks = (text) => {
    let result = text.replaceAll('lawReportSingleViewer.phtml?id=', '/rattsfall/').replaceAll('a href', 'a target="_blank" href');
    result = result.replaceAll('href="rattsfall/', 'href="/rattsfall/');
    result = result.replaceAll('href="lag/', 'href="/lag/');

    result = result.replaceAll('href="/rattsfall/', `href="${UI_URL}/rattsfall/`);
    result = result.replaceAll('href="/lag/', `href="${UI_URL}/lag/`);

    return result;
  }

  return (

    <Box ml={4}>
      {showTitle && <Box>
        <Typography variant="h4">{newsletter.titleInContent.replace('Nyhetsbrev - ', '').replace('Nyhetsbrev ', '')}</Typography>
      </Box>}
      <Box mt={2}>
        <img src={`/newsletter/db_${newsletter.database.toLowerCase()}.jpg`} alt={`Database ${newsletter.database}`} style={{ borderRadius: 5, border: '1px solid #bbb', width: 900 }} />
      </Box>
      <Box pr={4} maxWidth={900}>
        {newsletter.sections.map((section, index) =>
          <Box key={index} mt={2} mb={6}>
            <Box mb={1}>
              <Typography variant="h4">{section.title}</Typography>
            </Box>
            {section.articles.map((article, articleIndex) =>
              <Box mb={1} component="article">
                <Typography variant="h6" dangerouslySetInnerHTML={{ __html: replaceLinks(article.title) }} />
                <Typography variant="body2" dangerouslySetInnerHTML={{ __html: replaceLinks(article.text) }} />
                <Divider />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>

  );
}

Newsletter.propTypes = {
  newsletter: PropTypes.object,
  showTitle: PropTypes.bool
};

Newsletter.defaultProps = {
  showTitle: true,
  absoluteUrls: false
}

export default Newsletter;