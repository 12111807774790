import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InfosocIcon from './InfosocIcon';

function DialogCloseExpand({ link, onClose, disableNewWindow }) {

    return (
        <Box sx={{
            position: 'absolute',
            right: 20,
            top: 20
        }}>
            {!disableNewWindow && !!link &&
                <Link href={link} target="_blank">
                    <Button endIcon={<InfosocIcon type='new-window' />}>Öppna i nytt fönster</Button>
                </Link>
            }
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    marginLeft: 2,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        </Box>
    )
}

export default DialogCloseExpand;