import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Sbt from 'src/components/Sbt';
import PreviewDialog from 'src/components/PreviewDialog';
import StructuredSearch from 'src/components/StructuredSearch';

function QuestionAnalysisPage({ menuOption }) {
	const [sbtId, setSbtId] = useState(-1);

	document.title = 'Frågor & analyser | Infosoc';

	const selectEntry = (entry) => {
		setSbtId(entry.id);
	};

	return (
		<Box mt={10}>
			<StructuredSearch
				offsetTop={120}
				ignoreSpecialEntries
				headers={['']}
				baseUrl='/lawreports/analysis/categories'
				onSelectResult={selectEntry}
				showResultFilter
				showAllCategoryOption
				showSubcategories={false}
				menuOption={menuOption}
			/>
			<PreviewDialog
				open={sbtId !== -1}
				onClose={() => setSbtId(-1)}
				title={''}
				link={`/rattsfall/${sbtId}`}>
				{sbtId !== -1 && (
					<Box mr={2} mt={4}>
						<Sbt id={sbtId} />
					</Box>
				)}
			</PreviewDialog>
		</Box>
	);
}

export default QuestionAnalysisPage;
