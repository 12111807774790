import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useParams } from "react-router-dom";
import Sbl from "src/components/Sbl";
import { get } from "src/services/http-service";

export default () => {

  let { id } = useParams();
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');

  document.title = "Skriv Ut | Infosoc";

  const load = async () => {

    const response = await get(`/lawdocuments/${id}`);
    setText(response.text);
    setTitle(response.title);

    document.title = response.title + " | Infosoc";

    setTimeout(() => {
      window.print();
    }, 200)
  }

  useEffect(() => {
    load();
  }, [id]);

  return (
    <Box sx={{ flexGrow: 1 }} m={0} >
      <Box ml={4} mt={2}>
        <Typography variant="h3" >{title}</Typography>
      </Box>
      <Typography id="lawDocumentContent" variant="body2" dangerouslySetInnerHTML={{ __html: text }} />

    </Box>
  );
}
