import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { post } from "src/services/http-service";
import storageService from "src/services/storage-service";
import { redirect, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@mui/material';
import TextInputDialog from "src/components/TextInputDialog";
import ChangePasswordDialog from 'src/components/ChangePasswordDialog';

function ForgotPasswordPage({ onSuccess, type }) {

  const navigate = useNavigate();
  let { token } = useParams();

  const [login, setLogin] = useState(null);

  document.title = 'Ändra lösenord | Infosoc';

  let title = 'Välkommen till Infosoc Rättsdata'
  let subTitle = 'För att börja använda databasen måste du ange ett lösenord';

  if(type==='forgotpassword'){
    title = 'Ange ett nytt lösenord för att återställa ditt konto';
    subTitle = '';
  }

  const load = async () => {
    const form = new FormData();
    form.append('token', token);
    const response = await post('/logintoken', form);
    storageService.save(response);
    setLogin(response);
  };

  const changeSuccess = () => {
    storageService.save(login);
    navigate("/");
    onSuccess();
  }

  useEffect(() => {
    load();
  }, [token]);

  return (
    <ChangePasswordDialog title={title} subTitle={subTitle} hideCancel={true} open={true} onClose={() => changeSuccess()} />
  );
}

ForgotPasswordPage.propTypes = {
  onSuccess: PropTypes.func,
  type: PropTypes.string,
};

export default ForgotPasswordPage;
