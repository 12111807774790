import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { post } from 'src/services/http-service';
import { validatePassword } from "src/services/password-service";

function ChangePasswordDialog({ open, onClose, title, subTitle, hideCancel }) {

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setPassword('');
      setPasswordRepeat('');
      setErrorMessage('');
    }
  }, [open]);

  const changePassword = async () => {

    const passwordMessage = validatePassword(password);

    if (passwordMessage) {
      setErrorMessage(passwordMessage);
      return;
    }

    if (password !== passwordRepeat) {
      setErrorMessage('Lösenorden matchar inte');
      return;
    }

    const form = new FormData();
    form.append('password', password);

    await post('/login/password/model', form, true);
    onClose();
  }

  return (
    <Dialog
      open={open}
      scroll="paper"
    >
      <DialogTitle id="scroll-dialog-title">{title || 'Ändra lösenord'}</DialogTitle>
      <DialogContent dividers>

        {subTitle && <Box mb={2}>
          <Typography variant="subtitle1">{subTitle}</Typography>
        </Box>}

        <Box mb={2}>
          <Typography variant="subtitle2" gutterBottom>
            Lösenordet måste vara minst 8 tecken långt och innehålla
            1 liten bokstav, 1 stor bokstav, och 1 siffra
          </Typography>
        </Box>

        {errorMessage && (
          <Box mb={4}>
            <Alert variant="filled" severity="error">
              {errorMessage}
            </Alert>
          </Box>
        )}

        <form noValidate>
          <TextField
            inputProps={{
              autocomplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            required
            fullWidth
            type='password'
            label='Nytt lösenord'
            autoFocus
          />


          <TextField
            value={passwordRepeat}
            onChange={(e) => setPasswordRepeat(e.target.value)}
            margin="normal"
            required
            fullWidth
            type='password'
            label='Bekräfta nytt lösenord'
          />

        </form>


      </DialogContent>
      <DialogActions>
        <Box m={2}>
          <LoadingButton loading={loading} variant="contained" onClick={() => changePassword()}>OK</LoadingButton>
          {!hideCancel &&
            <Button onClick={() => onClose()}>Avbryt</Button>
          }
        </Box>
      </DialogActions>
    </Dialog>
  )
}

ChangePasswordDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  hideCancel: PropTypes.bool,
};

export default ChangePasswordDialog;