

import React, { useState, useEffect } from 'react';
import { post } from "../services/http-service";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { DialogContent, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';

function EditSblWatchListEntryDialog({ open, onClose, watchListEntry }) {

  const [description, setDescription] = useState('');
  const [organization, setOrganization] = useState('');
  const [routine, setRoutine] = useState('');
  const [responsible, setResponsible] = useState('');
  const [emails, setEmails] = useState('');

  useEffect(() => {

    if (!watchListEntry) {
      return;
    }

    setDescription(watchListEntry.description || '');
    setOrganization(watchListEntry.organization || '');
    setRoutine(watchListEntry.routines || '');
    setResponsible(watchListEntry.responsible || '');
    setEmails(watchListEntry.emails || '');

  }, [open, watchListEntry])

  const onSubmit = async () => {
    const form = new FormData();
    form.append('description', description);
    form.append('organization', organization);
    form.append('routine', routine);
    form.append('responsible', responsible);
    form.append('emails', emails);

    await post(`/watchlist/${watchListEntry.id}`, form, true);

    onClose({
      ...watchListEntry,
      description,
      organization,
      routines: routine,
      responsible,
      emails
    });

    toast.success('Bevakning sparad');
  }

  return (
    <Dialog
      open={open}
      scroll="paper"
    >
      <DialogTitle id="scroll-dialog-title">
        <Typography variant="h6" >Redigera bevakning</Typography>
      </DialogTitle >
      <DialogContent dividers>

        <TextField
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          fullWidth
          margin="normal"
          label="Beskrivning"
          autoFocus
        />

        <TextField
          onChange={(e) => setOrganization(e.target.value)}
          value={organization}
          fullWidth
          margin="normal"
          label="Avdelning"
        />

        <TextField
          onChange={(e) => setResponsible(e.target.value)}
          value={responsible}
          fullWidth
          margin="normal"
          label="Ansvarig"
        />

        <TextField
          onChange={(e) => setRoutine(e.target.value)}
          value={routine}
          margin="normal"
          fullWidth
          multiline
          rows={4}
          label="Rutin"
        />
        <Box mt={4}>
          <Typography variant='subtitle2'>Upplys följande e-post mottagare om ändringar (en e-postadress per rad)</Typography>
        </Box>
        <TextField
          onChange={(e) => setEmails(e.target.value)}
          value={emails}
          margin="normal"
          fullWidth
          multiline
          rows={4}
          label=""
        />


      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onSubmit}>Spara</Button>
        <Button onClick={() => onClose()}>Avbryt</Button>
      </DialogActions>
    </Dialog >
  )

}

EditSblWatchListEntryDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  watchListEntry: PropTypes.object,
};

EditSblWatchListEntryDialog.defaultProps = {
  onClose: () => { },
  open: false,
};

export default EditSblWatchListEntryDialog;