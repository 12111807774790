import React, { useEffect, useState, useRef } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import Mark from 'mark.js';
import NextIcon from '@mui/icons-material/KeyboardArrowUp';
import PreviousIcon from '@mui/icons-material/KeyboardArrowDown';
import { ButtonGroup, FormControl, InputAdornment, InputLabel } from '@mui/material';
import PropTypes from 'prop-types';

function HighlightSearch({ anchor, autoFocus, autoScoll, onClose, props }) {
	const [search, setSearch] = useState('');
	const [markers, setMarkers] = useState([]);
	const [selectedMarker, setSelectedMarker] = useState(null);
	const markJS = new Mark(anchor.current);
	let inputRef = useRef(null);

	const handleSearch = (searchTerm) => {
		if (searchTerm.length >= 2) {
			setSearch(searchTerm);
		} else {
			setSearch('');
		}
	};

	const handleKeyPress = (e) => {
		// Input field is in focus
		if (e.target === inputRef.current.childNodes[0]) {
			// Enter was pressed
			if (e.keyCode === 13) {
				e.preventDefault();
				handleNext();
			}

			// Esc was pressed
			if (e.keyCode === 27) {
				e.preventDefault();
				setSearch('');
				onClose();
				inputRef.current.childNodes[0].blur();
			}
		}

		// Ctrl + F was pressed
		if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) {
			e.preventDefault();
			inputRef.current.childNodes[0].focus();
		}
	};

	const handleNext = () => {
		if (markers.length === 0) return;

		let newSelected = 0;
		if (selectedMarker >= 0 && selectedMarker < markers.length - 1) {
			newSelected = selectedMarker + 1;
		}

		setSelectedMarker(newSelected);
	};

	const handlePrevious = () => {
		if (markers.length === 0) return;

		let newSelected = markers.length - 1;
		if (selectedMarker > 0) {
			newSelected = selectedMarker - 1;
		}

		setSelectedMarker(newSelected);
	};

	useEffect(() => {
		let markerLen = markers.length;
		if (markerLen < 1) return;

		let beforeSelected = selectedMarker > 0 ? selectedMarker - 1 : markerLen - 1;
		let afterSelected = selectedMarker < markerLen - 1 ? selectedMarker + 1 : 0;

		markers[selectedMarker].classList.add('selected--highlight-search');
		markers[beforeSelected].classList.remove('selected--highlight-search');
		markers[afterSelected].classList.remove('selected--highlight-search');

		if (autoScoll) {
			markers[selectedMarker].scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'nearest',
			});
		}
	}, [markers, selectedMarker, autoScoll]);

	useEffect(() => {
		markJS.unmark();
		markJS.mark(search, {
			accuracy: 'partially',
			acrossElements: true,
			className: 'highlighted--highlight-search',
			wildcards: 'enabled',
			separateWordSearch: false,
			caseSensitive: false,
		});

		setMarkers(anchor.current.querySelectorAll('.highlighted--highlight-search'));
		setSelectedMarker(0);
	}, [search, anchor]);

	useEffect(() => {
		window.addEventListener('keydown', handleKeyPress);

		if (autoFocus) {
			// Focus the input field
			inputRef.current.childNodes[0].focus();
		}

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
			markJS.unmark();
		};
	}, []);

	if (!anchor) return false;

	return (
		<FormControl
			variant='outlined'
			size='small'
			color='primary'
			sx={{
				'& .MuiInputLabel-root': { color: '#0e90d2' }, //styles the label
				'& .MuiOutlinedInput-root': {
					'& > fieldset': { borderColor: '#0e90d280' }, //styles the outer <fieldset>
				},
				'& .MuiOutlinedInput-root:hover': {
					'& > fieldset': {
						borderColor: '#0e90d2', //styles the outer <fieldset>
					},
				},
				maxWidth: '320px',
			}}>
			<InputLabel htmlFor='highlight-search'>Sök</InputLabel>
			<OutlinedInput
				id='highlight-search'
				ref={inputRef}
				onChange={(e) => handleSearch(e.target.value)}
				onKeyDown={handleKeyPress}
				margin='none'
				label={'Sök'}
				color='primary'
				sx={{
					paddingRight: 0,
					minWidth: 260,
				}}
				endAdornment={
					// Add buttons to the end of the input
					<>
						{markers.length > 0 && (
							<InputAdornment position='end'>
								{`${selectedMarker + 1} / ${markers.length}`}
							</InputAdornment>
						)}
						<InputAdornment position='end'>
							<ButtonGroup
								variant='outlined'
								disableElevation
								orientation='horizontal'
								color='primary'
								sx={{ height: '40px', borderTop: 0, borderBottom: 0 }}>
								<Button onClick={handlePrevious} sx={{ borderRadius: '0' }} title='Föregående'>
									<NextIcon />
								</Button>
								<Button onClick={handleNext} title='Nästa'>
									<PreviousIcon />
								</Button>
							</ButtonGroup>
						</InputAdornment>
					</>
				}
				{...props}
			/>
		</FormControl>
	);
}

HighlightSearch.propTypes = {
	autoFocus: PropTypes.bool,
	autoScoll: PropTypes.bool,
	onClose: PropTypes.func,
};

HighlightSearch.defaultProps = {
	autoFocus: false,
	autoScoll: false,
	onClose: () => {},
};

export default HighlightSearch;
