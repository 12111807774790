import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FolderIcon from '@mui/icons-material/Folder';
import BookIcon from '@mui/icons-material/Book';
import CurrencyIcon from '@mui/icons-material/Euro';
import QuestionIcon from '@mui/icons-material/HelpOutline';
import GavelIcon from '@mui/icons-material/GavelRounded';
import AllIcon from '@mui/icons-material/ListOutlined';
import OrgIcon from '@mui/icons-material/People';
import ParagraphIcon from '@mui/icons-material/FormatAlignLeft';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import EmailIcon from '@mui/icons-material/Email';

import DraftsIcon from '@mui/icons-material/Drafts';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import FilterIcon from '@mui/icons-material/FilterAlt';
import Highlight from './Highlight';
import debounce from 'lodash.debounce';
import Skeleton from '@mui/material/Skeleton';

import { API_URL } from "src/services/http-service";


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

function CardView({ entries, onSelect, selectedIndex, showFilter, actionLabel, secondaryActionLabel, isEBook }) {

  const [filter, setFilter] = useState('');

  useEffect(() => {
    setFilter('');
  }, [entries])

  const updateFilterBounced = (newValue) => {
    setFilter(newValue);
  }

  const updateFilter = useCallback(
    debounce(updateFilterBounced, 500)
    , []);

  const lowerFilter = filter.toLocaleLowerCase();
  const filteredEntries = filter ? entries.filter(e => (e.name || e.title).toLocaleLowerCase().indexOf(lowerFilter) >= 0) : entries;

  const getText = (entry, index) => {

    let text = entry.name || entry.title;

    const maxLength = 85;
    if(text.length > maxLength){
      text = text.substring(0, maxLength)+'...';
    }

    const highlight = <Highlight text={text} query={lowerFilter} />;
    return selectedIndex === index ? <b>{highlight}</b> : highlight
  }

  return (
    <Box height="calc(100vh - 80px)" display="flex" flexDirection="column">
      {showFilter && <Box ml={2}>
        <TextField
          autoFocus
          onChange={(e) => updateFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FilterIcon />
              </InputAdornment>
            ),
          }}
          variant="standard" fullWidth label="Filtrera på namn..." />
      </Box>}
      <Box flex={1} overflow="auto" mt={4} ml={2} pb={6}>
        <Grid container direction="row" alignItems="center">
          {filteredEntries.map((entry, index) => (
            <Grid item xs={12} sm={6} lg={4} xl={3} spacing={8}>
              <Card sx={{ maxWidth: 300, margin: 1 }}>
                <CardMedia
                  component="img"
                  height={isEBook? '180' : '220'}
                  width="348"
                  style={{'filter': entry.access? '' :'grayscale(100%)'}}
                  image={isEBook? `${API_URL}/lawdocuments/${entry.id}/cover` : `/legalcomments/background_${(index % 2) + 1}.jpg`}
                />
                <CardContent>
                  <Box style={{ minHeight: 100 }}>
                    <Typography gutterBottom variant="subtitle1" component="div">
                      {getText(entry, index)}
                    </Typography>
                  </Box>
                </CardContent>
                {actionLabel &&
                  <CardActions>
                    <Button size="small" onClick={() => onSelect(index, entry)}>{actionLabel}</Button>
                    { secondaryActionLabel && <Button size="small" onClick={() => onSelect(index, entry, true)}>{secondaryActionLabel}</Button>}
                  </CardActions>
                }
              </Card>
            </Grid>
          ))}
        </Grid>                
      </Box>
    </Box>
  )
}

CardView.propTypes = {
  showFilter: PropTypes.bool,
  isEBook: PropTypes.bool,
  entries: PropTypes.array,
  selectedIndex: PropTypes.number,
  onSelect: PropTypes.func,
  actionLabel: PropTypes.string,
  secondaryActionLabel: PropTypes.string,
};

CardView.defaultProps = {
  selectedIndex: -1,
  showFilter: false,
  isEBook: false
};


export default CardView;