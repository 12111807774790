import React, { useState, useEffect, useMemo } from 'react';
import { get } from '../services/http-service';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import HeaderIcon from './HeaderIcon';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import storageService from 'src/services/storage-service';
import LocalStorageService from 'src/services/local-storage-service';
import SelectAllIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/Clear';
import OfferDialog from 'src/components/OfferDialog';
import Loading from './Loading';

function DatabaseDialog({ open, onClose }) {
	const [loading, setLoading] = useState(false);
	const [databases, setDatabases] = useState([]);
	const [otherDatabases, setOtherDatabases] = useState([]);
	const [offerDialogSelected, setOfferDialogSelected] = useState(null);
	const [allLawsSelected, setAllLawsSelected] = useState(true);
	const localStorageService = useMemo(() => new LocalStorageService(), []);

	const load = async () => {
		setLoading(true);
		let response = await get('/currentuser');
		setDatabases(response.databases);
		setOtherDatabases(response.otherDatabases);
		setLoading(false);
	};

	useEffect(() => {
		if (open) {
			load();
		}
	}, [open]);

	useEffect(() => {
		let allLawsSelected = localStorageService.get(localStorageService.ALL_LAWS_KEY());

		if (allLawsSelected != null && typeof allLawsSelected === 'boolean') {
			setAllLawsSelected(allLawsSelected);
		}
	}, [localStorageService]);

	const toggleDatabase = (index, checked) => {
		const updatedDatabases = [...databases];

		updatedDatabases[index].selected = checked;
		setDatabases(updatedDatabases);
	};

	const showUpgradeDialog = (event, dbName) => {
		event.preventDefault();
		setOfferDialogSelected(dbName);
	};

	const toggleAll = (selected) => {
		const updatedDatabases = databases.map((d) => ({ ...d, selected }));
		setDatabases(updatedDatabases);
	};

	const toggleAllLawsSelected = () => {
		setAllLawsSelected(!allLawsSelected);
	};

	const onSubmit = () => {
		const dbKey = databases
			.filter((d) => d.selected)
			.map((d) => d.key)
			.join('');
		storageService.save({ dbKey }, true);

		localStorageService.set(localStorageService.ALL_LAWS_KEY(), allLawsSelected);

		window.location.reload(false);
	};

	const selectedCount = databases.filter((d) => d.selected).length;
	const hasOtherDatabases = otherDatabases.length > 0;
	const disableSelectAll = selectedCount === databases.length;
	const disableClearAll = selectedCount === 0;

	return (
		<>
			<Dialog
				open={open}
				onClose={onClose}
				scroll='paper'
				aria-labelledby='scroll-dialog-title'
				aria-describedby='scroll-dialog-description'
				sx={{
					marginBottom: '24px',
				}}
			>
				{loading && (
					<Box m={4}>
						<Loading />
					</Box>
				)}

				{!loading && (
					<DialogTitle id='scroll-dialog-title'>
						<Box mt={0} ml={0} mr={0} mb={0}>
							<HeaderIcon
								text='Rättsområden'
								tooltip={
									<Box mt={0} ml={1}>
										Välj de rättsområden som ni önskar kunna se i listor samt i samband med sökningar.
									</Box>
								}
							/>
						</Box>
					</DialogTitle>
				)}

				{!loading && (
					<DialogContent dividers>
						<Box mb={3}>
							<Stack direction='row' spacing={2}>
								<Button
									disabled={disableSelectAll}
									onClick={() => toggleAll(true)}
									variant='outlined'
									startIcon={<SelectAllIcon />}
								>
									Välj alla
								</Button>
								<Button
									disabled={disableClearAll}
									onClick={() => toggleAll(false)}
									variant='outlined'
									startIcon={<ClearAllIcon />}
								>
									Rensa
								</Button>
							</Stack>
						</Box>

						{hasOtherDatabases && (
							<Typography variant='subtitle1' gutterBottom>
								<b>
									Du har tillgång till {databases.length}{' '}
									{databases.length === 1 ? 'rättsområde' : 'rättsområden'}
								</b>
							</Typography>
						)}

						<FormGroup>
							<Grid container justifyContent='space-between'>
								{databases.map((db, index) => (
									<Grid item xs={6} key={index}>
										<Box>
											<FormControlLabel
												control={
													<Checkbox
														checked={db.selected}
														onChange={(event) => toggleDatabase(index, event.target.checked)}
													/>
												}
												label={db.name}
											/>
										</Box>
									</Grid>
								))}
							</Grid>

							{hasOtherDatabases && (
								<Box mt={4}>
									<Typography variant='subtitle1' gutterBottom>
										<b>Få tillgång till ytterligare områden</b>
									</Typography>
									<Grid container justifyContent='space-between'>
										{otherDatabases.map((db, index) => (
											<Grid item xs={6} key={index}>
												<Box>
													<FormControlLabel
														control={
															<Checkbox
																checked={false}
																onChange={(event) => showUpgradeDialog(event, db.name)}
															/>
														}
														label={db.name}
													/>
												</Box>
											</Grid>
										))}
									</Grid>
								</Box>
							)}
						</FormGroup>

						<Box mt={3} ml={0} mr={2} mb={0}>
							<Typography variant='subtitle1' gutterBottom>
								<b>Ytterligare filter</b>
							</Typography>
							<FormGroup>
								<Grid container justifyContent='space-between'>
									<Grid item xs={4}>
										<Box>
											<FormControlLabel
												control={
													<Checkbox
														checked={allLawsSelected}
														onChange={(event) => toggleAllLawsSelected()}
													/>
												}
												label='Alla lagar'
											/>
										</Box>
									</Grid>
								</Grid>
							</FormGroup>
						</Box>
					</DialogContent>
				)}
				<DialogActions>
					<Button variant='contained' disabled={selectedCount === 0} onClick={onSubmit}>
						Spara
					</Button>
					<Button onClick={onClose}>Avbryt</Button>
				</DialogActions>
			</Dialog>
			<OfferDialog
				open={offerDialogSelected !== null}
				onClose={() => setOfferDialogSelected(null)}
				title='Rättsområden'
				selected={offerDialogSelected}
				items={otherDatabases.map((item) => item.name)}
			/>
		</>
	);
}

DatabaseDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSelectUnavailable: PropTypes.func,
};

export default DatabaseDialog;
