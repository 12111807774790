import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogClose from './DialogClose';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import ShareIcon from '@mui/icons-material/Share';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { post } from "src/services/http-service";
import storageService from 'src/services/storage-service';
import { toast } from 'react-toastify';

function ShareDialog({ open, onClose, templateSubject, templateText, attachments }) {
  const [loading, setLoading] = useState(false);
  const [sendTo, setSendTo] = useState('');
  const [sendFrom, setSendFrom] = useState('');
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const email = storageService.load().email || '';
  const isEmail = email.indexOf('@') > 0;
  
  useEffect(() => {
    setSubject(templateSubject);
    setText(templateText);
    setSendFrom(email && isEmail ? email : '');

  }, [templateSubject, templateText, email, isEmail]);

  const submit = async () => {

    setErrorMessage('');

    if (sendFrom.trim().length === 0) {
      return setErrorMessage('Skicka från');
    }
    if (sendTo.trim().length === 0) {
      return setErrorMessage('Skicka till');
    }
    if (subject.trim().length === 0) {
      return setErrorMessage('Ämne');
    }

    setLoading(true);

    var form = new FormData();
    form.append('subject', subject);
    form.append('from', sendFrom);
    form.append('to', sendTo);
    form.append('content', text);

    if (attachments && attachments.length > 0) {
      attachments.forEach((attachment, index) => {
        form.append('attachment', attachment.file, attachment.name);
      });

      await post('/email/attachment', form, true);
    } else {
      await post('/emails', form, true);
    }

    toast.success('Meddelande har skickats till ' + sendTo);

    onClose();
    setLoading(false);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Box mt={1} mr={1}>
              <ShareIcon color="primary" />
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="primary">Dela</Typography>
          </Grid>
        </Grid>

        <DialogClose onClose={onClose} />
      </DialogTitle>
      <DialogContent dividers>

        {errorMessage &&
          <Box mb={4}>
            <Alert variant="filled" severity="error">
              <strong>{errorMessage}</strong> är obligatorisk. Vänligen korrigera och försök igen.
            </Alert>
          </Box>
        }

        <TextField
          onChange={(e) => setSendFrom(e.target.value)}
          value={sendFrom}
          margin="normal"
          required
          fullWidth
          label="Skicka från"
          autoFocus={!isEmail}
          disabled={isEmail}
        />

        <TextField
          onChange={(e) => setSendTo(e.target.value)}
          value={sendTo}
          margin="normal"
          required
          fullWidth
          label="Skicka till"
          autoFocus={isEmail && email.length > 0}
        />

        <TextField
          onChange={(e) => setSubject(e.target.value)}
          value={subject}
          margin="normal"
          required
          fullWidth
          label="Ämne"
        />

        <TextField
          onChange={(e) => setText(e.target.value)}
          defaultValue={text}
          margin="normal"
          required
          fullWidth
          multiline
          rows={4}
          label="Text"
        />

        {attachments && attachments.length > 0 && (
          <Box mt={2}>
            <Typography variant="subtitle1">Bilagor:</Typography>
            <Grid container direction="row" alignItems="center">
              {attachments.map((attachment, index) => (
                <Grid item key={index}>
                  <AttachFileIcon color="" fontSize={'10px'} />
                  <Typography variant="text" color="">{attachment.name}</Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton variant="contained" loading={loading} onClick={() => submit()}>Skicka</LoadingButton>
        { !loading && <Button onClick={() => onClose()}>Avbryt</Button> }
      </DialogActions>
    </Dialog>
  )
}

ShareDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  templateSubject: PropTypes.string,
  templateText: PropTypes.string,
  attachments: PropTypes.array,
};

ShareDialog.defaultProps = {
  open: false,
  onClose: () => { },
  templateSubject: '',
  text: '',
  attachments: [],
};

export default ShareDialog;