import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

function ConfirmDialog({ open, onClose, onConfirm, title, text, saveButtonLabel, color }) {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>

        <Box mb={2}>
          <Typography variant="subtitle1" gutterBottom>
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color={color} onClick={() => handleConfirm()}>{saveButtonLabel}</Button>
        <Button onClick={() => onClose()}>Avbryt</Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  saveButtonLabel: PropTypes.string,
  color: PropTypes.string,
};

ConfirmDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onConfirm: () => {},
  title: '',
  text: '',
  saveButtonLabel: 'Spara',
  color: 'primary',
};

export default ConfirmDialog;