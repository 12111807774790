

export const validatePassword = (password) => {

  if (password.length < 8) {
    return 'Lösenordet måste vara minst 8 tecken';
  }
  if (password.search(/[a-zåäö]/) < 0) {
    return 'Lösenordet måste innehålla minst 1 liten bokstav';
  }
  if (password.search(/[A-ZÅÄÖ]/) < 0) {
    return 'Lösenordet måste innehålla minst 1 stor bokstav';
  }
  if (password.search(/[0-9]/) < 0) {
    return 'Lösenordet måste innehålla minst 1 siffra';
  }

  return null;
}
