import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function HoverableBox({ children, hoverable, minWidth, maxWidth, delay, onHover, ...props }) {
    const [hover, setHover] = useState(false);
    const [delayHandler, setDelayHandler] = useState(null);

    const hanndleMouseEnter = event => {
        setDelayHandler(setTimeout(() => {
            setHover(true);
            onHover(true);
        }, delay || 0));
    }

    const handleMouseLeave = () => {
        if (delayHandler) {
            clearTimeout(delayHandler);
        }

        setHover(false);
        onHover(false);
    }

    return (
        <Box
            onMouseLeave={handleMouseLeave}
            onMouseEnter={hanndleMouseEnter}
            minWidth={minWidth}
            maxWidth={
                (hoverable && !hover) ? minWidth : maxWidth
            }
            {...props}
        >
            {
                children
            }
        </Box>
    );
}

HoverableBox.propTypes = {
    hoverable: PropTypes.bool,
    delay: PropTypes.number,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    onHover: PropTypes.func
}

HoverableBox.defaultProps = {
    hoverable: false,
    delay: 0,
    minWidth: 0,
    maxWidth: 0,
    onHover: () => {}
}

export default HoverableBox;