import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Paper, Button } from '@mui/material';
import { get } from "src/services/http-service";
import DenseList from 'src/components/DenseList';
import Sbt from 'src/components/Sbt';
import Newsletter from 'src/components/Newsletter';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material';
import Loading from 'src/components/Loading';
import SubscribeNewsletterDialog from 'src/components/SubsribeNewsletterDialog';

function NewsletterListPage() {

  const [entries, setEntries] = useState([]);
  const [newsletter, setNewsletter] = useState(null);
  const [newsletters, setNewsletters] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedDatabase, setSelectedDatabase] = useState('');
  const [showSubscribeNewsletterDialog, setShowSubscribeNewsletterDialog] = useState(false);

  document.title = 'Nyhetsbrev | Infosoc';

  const load = async () => {
    const response = await get('/newsletters');
    setEntries(response.entries.slice(0, 200));

    const responseSingle = await get('/newsletters/period/' + response.entries[0].id);
    setNewsletter(responseSingle.newsletters[0]);
    setNewsletters(responseSingle.newsletters);
    setSelectedDatabase(responseSingle.newsletters[0].database);
    setSelectedIndex(0);
  }

  useEffect(() => {
    load();
  }, []);

  const selectNewsletter = async (index) => {
    setSelectedIndex(index);
    setNewsletter(null);
    const responseSingle = await get('/newsletters/period/' + entries[index].id);
    setNewsletter(responseSingle.newsletters.find(n => n.database === selectedDatabase) || responseSingle.newsletters[0]);
    setNewsletters(responseSingle.newsletters);
  }

  const changeDatabase = (value) => {
    setNewsletter(newsletters.find(n => n.database === value))
    setSelectedDatabase(value);
  }

  if (entries.length === 0) {
    return <Box mt={10} ml={2}><Loading /></Box>;
  }

  let title = newsletter ? newsletter.titleInContent.substr(newsletter.titleInContent.indexOf('vecka ')) : '';
  title = title.substr(0, title.indexOf(', '));

  return (
    <Box height="calc(100vh - 250px)" display="flex" flexDirection="column" mt={6}>
      <Stack direction="row" height="calc(100vh - 80px)">
        <Box mt={4} maxWidth={250} minWidth={250}>
          <DenseList offsetTop={120} entries={entries} onSelect={(index, entry) => selectNewsletter(index)} selectedIndex={selectedIndex} />
        </Box>
        {newsletter &&

          <Box flex={1} overflow="auto" mt={5} maxWidth={1000} pl={2} pr={2} >

            <Paper elevation={5} style={{ borderTop: '1px solid #eee', borderRadius: 10 }}>
              <Box ml={4} pt={4} mb={4}>

                <Stack direction="row">
                  <FormControl size="small" margin='dense'>
                    <InputLabel id="demo-select-small">Databas</InputLabel>
                    <Select
                      value={selectedDatabase}
                      label="Databas"
                      onChange={(event) => changeDatabase(event.target.value)}
                    >
                      {newsletters.map((newsletter, index) =>
                        <MenuItem key={index} value={newsletter.database}>{newsletter.databaseName}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <Box ml={2} mt={1}>
                    <Typography variant="h5">{title}</Typography>
                  </Box>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>

                  </Typography>
                  <Box ml={4} mr={6}>
                    <Typography variant="body2">Visste du att våra populära nyhetsbrev ingår kostnadsfritt för dig som kund?
                    </Typography>
                    <Stack direction="row">
                      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
                      <Box mt={2}><Button disableElevation variant="contained" onClick={() => setShowSubscribeNewsletterDialog(true)}>Prenumerera nu!</Button></Box>
                    </Stack>
                  </Box>
                </Stack>
              </Box>

              <Newsletter showTitle={false} newsletter={newsletter} />

            </Paper>

          </Box>
        }

      </Stack >
      <SubscribeNewsletterDialog open={showSubscribeNewsletterDialog} onClose={() => setShowSubscribeNewsletterDialog(false)} />
    </Box >

  );
}


export default NewsletterListPage;