import React, { useState, useEffect } from 'react';
import { get, post } from "../services/http-service";
import { Button, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Typography, Stack, Link, TextField, } from '@mui/material/';
import InfosocIcon from './InfosocIcon';
import Reorder from 'react-reorder';
import { reorder } from 'react-reorder';
import Loading from './Loading';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider } from '@mui/material';

function Investigation({ id, onLoaded, isReOrdering }) {

  const [loading, setLoading] = useState(true);
  const [entries, setEntries] = useState([]);
  const [editEntryId, setEditEntryId] = useState(-1);

  const [editTitle, setEditTitle] = useState('');
  const [editText, setEditText] = useState('');

  const load = async () => {
    let response = await get(`/investigations/${id}`);
    setEntries(response.entries);
    onLoaded(response);
    setLoading(false);
  }

  useEffect(() => {
    if (id > 0) {
      load();
    }
  }, [id]);

  const deleteEntry = async (entry) => {
    await post(`/investigations/entries/${entry.id}/delete`, {}, true);
    setEntries(entries.filter(e => e.id !== entry.id));
  }

  const editEntry = (entry) => {
    setEditEntryId(entry.id);
    setEditTitle(entry.title);
    setEditText(entry.text);
  }

  const saveEntry = async () => {

    const textForm = new FormData();
    textForm.append('id', `investigationentry_${editEntryId}_text`);
    textForm.append('value', editText.trimStart());

    await post('/investigations/text', textForm, true);

    const titleForm = new FormData();
    titleForm.append('id', `investigationentry_${editEntryId}_title`);
    titleForm.append('value', editTitle.trimStart());

    await post('/investigations/title', titleForm, true);

    setEditEntryId(-1);

    load();
  }

  const addEntry = async () => {
    const form = new FormData();
    form.append('name', 'Min titel');
    form.append('linkType', 'text');
    form.append('text', 'Min textruta');

    const response = await post(`/investigations/${id}/entries`, form)

    setEntries([response].concat(entries));
    setEditEntryId(response.id);
    setEditTitle(response.title);
    setEditText(response.text);
  }

  const onReorder = async (event, previousIndex, nextIndex, fromId, toId) => {
    const newOrder = reorder(entries, previousIndex, nextIndex);
    setEntries(newOrder);
    const form = new FormData();
    newOrder.forEach(e => form.append('positions[]', e.id));
    await post(`/investigations/${id}/order`, form, true);
  }

  if (loading) {
    return <Loading />;
  }

  const liClassName = isReOrdering ? 'investigation-entry-can-drag' : 'investigation-entry';
  const currentEditId = isReOrdering ? -1 : editEntryId;

  return (
    <Box ml={1}>
      {!isReOrdering && <Box ml={2}>
        <Divider />
        <Box mt={2}>
        </Box>

        <Button onClick={() => addEntry()} variant="outlined" disabled={currentEditId > 0} startIcon={<InfosocIcon type="add" />}>Lägg in egen textruta</Button>
        <Box mt={2}>
          <Divider />
        </Box>
      </Box>}

      <Reorder
        reorderId="investigation-entries-list"
        reorderGroup="reorder-group"
        component="ul"
        draggedClassName="investigation-entry-dragging"
        lock="horizontal"
        onReorder={onReorder}
        autoScroll={true}
        disabled={!isReOrdering}
        disableContextMenus={true}
        className="investigation-entry-list"
      >
        {
          entries.map((entry) => (
            <li key={entry.id} className={liClassName}>
              {currentEditId === entry.id && <Box m={2}>
                <TextField value={editTitle} fullWidth margin="normal" onChange={(e) => setEditTitle(e.target.value)}></TextField>
                <TextField rows={5} multiline fullWidth value={editText} onChange={(e) => setEditText(e.target.value)}></TextField>
                <Box mt={1}>
                  <Stack direction="row" spacing={1}>
                    <Typography sx={{ flexGrow: 1 }}></Typography>
                    <Button startIcon={<InfosocIcon type='checkmark' />} variant="outlined" onClick={() => saveEntry(entry)}>Spara</Button>
                    <Button variant="outlined" onClick={() => setEditEntryId(-1)}>Avbryt</Button>
                  </Stack>
                </Box>
              </Box>}
              {currentEditId !== entry.id &&
                <Box m={2}>
                  <Typography variant='h6'>{entry.title}</Typography>
                  <Typography dangerouslySetInnerHTML={{ __html: entry.text }}></Typography>
                </Box>
              }
              {!isReOrdering && currentEditId !== entry.id && <Box ml={2}>
                <Stack direction="row" spacing={1}>
                  {entry.hasSource &&
                    <Link target="_blank" href={entry.link}>
                      <Button startIcon={<InfosocIcon type='link' />} >Gå till källa</Button>
                    </Link>
                  }
                  <Typography sx={{ flexGrow: 1 }}></Typography>
                  {currentEditId < 0 &&
                    <>
                      <IconButton onClick={() => deleteEntry(entry)}>
                        <DeleteIcon />
                      </IconButton>
                      <Button startIcon={<InfosocIcon type='edit' />} variant="outlined" onClick={() => editEntry(entry)}>Redigera</Button>
                    </>
                  }
                </Stack>
              </Box>}
              <Box mt={2}>
                <Divider />
              </Box>
            </li>
          ))
        }
      </Reorder>

    </Box>
  )

}

Investigation.propTypes = {
  id: PropTypes.number,
  onLoaded: PropTypes.func,
};

Investigation.defaultProps = {
  id: -1,
  onLoaded: () => { },
};

export default Investigation;