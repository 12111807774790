import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Box,
	Chip,
	Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import InfosocIcon from './InfosocIcon';
import DialogClose from './DialogClose';
import OfferDialog from './OfferDialog';

import { get } from '../services/http-service';

function FeaturesDialog({ open, onClose }) {
	const [offerDialogSelected, setOfferDialogSelected] = useState(null);
	const [currentUser, setCurrentUser] = useState(null);
	const [ebooks, setEbooks] = useState([]);

	const FeatureItem = ({
		label,
		description,
		clickable,
		enabled,
		icon,
		...props
	}) => {
		const cursorStyling = {
			cursor: !enabled || clickable ? 'pointer' : 'default',
		};

		return (
			<ListItem
				title={
					enabled ? 'Aktiv' : 'Klicka för att beställa denna tjänst.'
				}
				onClick={() => {
					if (!enabled || clickable) {
						setOfferDialogSelected(label);
					}
				}}
				sx={cursorStyling}
				{...props}
			>
				<ListItemIcon sx={{ minWidth: 44 }}>
					<InfosocIcon type={icon} />
				</ListItemIcon>
				<ListItemText
					primary={label}
					secondary={description}
					style={{ marginRight: 8 }}
				/>
				<ListItemIcon>
					<Chip
						variant="outlined"
						label={
							<Typography
								variant="body2"
								width={40}
								textAlign={'center'}
							>
								{enabled ? 'Aktiv' : 'Inaktiv'}
							</Typography>
						}
						icon={enabled ? <CheckCircleIcon /> : <CancelIcon />}
						color={enabled ? 'success' : 'default'}
						sx={cursorStyling}
					/>
				</ListItemIcon>
			</ListItem>
		);
	};

	useState(() => {
		const fetchData = async () => {
			const user = await get('/currentuser');
			if (user) {
				setCurrentUser(user);

				const allAvailableDatabases = `${user.databases
					.map((db) => db.key)
					.join(',')},${user.otherDatabases
					.map((db) => db.key)
					.join(',')}`;
				const data = await get('/lawdocuments/9/categories', {
					dbKey: allAvailableDatabases,
				});

				if (data && data.entries) {
					setEbooks(data.entries);
				}
			}
		};

		fetchData();
	}, []);

	if (!currentUser) {
		return null;
	}

	const ebookItems = ebooks
		.filter((document) => !document.access)
		.map((document) => document.title);

	const FeatureItems = [
		{
			label: 'E-litteratur',
			description: `${ebooks.length - ebookItems.length} av ${
				ebooks.length
			} tillgängliga dokument.`,
			enabled: ebooks.length - ebookItems.length > 0,
			clickable: ebookItems.length > 0,
			icon: 'ebook',
		},
		{
			label: 'Lagkommentarer',
			description: 'Kurerat lagmaterial från våra experter.',
			enabled: currentUser.featureLegalComments,
			icon: 'legalcomment',
		},
		{
			label: 'Rättsfall Plus',
			description: 'Utökat sortiment med rättsfall.',
			enabled: currentUser.featureSbtPlus,
			icon: 'sbt_plus',
		},
	];

	const offerDialogItems = FeatureItems.filter((f) => !f.enabled).map(
		(f) => f.label
	);

	return (
		<>
			<Dialog open={open} onClose={onClose} scroll="paper" maxWidth="sm">
				<DialogTitle id="scroll-dialog-title">
					Plustjänster
					<DialogClose onClose={() => onClose()} />
				</DialogTitle>
				<DialogContent dividers>
					<Typography variant="body1" gutterBottom pr={2} pl={2}>
						Här kan du se vilka plustjänster som är aktiverade för
						ditt konto.
					</Typography>
					<Box m={2}>
						<List>
							{FeatureItems.map((feature, index) => (
								<FeatureItem
									key={index}
									label={feature.label}
									enabled={feature.enabled}
									icon={feature.icon}
									description={feature.description}
									clickable={feature.clickable}
								/>
							))}
						</List>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button type="outlined" onClick={onClose}>
						Stäng
					</Button>
				</DialogActions>
			</Dialog>
			<OfferDialog
				open={offerDialogSelected !== null}
				onClose={() => setOfferDialogSelected(null)}
				title={
					offerDialogItems.length > 0
						? 'Plustjänster'
						: 'E-litteratur'
				}
				items={offerDialogItems}
			/>
		</>
	);
}

FeaturesDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default FeaturesDialog;
