import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ReactPlayer from 'react-player';


function VideoFrame({ src, title, autoPlay, maxWidth, props }) {
	return (
		<Box
			maxWidth={maxWidth}
			className='videoFrame'
			{...props}
		>
			<ReactPlayer 
				url={src}
				width='100%'
				height='100%'
				title={title}
				playing={autoPlay}
				controls
			/>
		</Box>
		
	);
}

VideoFrame.propTypes = {
	src: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	autoPlay: PropTypes.bool,
	maxWidth: PropTypes.number,
}

VideoFrame.defaultProps = {
	autoPlay: false,
	maxWidth: 1200,
}

export default VideoFrame;