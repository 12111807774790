import { alertClasses } from "@mui/material";


const DEFAULT_USER = {

}

const KEY = 'login.databas.infosoc.se';
const KEY_DB = 'db.databas.infosoc.se';

const areAllowedDatabase = (databases, allDatabases) => {

    if (!allDatabases) {
        return true;
    }

    for (let dbIndex = 0; dbIndex < databases.length; dbIndex++) {
        if (allDatabases.indexOf(databases[dbIndex]) < 0) {
            return false;
        }
    }

    return true;
}

export default {

    isLoggedIn: () => {
        const login = localStorage.getItem(KEY);
        return !!login && login.indexOf('accessKey') >= 0;
    },

    load: () => {
        const login = localStorage.getItem(KEY);
        const dbKey = localStorage.getItem(KEY_DB);

        if (login) {

            const parsedLogin = JSON.parse(login);

            if (dbKey && areAllowedDatabase(dbKey, parsedLogin.dbKey)) {
                parsedLogin.dbKey = dbKey;
            }

            return parsedLogin;
        }

        return DEFAULT_USER;
    },

    save: (updatedLogin, saveDatebaseSelection) => {
        const existing = localStorage.getItem(KEY);
        const login = existing ? JSON.parse(localStorage.getItem(KEY)) : {};

        const loginToSave = { ...login, ...updatedLogin };
        localStorage.setItem(KEY, JSON.stringify(loginToSave));

        if (saveDatebaseSelection) {
            localStorage.setItem(KEY_DB, loginToSave.dbKey);
        }
    },

    clear: () => {
        localStorage.setItem(KEY, JSON.stringify(DEFAULT_USER));
    }
}