import React, { useEffect, useState } from 'react';
import {
	Stack,
	Box,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	TextField,
	Checkbox,
	Typography,
	FormControlLabel,
	Button,
	Chip,
	Grid,
	Tooltip,
} from '@mui/material';
import { get } from 'src/services/http-service';
import Sbt from 'src/components/Sbt';
import Newsletter from 'src/components/Newsletter';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import Loading from 'src/components/Loading';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import InfosocIcon from 'src/components/InfosocIcon';
import PreviewDialog from 'src/components/PreviewDialog';
import SblDialog from 'src/components/SblDialog';
import Link from '@mui/material/Link';
import SbtIcon from '@mui/icons-material/GavelRounded';
import SblIcon from '@mui/icons-material/Book';
import LegalCommentsIcon from '@mui/icons-material/TextsmsOutlined';
import QuestionIcon from '@mui/icons-material/LiveHelpOutlined';
import NewsletterIcon from '@mui/icons-material/EmailOutlined';
import FormIcon from '@mui/icons-material/Assignment';
import InformationMaterialIcon from '@mui/icons-material/MenuBook';
import { DocumentType } from 'src/constants/DocumentType';

const WORD_MATCH_LOOKUP = {
	ett: 'one',
	alla: 'all',
	exakt: 'exact',
};

const ENDPOINT = '/search';

function SearchResultPage() {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [loadingFilter, setLoadingFilter] = useState(false);
	const [loadingPage, setLoadingPage] = useState(false);

	const [newsletter, setNewsletter] = useState(null);

	const [sbtId, setSbtId] = useState(-1);
	const [sblId, setSblId] = useState(-1);

	const [entries, setEntries] = useState([]);

	const [sources, setSources] = useState([]);

	const [selectedId, setSelectedId] = useState(-1);

	const [search] = useSearchParams();

	const [summaryEntries, setSummaryEntries] = useState([]);

	const [hasMoreEntries, setHasMoreEntries] = useState(false);
	const [start, setStart] = useState(1);

	const query = search.get('q');
	const days = search.get('dagar') || '-1';
	const matchAllWords = WORD_MATCH_LOOKUP[search.get('innehaller') || 'ett'];
	const excludeWords = search.get('excludera') || '';
	const sortBy = search.get('sortera') === 'datum' ? 'date' : 'relevance';
	const sid = search.get('sid') || '';
	const exactMatch = ['exact', 'exakt'].includes(matchAllWords);

	document.title = 'Sökresultat | Infosoc';

	const toggleSummary = (index) => {
		const copied = [...summaryEntries];
		copied[index].selected = !copied[index].selected;
		setSummaryEntries(copied);

		toggleSourcesByType(copied[index].id);

		filterTypes(copied, null, sources);
	};

	const filterTypes = async (types, start, sources) => {
		if (start) {
			setLoadingPage(true);
		} else {
			setLoadingFilter(true);
		}
		const typesQuery = types
			.filter((t) => t.selected)
			.map((t) => `type=${t.id}`)
			.join('&');
		const sourcesQuery = sources
			? sources
					.filter((s) => s.selected && s.type === DocumentType.SBT)
					.map((s) => `&sources=${s.name}`)
					.join('&')
			: '';
		const plusSourcesQuery = sources
			? sources
					.filter(
						(s) => s.selected && s.type === DocumentType.SBT_PLUS
					)
					.map((s) => `&sourcesPlus=${s.name}`)
					.join('&')
			: '';

		const response = await get(
			`${ENDPOINT}?q=${query}&${
				start ? `&start=${start}&` : ''
			}${typesQuery}${sourcesQuery}${plusSourcesQuery}&exactMatch=${exactMatch}&days=${days}&matchAllWords=${matchAllWords}&excludeWords=${excludeWords}&sortBy=${sortBy}&newSearch=false`
		);

		if (response.summary.sources && !sources) {
			setSources(response.summary.sources);
		}

		if (start) {
			setEntries(entries.concat(response.entries));
			if (!sources) {
				setSources([]);
			}
		} else {
			setEntries(response.entries);
			setStart(1);
		}

		setHasMoreEntries(response.hasMoreEntries);

		setLoadingPage(false);
		setLoadingFilter(false);
	};

	const nextPage = () => {
		const nextStart = start + 20;
		setStart(nextStart);
		filterTypes(summaryEntries, nextStart, sources);
	};

	const getTooltipMessage = (entry, m) => {
		const isInComment =
			entry.hasOwnProperty('link') &&
			entry.link &&
			entry.link.indexOf('rattsfall') >= 0;

		const message = `Ordet ${m.word} förekommer`;

		if (m.onlyFullText) {
			return `${message} endast i fulltexten`;
		}
		if (m.onlyAttachmentText) {
			return `${message} endast träff i bilagan`;
		}

		return `${message} i ${isInComment ? 'kommentaren' : 'texten'}`;
	};

	const getIconType = (m) => {
		if (m.onlyFullText) {
			return 'fulltext';
		}
		if (m.onlyAttachmentText) {
			return 'attachment';
		}

		return 'checkmark';
	};

	const selectEntry = async (entry) => {
		setSelectedId(entry.id);

		if (entry.type === 'form') {
			setSblId(entry.id);
		} else {
			const tokens = entry.link.split('/');
			if (tokens[0] === 'rattsfall') {
				setSbtId(tokens[1]);
			} else if (tokens[0] === 'lag') {
				setSblId(tokens[1]);
			} else if (tokens[0] === 'nyhetsbrev') {
				const response = await get(
					`/newsletter/${entry.id}?q=${query}`
				);
				setNewsletter(response);
			}
		}
	};

	const getIcon = (type, disabled) => {
		const props = { color: disabled ? 'disabled' : '' };
		if (type === 'sbt') return <SbtIcon {...props} />;
		if (type === 'sbt_plus')
			return (
				<InfosocIcon
					type={type}
					color={disabled ? '#00000042' : '#000'}
				/>
			);
		if (type === 'sbl') return <SblIcon color="primary" {...props} />;
		if (type === 'legalcomment')
			return <LegalCommentsIcon color="primary" {...props} />;
		if (type === 'analysis')
			return <QuestionIcon color="primary" {...props} />;
		if (type === 'prop') return <SbtIcon color="primary" {...props} />;
		if (type === 'newsletter')
			return <NewsletterIcon color="primary" {...props} />;
		if (type === 'form') return <FormIcon color="primary" {...props} />;
		if (type === 'info')
			return <InformationMaterialIcon color="primary" {...props} />;
	};

	useEffect(() => {
		const load = async () => {
			setLoading(true);
			const response = await get(
				`${ENDPOINT}?q=${query}&exactMatch=${exactMatch}&days=${days}&matchAllWords=${matchAllWords}&excludeWords=${excludeWords}&newSearch=true&sortBy=${sortBy}`
			);
			setSummaryEntries(response.summary.entries);
			setEntries(response.entries);
			setHasMoreEntries(response.hasMoreEntries);
			setSources(response.summary.sources);

			setLoading(false);
		};

		load();
	}, [query, days, matchAllWords, excludeWords, sortBy, sid, exactMatch]);

	const getSummaryName = (summary) => {
		return (
			<Grid container direction="row" alignItems="center">
				<Grid item>
					<Box mt={1} mr={1}>
						{getIcon(summary.id, summary.count === 0)}
					</Box>
				</Grid>
				<Grid item>
					<Typography variant="body">
						{summary.count
							? `${summary.name} (${summary.count})`
							: summary.name}
					</Typography>
				</Grid>
			</Grid>
		);
	};

	if (loading) {
		let loadingMessage = `Söker efter ${query}`;
		return (
			<Box mt={13} ml={4}>
				<Loading message={loadingMessage} />
				<TextField
					autoFocus
					style={{ position: 'fixed', left: -500 }}
				/>{' '}
			</Box>
		);
	}

	const toggleSource = (index, type) => {
		const sourcesCopy = [...sources];
		sourcesCopy[index].selected = !sourcesCopy[index].selected;
		setSources(sourcesCopy);

		filterTypes(summaryEntries, null, sourcesCopy);
	};

	const toggleSourcesByType = (type) => {
		const sourcesCopy = [...sources];

		for (const s of sourcesCopy) {
			if (s.type === type) {
				s.selected = false;
			}
		}

		setSources(sourcesCopy);

		filterTypes(summaryEntries, null, sourcesCopy);
	};

	const openSearchGuide = () => {
		document.dispatchEvent(new CustomEvent('search_guide_open_event'));
	};

	return (
		<Box mt={10} display="flex" bgcolor={'#fafafa'}>
			<Box
				pl={2}
				pr={2}
				mt={4}
				overflow="auto"
				minWidth={320}
				maxWidth={500}
				maxHeight={'85dvh'}
			>
				<Accordion defaultExpanded={true} expanded>
					<AccordionSummary
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography variant="h6">Träffar</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Stack>
							{summaryEntries.map((summary, index) => (
								<>
									<FormControlLabel
										key={index}
										control={
											<Checkbox
												disabled={summary.count === 0}
												checked={summary.selected}
												onChange={() =>
													toggleSummary(index)
												}
											/>
										}
										label={getSummaryName(summary)}
									/>

									{summary.selected && (
										<Box ml={2} mb={0}>
											<Stack>
												{sources.length > 0 &&
													sources.map(
														(source, index) => {
															if (
																source.type ===
																summary.id
															) {
																return (
																	<FormControlLabel
																		key={
																			index
																		}
																		control={
																			<Checkbox
																				size="small"
																				checked={
																					source.selected
																				}
																				onChange={(
																					event
																				) =>
																					toggleSource(
																						index,
																						source
																					)
																				}
																				disableRipple
																			/>
																		}
																		label={`${source.name} (${source.count})`}
																	/>
																);
															}
															return null;
														}
													)}
											</Stack>
										</Box>
									)}
								</>
							))}
						</Stack>
					</AccordionDetails>
				</Accordion>
				<div height="10px"></div>
			</Box>
			<Box flex={1} pt={2} mt={2} mb={4} overflow="auto">
				{loadingFilter && (
					<Box mt={2} ml={4}>
						<Loading />
					</Box>
				)}
				{!loadingFilter && (
					<Box
						height={`calc(100vh - 152px)`}
						display="flex"
						flexDirection="column"
					>
						<Box flex={1} id="search-result">
							{entries.length === 0 && (
								<Box
									pt={2}
									pb={4}
									pl={4}
									sx={{ bgcolor: 'background.paper' }}
								>
									<Typography variant="h6">
										Inga träffar på <b>{query}</b>
									</Typography>
									<Typography variant="body1" mt={2}>
										<i>Förslag:</i>
										<ul>
											<li>
												Kontrollera att alla sökord är
												rättstavade
											</li>
											<li>Försök med andra sökord</li>
											<li>
												Testa att söka med{' '}
												<i>
													"Innehåller minst ett ord"
												</i>
											</li>
											<li>
												Se vår{' '}
												<Link
													onClick={openSearchGuide}
													style={{
														cursor: 'pointer',
													}}
												>
													sökguide
												</Link>{' '}
												för tips hur man kan söka
											</li>
										</ul>
									</Typography>
								</Box>
							)}

							{entries.length > 0 && (
								<List
									sx={{
										width: '100%',
										bgcolor: 'background.paper',
									}}
								>
									{entries.map((entry, index) => (
										<>
											<ListItemButton
												onClick={() =>
													selectEntry(entry)
												}
												m={0}
												selected={
													selectedId === entry.id
												}
												autoFocus={
													selectedId === entry.id
												}
												key={index}
											>
												<ListItem alignItems="flex-start">
													<ListItemAvatar>
														<InfosocIcon
															type={entry.icon}
														/>
													</ListItemAvatar>
													<ListItemText
														primary={
															selectedId ===
															entry.id ? (
																<b>
																	{
																		entry.title
																	}
																</b>
															) : (
																entry.title
															)
														}
														secondary={
															<Box>
																<span
																	dangerouslySetInnerHTML={{
																		__html: entry.text,
																	}}
																/>
																<Box mt={1}>
																	<Stack
																		direction="row"
																		spacing={
																			1
																		}
																	>
																		{entry.matched.map(
																			(
																				m,
																				index
																			) => (
																				<Tooltip
																					arrow
																					title={getTooltipMessage(
																						entry,
																						m
																					)}
																					placement="right-start"
																					key={
																						index
																					}
																				>
																					<Chip
																						icon={
																							<InfosocIcon
																								type={getIconType(
																									m
																								)}
																								style={{
																									paddingLeft: 4,
																								}}
																							/>
																						}
																						label={
																							m.word
																						}
																						variant="outlined"
																					/>
																				</Tooltip>
																			)
																		)}
																		{entry.notMatched.map(
																			(
																				m,
																				index
																			) => (
																				<Chip
																					key={
																						index
																					}
																					icon={
																						<InfosocIcon
																							type="hits-no-match"
																							style={{
																								paddingLeft: 4,
																							}}
																						/>
																					}
																					label={
																						m.word
																					}
																					variant="outlined"
																				/>
																			)
																		)}
																	</Stack>
																	<Box mt={2}>
																		<Stack
																			direction="row"
																			spacing={
																				1
																			}
																		></Stack>
																	</Box>
																</Box>
															</Box>
														}
													/>
												</ListItem>
											</ListItemButton>

											{index < entries.length - 1 && (
												<Divider />
											)}
										</>
									))}
								</List>
							)}
							{loadingPage && (
								<Box m={2}>
									<Loading />
								</Box>
							)}
							{hasMoreEntries && !loadingPage && (
								<Box
									pb={4}
									pl={2}
									pr={2}
									bgcolor={'background.paper'}
								>
									<Button
										fullWidth
										variant="outlined"
										startIcon={
											<InfosocIcon type="arrowdown" />
										}
										onClick={() => nextPage()}
									>
										Visa fler
									</Button>
								</Box>
							)}
						</Box>
					</Box>
				)}
			</Box>

			<PreviewDialog
				open={sbtId !== -1}
				onClose={() => setSbtId(-1)}
				link={`/rattsfall/${sbtId}`}
			>
				{sbtId !== -1 && (
					<Box mr={4}>
						<Sbt
							id={sbtId}
							query={query}
							matchPhrase={exactMatch}
						/>
					</Box>
				)}
			</PreviewDialog>

			<PreviewDialog
				open={newsletter}
				onClose={() => setNewsletter(null)}
				title={''}
				link={`/nyhetsbrev/${newsletter ? newsletter.id : -1}`}
			>
				{newsletter && (
					<Box mr={4}>
						<Newsletter newsletter={newsletter} />
					</Box>
				)}
			</PreviewDialog>

			<SblDialog
				open={sblId !== -1}
				onClose={() => setSblId(-1)}
				id={Number(sblId)}
				query={query}
			/>
		</Box>
	);
}

export default SearchResultPage;
