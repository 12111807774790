import React, { useState, useEffect } from 'react';
import { get, post } from "../services/http-service";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import WatchlistIcon from '@mui/icons-material/Notifications';
import { Divider } from '@mui/material';
import DialogClose from './DialogClose';
import { toast } from 'react-toastify';

function WatchSbtDialog({ id, onClose }) {

  const [entries, setEntries] = useState([]);
  const [sendEmail, setSendEmail] = useState(false);

  const load = async () => {

    let response = await get(`/watchlist/sbt/categories?sbtDocumentId=${id}`);
    response.entries.forEach(e => {
      e.selected = e.disabled;
    });
    setEntries(response.entries);
  }

  useEffect(() => {
    if (id > 0) {
      load();
    }
  }, [id]);

  const toggleCategory = (index, checked) => {
    const updatedEntries = [...entries];

    updatedEntries[index].selected = checked;
    setEntries(updatedEntries);
  }

  const onSubmit = async () => {
    const form = new FormData();
    const selected = entries.filter(e => e.selected && !e.disabled);
    form.append('sbtCategoryIds', selected.map(e => e.id).join(','));
    form.append('sendEmail', sendEmail);

    await post('/watchlist/sbt/category', form, true);

    toast.success("Du bevakar nu: " + selected.map(e => e.name).join(','));
    onClose(true);
  }

  const selectedCount = entries.filter(e => e.selected && !e.disabled).length;

  return (
    <Dialog
      onClose={onClose}
      open={id > 0}
      scroll="paper"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Box mt={1} mr={1}>
              <WatchlistIcon color="secondary" />
            </Box>
          </Grid>
          <Grid item color="secondary">
            <Typography variant="h6" color="secondary">Bevaka</Typography>
          </Grid>
        </Grid>
        <DialogClose onClose={onClose} />
      </DialogTitle>
      <DialogContent dividers>

        <Typography variant="subtitle1" gutterBottom>
          Rättsfallet är inlagt på följande tema 1
          Välj vilka teman du vill bevaka framöver (max 3) för information om nya rättsfall.
        </Typography>

        <FormGroup>

          {entries.map((entry, index) => (
            <Box key={index}>
              <FormControlLabel control={<Checkbox disabled={entry.disabled} checked={entry.selected} onChange={(event) => toggleCategory(index, event.target.checked)} />} label={entry.name + (entry.disabled? ' (bevakas redan)' : '')} />
            </Box>
          ))}

          <Box mt={4} p={2} style={{ background: '#eee', borderRadius: 5 }}>
            <FormControlLabel control={<Checkbox checked={sendEmail} onChange={(event) => setSendEmail(event.target.checked)} />} label="Bevaka via e-post" />
          </Box>

        </FormGroup>
      </DialogContent>
      <DialogActions>

        <Button variant="contained" disabled={selectedCount === 0} onClick={onSubmit}>Spara</Button>
        <Button onClick={() => onClose()}>Avbryt</Button>
      </DialogActions>
    </Dialog>
  )

}

WatchSbtDialog.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.number,
};

WatchSbtDialog.defaultProps = {
  onClose: () => { },
  id: -1,
};

export default WatchSbtDialog;