import React, { useState, useEffect } from 'react';
import { get, post } from "../services/http-service";
import Button from '@mui/material/Button';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { Typography, Stack, Link } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import InfosocIcon from './InfosocIcon';
import Investigation from './Investigation';
import { Toolbar } from '@mui/material';
import ConfirmDialog from './ConfirmDialog';
import DialogActions from '@mui/material/DialogActions';
import InvestigationPdf from './InvestigationPdf';
import DialogClose from './DialogClose';
import ShareDialog from './ShareDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { pdf } from '@react-pdf/renderer';

function InvestigationDialog({ onClose, id }) {

  const [title, setTitle] = useState('');
  const [isReOrdering, setIsReOrdering] = useState(false);
  const [investigation, setInvestigation] = useState(null);
  const [document, setDocument] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [showShareDialog, setShowShareDialog] = useState(false);

  useEffect(() => {
    const pdfToBlob = async () => {
      await pdf(document).toBlob().then((blob) => {
        setAttachment(blob);
      });
    };

    if (document) {
      pdfToBlob();
    }
  }, [document]);

  const onLoaded = (investigation) => {
    setTitle(investigation.title);
    setInvestigation(investigation);
    setDocument(<InvestigationPdf data={investigation} />);
  }

  const link = `/utredningar/${id}`;

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={id > 0}
        onClose={onClose}
      >
        <DialogTitle id="scroll-dialog-title">
          <Box m={2}>
            <Typography variant="h4" >{title}</Typography>

            <DialogClose onClose={onClose} />
          </Box>

          <Toolbar>
            <Stack direction="row" spacing={1}>
              {!isReOrdering && <Button onClick={() => setIsReOrdering(true)} startIcon={<InfosocIcon type='reorder' />} variant='outlined'>Ändra ordning</Button>}
              {!isReOrdering && investigation &&
                <PDFDownloadLink document={document} fileName={`${title}.pdf`} style={{ textDecoration: 'none' }}>
                  {() => <Button startIcon={<DownloadIcon />} variant='outlined'>Ladda ned</Button> }
                </PDFDownloadLink>
              }

              {!isReOrdering && <Link target="_blank" href={`/utredningar/${id}/skrivut`}><Button startIcon={<PrintIcon />} variant='outlined'>Skriv ut</Button></Link>}
              {!isReOrdering && <Button onClick={() => setShowShareDialog(true)} startIcon={<ShareIcon />} variant='outlined'>Dela</Button>}
              {isReOrdering && <Button onClick={() => setIsReOrdering(false)} startIcon={<InfosocIcon type='checkmark' />} variant='outlined'>Klar med ändringar</Button>}
            </Stack>
          </Toolbar>
        </DialogTitle >
        <DialogContent>
          <Investigation id={id} onLoaded={onLoaded} isReOrdering={isReOrdering} />
        </DialogContent>
      </Dialog>

      {showShareDialog && 
        <ShareDialog
          open={showShareDialog}
          onClose={() => setShowShareDialog(false)}
          templateSubject={`Utredning ${title}`}
          templateText={`Hej,

Jag ville dela den här utredningen med dig, vänligen se den bifogade PDF-filen.`}
          attachments={[{
            name: `${title}.pdf`,
            file: attachment,
          }]}
        />
      }
    </>
  );
}

InvestigationDialog.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
};

InvestigationDialog.defaultProps = {
  id: -1,
  onClose: () => { },
};

export default InvestigationDialog;