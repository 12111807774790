import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Stack, Box, AppBar, Paper, TextField, Checkbox, Typography, FormControlLabel, Button, Alert } from '@mui/material';
import { get } from "src/services/http-service";
import DenseList from 'src/components/DenseList';
import Sbt from 'src/components/Sbt';
import Newsletter from 'src/components/Newsletter';
import { useNavigate } from 'react-router-dom';
import { post } from "src/services/http-service";
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';

function UsersPage() {

  const [entries, setEntries] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showUser, setShowUser] = useState(false);

  const [id, setId] = useState(-1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [enabled, setEnabled] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  document.title = 'Användare | Infosoc';

  const load = async () => {
    const response = await get('/logins?onlyUsers=true');
    setEntries(response.entries);
  }

  useEffect(() => {
    load();
  }, []);

  const selectUser = (index) => {
    setSelectedIndex(index);

    const user = entries[index];
    setId(user.id);
    setEmail(user.email);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEnabled(user.enabled);
    setAdmin(user.admin);

    setShowUser(true);
  }

  const newUser = () => {
    setId(-1);
    setEmail('');
    setFirstName('');
    setLastName('');
    setEnabled('');
    setAdmin('');

    setShowUser(true);
    setSelectedIndex(-1);
  }

  const onSubmit = async () => {

    setErrorMessage('');

    if (firstName.trim().length === 0) {
      return setErrorMessage('Förnamn är obligatorisk');
    }
    if (lastName.trim().length === 0) {
      return setErrorMessage('Efternamn är obligatorisk');
    }
    if (email.trim().length === 0) {
      return setErrorMessage('E-post är obligatorisk');
    }


    var form = new FormData();
    form.append('firstName', firstName);
    form.append('lastName', lastName);
    form.append('email', email);
    form.append('enabled', !!enabled);
    form.append('admin', !!admin);

    const isNewUser = id === -1;
    const url = isNewUser ? '/logins' : `/logins/${id}`;

    await post(url, form, true);

    setShowUser(false);

    toast.success('Användare ' + (isNewUser ? 'skapad' : 'sparad'));

    setSelectedIndex(-1);

    load();
  }

  return (
    <Box mt={8} >
      <Stack direction="row">
        <Box minWidth={200}>
          <Paper >
            <Box m={2}>
              <Stack direction="row">
                <Typography variant="h6">Användare</Typography>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
                <Button variant="outlined" startIcon={<AddIcon />} onClick={() => newUser()}>Ny användare</Button>
              </Stack>
            </Box>
            <DenseList entries={entries} onSelect={(index) => selectUser(index)} selectedIndex={selectedIndex} showFilter={false} />
          </Paper>
        </Box>
        {showUser && <>
          <Box minWidth={800} ml={4} mt={4}>

            <Typography variant="h6">{id === -1 ? 'Ny användare' : 'Användare'}</Typography>

            {errorMessage && (
              <Box mt={2}>
                <Alert variant="filled" severity="error">
                  {errorMessage}
                </Alert>
              </Box>
            )}

            <TextField
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              margin="normal"
              required
              fullWidth
              label="Förnamn"
            />

            <TextField
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              margin="normal"
              required
              fullWidth
              label="Efternamn"
            />

            <TextField
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              margin="normal"
              required
              fullWidth
              label="Email"
            />

            <Box>
              <FormControlLabel control={<Checkbox checked={admin} onChange={(event) => setAdmin(event.target.checked)} />} label="Administratör" />
            </Box>

            <Box>
              <FormControlLabel control={<Checkbox checked={enabled} onChange={(event) => setEnabled(event.target.checked)} />} label="Aktiverad" />
              <Typography variant="body2"><i>Användaren måste vara aktiverad för att kunna logga in</i></Typography>
            </Box>

            <Box mt={4}>
              <Stack spacing={2} direction="row">
                <Button variant="contained" onClick={() => onSubmit()}>Spara</Button>
                <Button variant="outlined" onClick={() => { setSelectedIndex(-1); setShowUser(false) }}>Avbryt</Button>
              </Stack>
            </Box>

          </Box>
        </>}
      </Stack>
    </Box>
  );
}


export default UsersPage;