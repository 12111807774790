import React from 'react';
import AppBar from '@mui/material/AppBar';
import { Box, Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function PlainAppBar({ title }) {

  const navigate = useNavigate();

  return (
    <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: "white", color: '#222' }}>

      <Box ml={1} mt={1}>
        <Stack direction="row">
          <img src="/logo.png" onClick={() => navigate("/")} style={{ cursor: 'pointer', height: 52 }} />
          <Box mt={1} ml={2}>
            <Typography variant="h5">{title}</Typography>
          </Box>
        </Stack>
      </Box>
    </AppBar>
  )
}

export default PlainAppBar;