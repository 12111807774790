import React, { useState, useEffect } from 'react';
import {
	Button,
	TextField,
	Grid,
	Dialog,
	DialogActions,
	DialogContent,
	Box,
	DialogTitle,
	Typography,
	IconButton,
} from '@mui/material/';

import FolderIcon from '@mui/icons-material/Folder';
import InvestigationIcon from '@mui/icons-material/ArticleOutlined';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

import { TreeItem, SimpleTreeView } from '@mui/x-tree-view';

import { get } from '../services/http-service';
import { recursiveFind, recursiveFindDepth } from '../services/folder-utils';

import InfosocIcon from './InfosocIcon';
import AddNewFolder from './AddNewFolder';

import PropTypes from 'prop-types';

function AddToInvestigationDialog({ open, onClose }) {
	const [investigationName, setInvestigationName] = useState('');
	const [root, setRoot] = useState({ children: [], id: '0', key: '0' });
	const [selectedNodeId, setSelectedNodeId] = useState('-1');
	const [showCreateFolder, setShowCreateFolder] = useState(false);

	const load = async () => {
		let response = await get(`/investigationfolders2`);

		const investigations = response.children.filter((c) => !c.isFolder);
		const folders = response.children.filter((c) => c.isFolder);

		response.children = [
			{
				children: investigations,
				isFolder: true,
				id: -1,
				key: '-1',
				title: 'Mina utredningar',
			},
		].concat(folders);

		setRoot(response);
	};

	useEffect(() => {
		if (open) {
			setSelectedNodeId('-1');
			setInvestigationName('');
			load();
		}

		return () => {
			setSelectedNodeId('-1');
			setInvestigationName('');
			setShowCreateFolder(false);
		};
	}, [open]);

	const renderIcon = (props) => {
		const { node } = props;
		if (node.id === -1) {
			return <FolderIcon color='secondary' />;
		}

		return !node.isFolder ? <InfosocIcon type='investigation' /> : <FolderIcon color='primary' />;
	};

	const renderTree = (node) => {
		return (
			<TreeItem itemId={'' + node.id} label={node.title} key={node.key} slots={{
				collapseIcon: renderIcon,
				expandIcon: renderIcon,
				endIcon: renderIcon,
			}}
			slotProps={{
				endIcon: { node, },
				collapseIcon: { node },
				expandIcon: { node },
			}}
			>
				{node.children.map((c) => renderTree(c))}
			</TreeItem>
		);
	};

	const onSubmit = async () => {
		const selected = recursiveFind(root, selectedNodeId);
		onClose(selectedNodeId, selected.title, selected.isFolder ? investigationName : null);
	};

	const selected = recursiveFind(root, selectedNodeId);
	const selectedDepth = recursiveFindDepth(root, selectedNodeId);
	const allowCreate = selected && selected.isFolder;
	const allowSubmit =
		selected && (!selected.isFolder || (allowCreate && investigationName.trim().length > 0));
	const allowCreateFolder = selected && selected.isFolder && selectedDepth < 2;

	return (
		<Dialog open={open} scroll='paper' onClose={() => onClose()}>
			<DialogTitle id='scroll-dialog-title'>
				<Grid container direction='row' alignItems='center'>
					<Grid item>
						<Box mt={1} mr={1}>
							<InvestigationIcon color='secondary' />
						</Box>
					</Grid>
					<Grid item color='secondary'>
						<Typography variant='h6' color='secondary'>
							Spara i utredning
						</Typography>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<SimpleTreeView
					defaultSelected={'-1'}
					defaultExpandedItems={['-1']}
					onItemFocus={(args, nodeId) => setSelectedNodeId(`${nodeId}`)}

					sx={{ minHeight: 200, maxHeight: 400, flexGrow: 1, maxWidth: 400, minWidth: 400 }}
				>
					{root.children.map((c) => renderTree(c))}
				</SimpleTreeView>

				{allowCreate && (
					<Box>
						<Typography variant='subtitle2'>
							Spara i <b>ny utredning</b> i vald katalog:
						</Typography>
						<TextField
							onChange={(e) => setInvestigationName(e.target.value)}
							value={investigationName}
							margin='normal'
							required
							fullWidth
							label='Ange namn'
						/>
					</Box>
				)}
			</DialogContent>
			{showCreateFolder && allowCreateFolder && selected.isFolder && (
				<DialogContent dividers sx={{ scrollbarGutter: 'stable' }}>
					<AddNewFolder
						parentFolder={recursiveFind(root, parseInt(selectedNodeId))}
						type='investigation'
						onComplete={(parentId) => {
							setShowCreateFolder(false);
							load();
							setSelectedNodeId(`${parentId}`);
						}}
					/>
				</DialogContent>
			)}
			<DialogActions>
				<IconButton
					variant='contained'
					onClick={() => setShowCreateFolder(!showCreateFolder)}
					color='primary'
					title='Skapa ny katalog'
					disabled={!allowCreateFolder}
				>
					<CreateNewFolderIcon />
				</IconButton>
				<div style={{ flex: '1 0 0' }} />
				<Button variant='contained' disabled={!allowSubmit} onClick={onSubmit}>
					Spara
				</Button>
				<Button onClick={() => onClose()}>Avbryt</Button>
			</DialogActions>
		</Dialog>
	);
}

AddToInvestigationDialog.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool,
};

AddToInvestigationDialog.defaultProps = {
	onClose: () => {},
	open: false,
};

export default AddToInvestigationDialog;
