import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import HeaderIcon from '../../components/HeaderIcon';
import EBookIcon from '@mui/icons-material/TabletMac';
import CoursesIcon from '@mui/icons-material/SchoolOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Typography, Link, Button } from '@mui/material';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import { getLawDocumentsByCategory } from 'src/services/api-utils';
import { LocalStorageService } from 'src/services/local-storage-service';

function CourseSkeleton() {
	return (
		<Box mt={0} ml={2} mr={2}>
			<Skeleton height={80} />
		</Box>
	);
}

function Courses({ entries, loading }) {
	const [showEbooks, setShowEbooks] = useState(false);
	const IMAGE_CSS = {
		maxWidth: '100%',
		borderRadius: 10,
		cursor: 'pointer',
		border: '1px solid #ddd',
		opacity: 0.7,
	};
	const IMAGE_TEXT_CSS = {
		fontSize: '16px',
		position: 'absolute',
		bottom: 5,
		left: 0,
		background: 'rgb(0,0,0,0.4)',
		padding: '10px 20px',
		color: '#fff',
		borderRadius: '0 0 10px 10px',
		minWidth: 'calc(100% - 40px)',
	};
	const dbs = LocalStorageService.getDBs();

	useState(() => {
		const fetchEBooks = async () => {
            const ebooks = await getLawDocumentsByCategory(9);
            setShowEbooks(ebooks.entries.length > 0);
        };

        fetchEBooks();
	}, [dbs]);

	return (
		<Paper variant='outlined' style={{ boxShadow: 'rgb(200 200 200 / 50%) 1px 2px 25px 2px' }}>
			<Box mt={2}>
				<Box ml={2} mr={2}>
					<HeaderIcon
						text='Kompetensutveckling'
						icon={<CoursesIcon color='secondary' />}
						tooltip={
							<Box ml={1}>
								Under Kompetensutveckling visas ett urval av vad Infosoc kan erbjuda inom valda
								rättsområden. Det handlar om såväl fysiska som webbaserade utbildningar samt
								aktuella webinar och litteratur. Utöver det urval som presenteras är det även
								möjligt att ta del av hela Infosoc utbud genom att klicka på <Link href='https://kurser.infosoc.se/courses' target='_blank' variant='body1'>SE ALLA</Link>.
							</Box>
						}
					/>
				</Box>
				<Box mt={2} ml={2} mr={2}>
					<Box>
						<Link
							href='https://kurser.infosoc.se/courses'
							target='_blank'
							style={{ textDecoration: 'none' }}
						>
							<Button fullWidth variant='outlined' disableElevation>
								Se alla
							</Button>
						</Link>
					</Box>
					{showEbooks && (
						<Box mt={1}>
							<Link href='/e-litteratur' target='_self' style={{ textDecoration: 'none' }}>
								<Button fullWidth variant='outlined' disableElevation startIcon={<EBookIcon />}>
									E-litteratur
								</Button>
							</Link>
						</Box>
					)}
				</Box>
				<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
					{entries.length === 0 && (
						<Box>
							<CourseSkeleton />
							<CourseSkeleton />
						</Box>
					)}

					{entries.map((entry, index) => (
						<ListItem disablePadding key={index}>
							<Box m={2}>
								<a target='_blank' href={'https://kurser.infosoc.se/' + entry.url} rel='noreferrer'>
									<div style={{ position: 'relative' }}>
										<img src={entry.image} style={IMAGE_CSS} alt='' />
										<Typography variant='body2'>
											<span style={IMAGE_TEXT_CSS}>{entry.title}</span>
										</Typography>
									</div>
								</a>
							</Box>
						</ListItem>
					))}
				</List>
			</Box>
		</Paper>
	);
}

Courses.propTypes = {
	entries: PropTypes.array,
	loading: PropTypes.bool,
};

export default Courses;
