import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import DialogClose from './DialogClose';

function HeaderIcon({ text, tooltip, icon, event, iconOnly }) {

  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    if (event) {
      document.addEventListener(event, () => setTooltipOpen(true));
    }
  }, [event]);

  return (
    <>
      <Dialog onClose={() => setTooltipOpen(false)} open={tooltipOpen}>
        <DialogTitle>
          {text}
        </DialogTitle>
        <DialogClose onClose={() => setTooltipOpen(false)} />

        <Typography variant="subtitle1" gutterBottom>
          <Box ml={2} mr={2} mb={4}>
            {tooltip}
          </Box>
        </Typography>
      </Dialog>

      <Box sx={{ flexGrow: 1 }} >
        <Grid
          container
          justifyContent="space-between"
        >
          {!iconOnly &&
            <Grid item >

              <Stack direction="row" alignItems="center" gap={1}>
                {icon}
                
                  <Typography variant="h6" >
                  {text}
                </Typography>
                

              </Stack>
            </Grid>
          }

          <Grid item >
            <Box mt={0.8}> 
              <InfoIcon onClick={() => setTooltipOpen(true)} fontSize='small' color='secondary' sx={{ cursor: 'pointer' }} />
            </Box>
          </Grid>
        </Grid>
      </Box >
    </>
  )
}

HeaderIcon.propTypes = {
  text: PropTypes.string,
  tooltip: PropTypes.object,
  icon: PropTypes.object,
  iconOnly: PropTypes.bool,
};

HeaderIcon.defaultProps = {
  text: '',
  iconOnly: false,
};

export default HeaderIcon;