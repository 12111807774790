import React from 'react';
import { Page, Text, View, Document, StyleSheet, Link, Font } from '@react-pdf/renderer';
import { parseStyledHtml, buildParagraphs } from 'src/services/pdf-utils';

// Import and register Roboto font
import RobotoRegular from 'src/fonts/Roboto-Regular.ttf';
import RobotoItalic from 'src/fonts/Roboto-Italic.ttf';
import RobotoBold from 'src/fonts/Roboto-Bold.ttf';
import RobotoBoldItalic from 'src/fonts/Roboto-BoldItalic.ttf';

Font.register({
	family: 'Roboto',
	fonts: [
		{ src: RobotoRegular, fontStyle: 'normal', fontWeight: 'normal' },
		{ src: RobotoItalic, fontStyle: 'italic', fontWeight: 'normal' },
		{ src: RobotoBold, fontStyle: 'normal', fontWeight: 'bold' },
		{ src: RobotoBoldItalic, fontStyle: 'italic', fontWeight: 'bold' },
	],
});

// Create styles
const styles = StyleSheet.create({
	page: {
		flexDirection: 'row',
		padding: 30,
		paddingBottom: 40,
		fontFamily: 'Roboto',
		fontSize: 12,
		lineHeight: 1.2,
		textAlign: 'justify',
	},
	title: {
		fontSize: 20,
		marginBottom: 12,
		lineHeight: 1.5,
	},
	header: {
		fontSize: 16,
		marginBottom: 8,
		lineHeight: 1.5,
	},
	property: {
		fontSize: 12,
		paddingBottom: 3,
	},
	preamble: {
		fontSize: 12,
		marginBottom: 8,
	},
	text: {},
	paragraph: {
		marginBottom: 8,
	},
	break: {
		marginBottom: 8,
		display: 'block',
	},
	blockquote: {
		margin: 10,
		paddingLeft: 20,
		fontStyle: 'italic',
	},
	bold: {
		fontWeight: 'bold',
	},
	italic: {
		fontStyle: 'italic',
	},
	underline: {
		textDecoration: 'underline',
	},
	link: {
		color: 'blue',
		cursor: 'pointer',
		margin: 0,
		padding: 0,
		display: 'inline',
	},
});

// Create Document Component
const SbtPdf = ({ data }) => {
	const { content } = data;
	const htmlString = content.text;
	const textBlocks = buildParagraphs(htmlString, styles);

	return (
		<Document>
			<Page size='A4' style={styles.page} renderAnnotationLayer={false} renderTextLayer={false}>
				<View>
					<Text style={styles.title}>{content.titleFull}</Text>
					{!content.hideLawReportDetails && (
						<View style={{ marginBottom: '8px' }}>
							<Text style={styles.property}>
								{content.isQuestionOrAnalysis ? 'Datum:' : 'Beslutsdatum:'} {content.decisionDate}
							</Text>
							{!content.isAnalysis && (
								<Text style={styles.property}>Pub. beteckning: {content.publication}</Text>
							)}
							{content.caseNumber && (
								<Text style={styles.property}>Målnr/Dnr: {content.caseNumber}</Text>
							)}
							{content.organizations.length && (
								<Text style={styles.property}>
									Organisationer: {content.organizations.join(',')}
								</Text>
							)}
						</View>
					)}
					{!content.isAnalysis && (
						<View>
							<Text style={styles.header}>Sammanfattning</Text>
							<Text style={styles.preamble}>{content.preamble.trim()}</Text>
							<Text style={styles.header}>Kommentar</Text>
						</View>
					)}
					{content.isAnalysis && <Text style={styles.header} />}
					<View>
						{textBlocks.map((block, blockIndex) => {
							return (
								<Text key={blockIndex} style={styles.paragraph}>
									{block.map((item, itemIndex) => {
										if (item.type === 'link') {
											return (
												<Link key={`block-${itemIndex}`} src={item.link} style={item.style}>
													{item.content}
												</Link>
											);
										}

										return (
											<Text key={`block-${itemIndex}`} style={item.style}>
												{item.content}
											</Text>
										);
									})}
								</Text>
							);
						})}
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default SbtPdf;
