import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import HeaderIcon from '../../components/HeaderIcon';
import ExpertIcon from '@mui/icons-material/AccountCircle';
import QuestionIcon from '@mui/icons-material/Comment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import ContactUsDialog from 'src/components/ContactUsDialog';
import { toast } from 'react-toastify';

function Experts({ entries, loading }) {
	const [showContactUsDialog, setShowContactUsDialog] = useState(false);

	const ExpertSkeleton = ({ count = 2 }) => (
		<Box m={2}>
			{Array.from({ length: count }, (_, index) => (
				<Box m={2} key={index}>
					<Stack key={index} direction='row' alignItems='center' gap={1}>
						<Skeleton variant='circular'>
							<Avatar />
						</Skeleton>
						<Skeleton animation='wave' height={50} width='80%' />
					</Stack>
				</Box>
			))}
		</Box>
	);

	return (
		<Paper variant='outlined' style={{ boxShadow: 'rgb(200 200 200 / 50%) 1px 2px 25px 2px' }}>
			<Box mt={2}>
				<Box ml={2} mr={2}>
					<HeaderIcon
						text='Våra experter'
						icon={<ExpertIcon color='secondary' />}
						tooltip={
							<Box ml={1}>
								Våra experter visar Infosoc juridiska experter inom valda rättsområden som du kommer
								i kontakt med om du använder Infosoc enklare juridiska rådgivning. Härunder har du
								möjlighet att via formuläret ställa din fråga direkt till dem; en funktion som ingår
								för dig som användare. Rådgivningen innebär att experten tittar på frågan i upp till
								30 minuter och ger vägledning kring densamma utifrån vår databas innehåll. Experten
								besvarar din fråga via telefon eller mail inom 1-2 arbetsdagar.
							</Box>
						}
					/>
				</Box>
				<Box mt={2} ml={2} mr={2}>
					<Button
						fullWidth
						startIcon={<QuestionIcon />}
						variant='outlined'
						disableElevation
						onClick={() => setShowContactUsDialog(true)}
					>
						Ställ din fråga
					</Button>
				</Box>

				{loading && <ExpertSkeleton />}

				<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
					{entries.map((entry, index) => (
						<ListItem key={index}>
							<ListItemAvatar>
								<Box mr={1}>
									<Avatar
										alt={entry.name}
										src={`/experts/${entry.fileName}`}
										sx={{ width: 60, height: 60 }}
									/>
								</Box>
							</ListItemAvatar>
							<ListItemText primary={entry.name} secondary={entry.title} />
						</ListItem>
					))}
				</List>
			</Box>
			<ContactUsDialog
				open={showContactUsDialog}
				onClose={() => {
					setShowContactUsDialog(false);
				}}
				initalOption='Juridisk rådgivning'
			/>
		</Paper>
	);
}

Experts.propTypes = {
	entries: PropTypes.array,
	loading: PropTypes.bool,
};

export default Experts;
