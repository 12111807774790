import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogClose from './DialogClose';

function TextInputDialog({
	open,
	onClose,
	title,
	inputLabel,
	text,
	saveButtonLabel,
	errorMessage,
	loading,
	initalValue,
}) {
	const [input, setInput] = useState('');

	useEffect(() => {
		setInput(initalValue);
	}, [initalValue, open]);

	const checkSubmit = (event) => {
		if (event.keyCode == 13 && input.trim().length > 0) {
			onClose(input);
		}
	};

	return (
		<Dialog
			onKeyUp={(e) => checkSubmit(e)}
			open={open}
			scroll="paper"
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<Box minWidth={600}>
				<DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
				<DialogClose onClose={() => onClose()} />
				<DialogContent dividers>
					<Box mb={2}>
						<Typography variant="subtitle1" gutterBottom>
							{text}
						</Typography>
					</Box>

					{errorMessage && (
						<Box mb={2}>
							<Alert variant="filled" severity="error">
								{errorMessage}
							</Alert>
						</Box>
					)}

					<TextField
						value={input}
						onChange={(e) => setInput(e.target.value)}
						margin="normal"
						required
						fullWidth
						label={inputLabel}
						autoFocus
					/>
				</DialogContent>
				<DialogActions>
					<LoadingButton
						loading={loading}
						variant="contained"
						disabled={!input || input.trim().length === 0}
						onClick={() => onClose(input)}
					>
						{saveButtonLabel}
					</LoadingButton>
					<Button onClick={() => onClose()}>Avbryt</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
}

TextInputDialog.propTypes = {
	open: PropTypes.bool,
	loading: PropTypes.bool,
	onClose: PropTypes.func,
	title: PropTypes.string,
	inputLabel: PropTypes.string,
	text: PropTypes.string,
	saveButtonLabel: PropTypes.string,
	errorMessage: PropTypes.string,
	initalValue: PropTypes.string,
};

export default TextInputDialog;
