import React, { useState, useEffect } from 'react';
import { get } from "../services/http-service";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import storageService from 'src/services/storage-service';
import SelectAllIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/Clear';


import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Sbl from 'src/components/Sbl';
import InfosocIcon from './InfosocIcon';
import DialogCloseExpand from './DialogCloseExpand';


function SblDialog({ id, open, onClose, linkParams, token, hideRightColumn, selectMode, onSelectSection, query, disableNewWindow }) {

  let [title, setTitle] = useState('');

  let titleTag = 'h5';

  if(title.length > 100){
    titleTag = 'h6';
  }

  if(title.length > 150){
    titleTag = 'subtitle1';
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogCloseExpand
        link={`/lag/${id}`}
        disableNewWindow={disableNewWindow}
        onClose={onClose}
      />

      <Box mt={4} ml={2} style={{ marginRight: 300 }}>
        <Typography variant={titleTag}>{title}</Typography>
      </Box>

      <Box mt={4}>
        {id > 0 && (
          <Sbl
            offsetTop={160}
            query={query}
            linkParams={linkParams}
            idOrFileName={String(id)}
            token={token}
            hideRightColumn={hideRightColumn}
            selectMode={selectMode}
            onSelectSection={onSelectSection}
            onLoaded={(sbl) => setTitle(sbl.title)}
          />
        )}
      </Box>
    </Dialog>
  );
}

SblDialog.propTypes = {
  hideRightColumn: PropTypes.bool,
  selectMode: PropTypes.bool,
  id: PropTypes.number,
  query: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  linkParams: PropTypes.string,
  token: PropTypes.string,
  onSelectSection: PropTypes.func,
  disableNewWindow: PropTypes.bool,
};

SblDialog.defaultProps = {
  hideRightColumn: false,
  selectMode: false,
  onSelectSection: () => {},
  query: '',
  disableNewWindow: false,
};


export default SblDialog;