import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { post } from 'src/services/http-service';
import storageService from 'src/services/storage-service';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Alert, { alertClasses } from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@mui/material';
import TextInputDialog from 'src/components/TextInputDialog';
import RequestLoginDialog from 'src/components/RequestLoginDialog';

function LoginPage({ requestLogin, forgotPassword, onSuccess }) {
	const [loading, setLoading] = useState(false);
	const [loginFailed, setLoginFailed] = useState(false);
	const [invalidInput, setInvalidInput] = useState(false);
	const [loginMode, setLoginMode] = useState('email');
	const [licenseKey, setLicenseKey] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showForgotPassword, setShowForgotPassword] = useState(
		forgotPassword || false
	);
	const [showRequestLogin, setShowRequestLogin] = useState(
		requestLogin || false
	);
	const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] =
		useState('');
	const [toastMessage, setToastMessage] = useState(null);

	const navigate = useNavigate();

	document.title = 'Logga in | Infosoc';

	const isLoggedIn = storageService.isLoggedIn();
	useEffect(() => {
		if (isLoggedIn && !showRequestLogin && !showForgotPassword) {
			navigate('/');
		}
	}, [navigate, isLoggedIn, showRequestLogin, showForgotPassword]);

	const resetPassword = async (email) => {
		if (!email) {
			setForgotPasswordErrorMessage('');
			return setShowForgotPassword(false);
		}

		setLoading(true);

		if (isLoggedIn && showForgotPassword) {
			storageService.clear();
		}

		let form = new FormData();
		form.append('email', email);
		const result = await post('/login/recover', form);

		setLoading(false);

		if (result.valid) {
			setShowForgotPassword(false);
			setToastMessage({
				type: 'success',
				message: `Vi har skickat instruktioner till din e-post ${email}`,
			});
		} else {
			setForgotPasswordErrorMessage(result.message);
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		setLoading(true);
		setInvalidInput(false);

		// Validate email/licenseKey is not empty
		if (!email && !licenseKey) {
			setLoading(false);
			setInvalidInput(true);
			return;
		}

		let form = new FormData();
		if (loginMode === 'email') {
			form.append('username', email);
			form.append('password', password);
		} else {
			form.append('licenseKey', licenseKey);
		}

		let loginResponse = await post('/login', form);

		setLoading(false);

		if (loginResponse.status === 'success') {
			storageService.save(loginResponse);
			onSuccess();
			navigate('/');
		} else {
			setLoginFailed(true);
		}
	};

	const isEmailLogin = loginMode === 'email';

	return (
		<Box
			component={'div'}
			style={{
				background: 'url(/login_background.jpg)',
				backgroundSize: 'cover',
				position: 'fixed',
				width: '100%',
				height: '100%',
				top: 0,
				left: 0,
				zIndex: 0,
			}}
		>
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="start"
				sx={{
					minHeight: '100vh',
					marginTop: {
						xs: 0,
						sm: 8,
						md: 12,
					},
				}}
			>
				<Grid item xs={3}>
					<Paper elevation={3} sx={{ zIndex: 10, maxWidth: 700 }}>
						<Box p={8}>
							<Box
								component="div"
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								<Link href="/">
									<Box
										component={'img'}
										src="/logo.svg"
										alt="InfoSoc Logo"
										width={220}
										mb={2}
									/>
								</Link>
							</Box>
							<Box
								component="form"
								onSubmit={handleSubmit}
								noValidate
							>
								<Box mt={2}>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										value={loginMode}
										name="radio-buttons-group"
										onChange={(event) => {
											setLoginMode(event.target.value);
											setLoginFailed(false);
											setInvalidInput(false);
										}}
									>
										<FormControlLabel
											value="email"
											control={<Radio />}
											label="Personlig inloggning"
										/>
										<Box ml={4}>
											<Typography variant="body2">
												Ingår kostnadsfritt i din licens
												och ger dig bl. a. tillgång till
												funktionen lag- och
												rättsfallsbevakning.
												<br />
												<Link
													href="#"
													variant="body2"
													onClick={() =>
														setShowRequestLogin(
															true
														)
													}
												>
													Beställ personlig inloggning
												</Link>
											</Typography>
										</Box>
										<FormControlLabel
											value="shared"
											control={<Radio />}
											label="Gemensam inloggning"
										/>
										<Box ml={4} mb={4}>
											<Typography variant="body2">
												Kan fortfarande användas i de
												fall då personlig inloggning ej
												beställts.
											</Typography>
										</Box>
									</RadioGroup>
								</Box>

								{loginFailed && (
									<Box mb={4}>
										<Alert
											variant="filled"
											severity="error"
										>
											Inloggningen misslyckades
										</Alert>
									</Box>
								)}

								{invalidInput && (
									<Box mb={4}>
										<Alert
											variant="filled"
											severity="error"
										>
											Felaktiga inloggningsuppgifter.
											Vänligen försök igen.
										</Alert>
									</Box>
								)}

								{toastMessage != null && (
									<Box mb={4}>
										<Alert
											variant="filled"
											severity={toastMessage.type}
										>
											{toastMessage.message}
										</Alert>
									</Box>
								)}

								{isEmailLogin && (
									<Box>
										<TextField
											onChange={(e) =>
												setEmail(e.target.value)
											}
											margin="normal"
											required
											fullWidth
											id="email"
											label="E-post"
											name="email"
											autoComplete="email"
											autoFocus
										/>
										<TextField
											onChange={(e) =>
												setPassword(e.target.value)
											}
											margin="normal"
											required
											fullWidth
											name="lösenord"
											label="Lösenord"
											type="password"
											id="password"
											autoComplete="current-password"
										/>
									</Box>
								)}
								{!isEmailLogin && (
									<Box>
										<TextField
											onChange={(e) =>
												setLicenseKey(e.target.value)
											}
											margin="normal"
											required
											fullWidth
											id="licensekey"
											label="Lösenord"
											name="licensekey"
											type="password"
											autoComplete="licensekey"
											autoFocus
										/>
									</Box>
								)}

								<LoadingButton
									loading={loading}
									type="submit"
									fullWidth
									variant="contained"
									sx={{ mt: 3, mb: 2 }}
								>
									Logga in
								</LoadingButton>
								{isEmailLogin && (
									<Grid
										container
										alignItems="center"
										justifyContent="center"
										direction="column"
									>
										<Grid item xs alignSelf={'center'}>
											<Link
												href="#"
												variant="body2"
												onClick={() =>
													setShowForgotPassword(true)
												}
											>
												Glömt lösenordet?
											</Link>
										</Grid>
									</Grid>
								)}
							</Box>
						</Box>
					</Paper>
					<RequestLoginDialog
						open={showRequestLogin}
						onClose={() => setShowRequestLogin(false)}
						onError={(message) => setToastMessage(message, 'error')}
						showForgotPassword={() => setShowForgotPassword(true)}
					/>
					<TextInputDialog
						open={showForgotPassword}
						loading={loading}
						title="Glömt lösenordet"
						inputLabel="E-post"
						text="Ange din e-post. Vi kommer att skicka en länk till din e-post med instruktioner om hur du kan ändra ditt lösenord"
						saveButtonLabel="Skicka"
						errorMessage={forgotPasswordErrorMessage}
						onClose={(email) => resetPassword(email)}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}

LoginPage.propTypes = {
	onSuccess: PropTypes.func,
};

export default LoginPage;
