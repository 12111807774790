import React from 'react';
import { Box, Typography } from '@mui/material';
import VideoFrame from 'src/components/VideoFrame';

const VIDEO_URL =	'https://infosoc-files-sthml.s3.eu-north-1.amazonaws.com/Filmer/Infosoc-Anva%CC%88ndarmanual-textad.mp4';

function HelpPage() {
	return (
		<Box
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<Box
				sx={{position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>
				<Typography variant='h4' gutterBottom>
					Användarmanual
				</Typography>
				<VideoFrame src={VIDEO_URL} title='Användarmanual' />
			</Box>
		</Box>
	);
}

export default HelpPage;
