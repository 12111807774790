import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import List from "@mui/material/List";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from "@mui/material/ListItemText";
import Typography from '@mui/material/Typography';
import InfosocIcon from './InfosocIcon';


function StructuredSearchResult({ 
  specialEntries, 
  entries, 
  allEntry, 
  showSubcategories, 
  offsetTop, 
  onSelectResult}) {

  const getIconColor = (entry) => {
    switch(entry.icon) {
      case 'gavel': return 'primary';
      case 'comment': return 'primary';
      case 'magnify': return 'default';
      case 'format-list-bulleted': return 'default';
      default: return 'primary';
    }
  };

  const getIconOpacity = (entry) => {
    switch(entry.icon) {
      case 'format-list-bulleted': return 0.7;
      case 'magnify': return 0.7;
      default: return 1;
    }
  };

  return ( 
    specialEntries.length > 0 || 
    entries.length > 0 || 
    allEntry != null ) && (
  
    <Box minWidth={250} maxWidth={300} width={showSubcategories ? 'fit-content' : 'fill-available'}>
      <Paper square={true} style={{ height: '100%', clipPath: 'inset(0px 0px 0px -10px)' }}>
        { /* Special entries - lawreports */ }
        {specialEntries.length > 0 && ( 
          <>
            <Box pt={1} pl={2}>
              <Typography variant="h6">Rättsfall</Typography>
            </Box>
            <List component="nav" style={{marginTop: {offsetTop} }} sx={{ fontSize: '14px' }}>
              { specialEntries.map(entry => (
                  <ListItemButton onClick={(e) => onSelectResult(`/${e.currentTarget.dataset.path}`)} data-path={entry.id} sx={{ height: '36px'}} title={entry.name} key={entry.id}>
                    <Box mr={2}>
                      <InfosocIcon type={entry.icon} color={getIconColor(entry)} opacity={getIconOpacity(entry)}></InfosocIcon>
                    </Box>
                    <ListItemText primary={
                      <div style={{ overflowX: 'hidden', textOverflow: 'ellipsis', fontSize: '14px'}}>
                        <span>{entry.name}</span>
                      </div>
                    } className='lawReportSummaryText' sx={{ fontFamily: '"Roboto","Helvetica","Arial", sans-serif', fontWeight: 400, fontSize: '14px' }} />
                  </ListItemButton>
                )
              )}
            </List>
          </>
        )}

        { /* Entries - more hits */ }
        { entries.length > 0 && (
          <>
            <Box pt={1} pl={2}>
              <Typography variant="h6">Fler träffar</Typography>
            </Box>
            <List component="nav" style={{marginTop: {offsetTop} }} sx={{ fontSize: '14px' }}>
              {entries.map(entry => (
                  <ListItemButton onClick={(e) => onSelectResult(`/${e.currentTarget.dataset.path}`)} data-path={entry.id} sx={{ height: '36px'}} title={entry.name}>
                    <Box mr={2}>
                      <InfosocIcon type={entry.icon} color={getIconColor(entry)} opacity={getIconOpacity(entry)}></InfosocIcon>
                    </Box>
                    <ListItemText primary={
                      <div style={{ overflowX: 'hidden', textOverflow: 'ellipsis', fontSize: '14px'}}>
                        <span>{entry.name}</span>
                      </div>
                    } className='lawReportSummaryText' sx={{ fontFamily: '"Roboto","Helvetica","Arial", sans-serif', fontWeight: 400, fontSize: '14px' }} />
                  </ListItemButton>
                )
              )}
            </List>
          </>
        )}

        { /* All entry - all button */ }
        { allEntry != null && (
          <>
            <List component="nav" style={{marginTop: {offsetTop} }} sx={{ fontSize: '14px' }}>
              <ListItemButton onClick={(e) => onSelectResult(`/${e.currentTarget.dataset.path}`)} data-path={allEntry.id} sx={{ height: '36px'}} title={allEntry.name}>
                <Box mr={2}>
                  <InfosocIcon type={allEntry.icon} color={getIconColor(allEntry)} opacity={getIconOpacity(allEntry)}></InfosocIcon>
                </Box>
                <ListItemText primary={
                  <div style={{ overflowX: 'hidden', textOverflow: 'ellipsis', fontSize: '14px'}}>
                    <span>{allEntry.name}</span>
                  </div>
                } className='lawReportSummaryText' sx={{ fontFamily: '"Roboto","Helvetica","Arial", sans-serif', fontWeight: 400, fontSize: '14px' }} />
              </ListItemButton>
            </List>
          </>
        )}
      </Paper>
    </Box>
  );
}

export default StructuredSearchResult;