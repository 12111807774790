import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { Viewer } from '@react-pdf-viewer/core';
import Box from '@mui/material/Box';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { API_URL } from 'src/services/http-service';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import storageService from 'src/services/storage-service';
import svLocale from 'src/locale/sv_SE.json';
import { LocalizationContext } from '@react-pdf-viewer/core';
import { printPlugin } from '@react-pdf-viewer/print';
import { searchPlugin } from '@react-pdf-viewer/search';

function AttachmentViewer({
	id,
	token,
	offsetTop,
	url,
	query,
	matchPhrase,
	showSearch,
	onSearchToggle,
}) {
	const viewerRef = createRef();
	const printPluginInstance = printPlugin({
		enableShortcuts: false,
		setPages: () => {
			return [1];
		},
	});

	const renderToolbar = (Toolbar) => (
		<Toolbar>
			{(slots) => {
				const {
					CurrentPageInput,
					EnterFullScreen,
					GoToNextPage,
					GoToPreviousPage,
					NumberOfPages,
					ShowSearchPopover,
					Zoom,
					ZoomIn,
					ZoomOut,
				} = slots;

				return (
					<div
						style={{
							alignItems: 'center',
							display: 'flex',
							width: '100%',
						}}
					>
						<div style={{ padding: '0px 2px' }}>
							<ShowSearchPopover />
						</div>
						<div style={{ padding: '0px 2px' }}>
							<GoToPreviousPage />
						</div>
						<div style={{ padding: '0px 2px', width: '4rem' }}>
							<CurrentPageInput />
						</div>
						<div style={{ padding: '0 2px 0 5px' }}>
							/ <NumberOfPages />
						</div>
						<div style={{ padding: '0px 2px' }}>
							<GoToNextPage />
						</div>
						<div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
							<ZoomOut />
						</div>
						<div style={{ padding: '0px 2px' }}>
							<Zoom />
						</div>
						<div style={{ padding: '0px 2px' }}>
							<ZoomIn />
						</div>

						<div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
							<EnterFullScreen />
						</div>
					</div>
				);
			}}
		</Toolbar>
	);

	useEffect(() => {
		const handleKeyPress = (e) => {
			// Ctrl/cmd + F
			if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) {
				e.preventDefault();
				onSearchToggle();
			}
		};

		let viewer;
		if (viewerRef.current) {
			viewer = viewerRef.current.viewerContainer;
			viewer.addEventListener('keydown', handleKeyPress);
		}

		return () => {
			// cleanup
			if (viewer) {
				viewer.removeEventListener('keydown', handleKeyPress);
			}
		};
	}, [onSearchToggle, viewerRef]);

	useEffect(() => {
		toggleSearch();
	}, [showSearch]);

	const baseSearchPluginInstance = searchPlugin();
	const toggleSearch = () => {
		const showSearchButton = document.querySelector(
			'[aria-describedby="rpv-core__tooltip-body-search-popover"] > .rpv-core__minimal-button'
		);
		if (showSearchButton) {
			showSearchButton.click();
		}
	};

	const defaultLayoutPluginInstance = defaultLayoutPlugin({
		renderToolbar,
		searchPluginInstance: baseSearchPluginInstance,
	});

	let plugins = [defaultLayoutPluginInstance, printPluginInstance];

	if (query) {
		let keyword;

		if (matchPhrase) {
			keyword = new RegExp(`${query}[\\wåäö]*`, 'gi');
		} else {
			const keywords = String(query)
				.toLowerCase()
				.split(/[\s,]+/);

			keyword = keywords.map(
				(word) => new RegExp(`\\b${word}[\\wåäöÅÄÖ]*\\b`, 'gi')
			);
		}

		plugins = [
			defaultLayoutPluginInstance,
			printPluginInstance,
			searchPlugin({
				keyword: keyword,
				onHighlightKeyword: (props) => {
					props.highlightEle.style.backgroundColor =
						'rgba(252, 237, 8, .3)';
					props.highlightEle.style.border = '1px solid transparent';
				},
			}),
		];
	}

	const login = storageService.load();
	const fullUrl = url
		? url
		: `${API_URL}/attachment/${id}${token ? '?token=' + token : ''}`;

	return (
		<Box height={`calc(100vh - ${offsetTop}px)`}>
			<LocalizationContext.Provider
				value={{
					currentLocale: svLocale,
				}}
			>
				<Viewer
					defaultScale={1.5}
					localization={svLocale}
					httpHeaders={{ accesskey: login.accessKey }}
					plugins={plugins}
					fileUrl={fullUrl}
					ref={viewerRef}
				/>
			</LocalizationContext.Provider>
		</Box>
	);
}

AttachmentViewer.propTypes = {
	id: PropTypes.number,
	token: PropTypes.string,
	offsetTop: PropTypes.number,
	url: PropTypes.string,
	query: PropTypes.string,
};

AttachmentViewer.defaultProps = {
	offsetTop: 180,
	showPrint: false,
	query: '',
};

export default AttachmentViewer;
