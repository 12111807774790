import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { get } from 'src/services/http-service';
import DenseList from 'src/components/DenseList';
import HoverableBox from 'src/components/HoverableBox';
import Loading from './Loading';
import StructuredSearchResult from './StructuredSearchResult';
import { menuOptions } from 'src/App';

function StructuredSearch({
  baseUrl,
  showFilter,
  showSecondaryFilter,
  showResultFilter,
  showAllCategoryOption,
  showSubcategories,
  onSelectResult,
  headers,
  ignoreSpecialEntries,
  onSelectCategory,
  offsetTop,
  menuOption,
}) {
  const [loading, setLoading] = useState(false);
  const [loadingSubCategory, setLoadingSubCategory] = useState(false);

  const [data, setData] = useState({
    categories: [[], [], [], []],
    selected: [-1, -1, -1, -1],
    filteredSelected: [-1, -1, -1, -1],
    results: [],
    x_results: {
      specialEntries: [],
      entries: [],
      allEntry: null,
    },
  });

  const [allResults, setAllResults] = useState(null);

  /**
   * hoverableCategory - Verifies if the category is hoverable
   *
   * @param {int} categoryIndex
   * @returns boolean
   */
  const hoverableCategory = (categoryIndex = 0) => {
    const windowWidth = window.innerWidth;

    const windowLimits = {
      0: 1700, // Category 1 limit (px)
      1: 1450, // Category 2 limit (px)
      2: 1350, // Category 3 limit (px)
    };

    const smallScreenLimit = 1400; // Small screen limit (px)
    const smallScreen = windowWidth <= smallScreenLimit;

    // Enforce fold behaviour on next selected category for small screens
    // Otherwise after selecting 2 categories.
    const selectSpacing = smallScreen ? 1 : 2;
    const upcommingSelected = data.selected[categoryIndex + selectSpacing] !== -1;

    // Enforce fold behaviour for pages using at least 3 available categories
    const contentSpacer = categoryIndex <= 0 ? 2 : 1;
    const upcommingContent = data.categories[categoryIndex + contentSpacer].length > 0;

    return upcommingSelected && upcommingContent && windowWidth <= windowLimits[categoryIndex];
  };

  const generateUrl = (newData, listIndex) => {
    const containsQueryParams = baseUrl.indexOf('?') > 0;
    let url = `${baseUrl}${containsQueryParams ? '' : '?'}`;

    for (let i = 0; i < listIndex; i++) {
      let selectedIndex = newData.selected[i];
      let options = newData.categories[i][selectedIndex];
      url = `${url}&options=${options.id}`;
    }

    return url;
  };

  const selectCategory = async (listIndex, filteredIndex, originalIndex) => {
    setLoadingSubCategory(true);

    const newData = { ...data, results: [] };
    newData.selected[listIndex - 1] = originalIndex;
    newData.filteredSelected[listIndex - 1] = filteredIndex;

    for (let i = listIndex; i < 4; i++) {
      newData.categories[i] = [];
      newData.selected[i] = -1;
      newData.filteredSelected[i] = -1;
    }

    setData(newData);

    if (showAllCategoryOption && originalIndex === 0 && allResults) {
      const newResultsData = { 
        ...newData, 
        results: allResults, 
        x_results: {
          specialEntries: [],
          entries: [],
          allEntry: null,
        }
      };

      setData(newResultsData);
      setLoadingSubCategory(false);

      return;
    }

    const url = generateUrl(newData, listIndex);
    const response = await get(url);
    
    newData.categories[listIndex] = response.entries;

    newData.x_results = {
      specialEntries: [],
      entries: [],
      allEntry: null,
    }

    // Special entries
    newData.results = response.summary && !ignoreSpecialEntries ? response.summary.specialEntries : [];
    newData.x_results.specialEntries = (response.summary && !ignoreSpecialEntries) ? response.summary.specialEntries : [];
    
    // More hits
    newData.results = newData.results.concat(response.results || response.summary.entries);
    newData.x_results.entries = response.results || response.summary.entries;

    // All entry
    if (response.summary && response.summary.allEntry) {
      newData.results.push(response.summary.allEntry);
      newData.x_results.allEntry = response.summary.allEntry;
    }

    setData(newData);
    setLoadingSubCategory(false);
  };

  const resetSelectedIndex = (listIndex) => {
    const newData = { ...data };
    newData.selected[listIndex] = -1;
    setData(newData);
  };

  useEffect(() => {
    const addAllOption = (initialData) => {
      // Create a new object with allResults data
      const results = {
        id: -1,
        name: 'Alla',
        icon: 'folder',
      };

      // Add the new object to the first array in categories
      initialData.categories[0].unshift(results);
      initialData.selected[0] = 0;
      initialData.filteredSelected[0] = 0;

      setAllResults(initialData.results);

      return initialData;
    };

    const load = async () => {
      setLoading(true);
      const response = await get(baseUrl);
      
      let initialData = {
        categories: [response.entries, [], [], []],
        selected: [-1, -1, -1, -1],
        filteredSelected: [-1, -1, -1, -1],
        results: [],
        x_results: {
          specialEntries: [],
          entries: [],
          allEntry: null,
        }
      };

      if (showAllCategoryOption) {
        initialData.results = response.results;
        initialData = addAllOption(initialData);
      }

      setData(initialData);

      setLoading(false);
    };

    load();
  }, [baseUrl, showAllCategoryOption]); // Dependency array to re-run the effect when baseUrl changes

  const vars = {
    entry: null
  };

  if (loading) {
    return (
      <Box maxWidth={400} mt={2}>
        <Loading />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack direction="row">
        <HoverableBox
          minWidth={showSubcategories ? 125 : 350}
          maxWidth={450}
          hoverable={showSubcategories ? hoverableCategory(0) : false}
          delay={150}
        >
          <Paper square={true} style={{ clipPath: 'inset(0px 0px 0px -10px)' }}>
            <DenseList
              offsetTop={offsetTop}
              onFilter={() => resetSelectedIndex(0)}
              title={headers[0]}
              entries={data.categories[0]}
              onSelect={(index, entry, originalIndex) =>
                selectCategory(1, index, originalIndex)
              }
              selectedIndex={data.filteredSelected[0]}
              showFilter={showFilter}
              foldable
            />
          </Paper>
        </HoverableBox>
        {showSubcategories && data.categories[1].length > 0 && (
          <HoverableBox minWidth={125} maxWidth={450} hoverable={hoverableCategory(1)} delay={150}>
            <Paper square={true} style={{ clipPath: 'inset(0px 0px 0px -10px)' }}>
              <DenseList
                offsetTop={offsetTop}
                onFilter={() => resetSelectedIndex(1)}
                title={headers[1]}
                entries={data.categories[1]}
                onSelect={(index, entry, originalIndex) =>
                  selectCategory(2, index, originalIndex)
                }
                selectedIndex={data.filteredSelected[1]}
                showFilter={showSecondaryFilter}
                foldable
              />
            </Paper>
          </HoverableBox>
        )}
        {showSubcategories && data.categories[2].length > 0 && (
          <HoverableBox minWidth={125} maxWidth={450} hoverable={hoverableCategory(2)} delay={150}>
            <Paper square={true} style={{ clipPath: 'inset(0px 0px 0px -10px)' }}>
              <DenseList
                offsetTop={offsetTop}
                onFilter={() => resetSelectedIndex(2)}
                title={headers[2]}
                entries={data.categories[2]}
                selectedIndex={data.filteredSelected[2]}
                onSelect={(index, entry, originalIndex) =>
                  selectCategory(3, index, originalIndex)
                }
                foldable
              />
            </Paper>
          </HoverableBox>
        )}
        {showSubcategories && data.categories[3].length > 0 && (
          <Box minWidth={125} maxWidth={450}>
            <Paper square={true} style={{ clipPath: 'inset(0px 0px 0px -10px)' }}>
              <DenseList
                offsetTop={offsetTop}
                onFilter={() => resetSelectedIndex(3)}
                title={headers[3]}
                entries={data.categories[3]}
                selectedIndex={data.filteredSelected[3]}
                onSelect={(index, entry, originalIndex) =>
                  selectCategory(4, index, originalIndex)
                }
              />
            </Paper>
          </Box>
        )}

        {menuOption === menuOptions.fragor_analyser && data.results.length > 0 && (
          <Box minWidth={250} width={showSubcategories ? '' : 'fill-available'}>
            <Paper square={true} style={{ clipPath: 'inset(0px 0px 0px -10px)' }}>
              <DenseList
                showFilter={showResultFilter}
                offsetTop={offsetTop}
                title="Träffar"
                entries={data.results}
                onSelect={(index, entry) => onSelectResult(entry)}
              />
            </Paper>
          </Box>
        )}

        {menuOption === menuOptions.rattsfall && !loadingSubCategory && (
          <StructuredSearchResult 
            specialEntries={data.x_results.specialEntries} 
            entries={data.x_results.entries} 
            allEntry={data.x_results.allEntry}
            showSubcategories={showSubcategories} 
            offsetTop={offsetTop}
            onSelectResult={onSelectResult}
          />
        )}

        {loadingSubCategory && (
          <Box maxWidth={400} mt={2} ml={2}>
            <Loading />
          </Box>
        )}
      </Stack>
    </Box>
  );
}

StructuredSearch.propTypes = {
  baseUrl: PropTypes.string,
  showFilter: PropTypes.bool,
  showSubcategories: PropTypes.bool,
  showSecondaryFilter: PropTypes.bool,
  showResultFilter: PropTypes.bool,
  onSelectResult: PropTypes.func,
  headers: PropTypes.array,
  ignoreSpecialEntries: PropTypes.bool,
  onSelectCategory: PropTypes.func,
  offsetTop: PropTypes.number,
  showAllCategoryOption: PropTypes.bool,
};

StructuredSearch.defaultProps = {
  showFilter: false,
  showResultFilter: false,
  showSubcategories: true,
  ignoreSpecialEntries: false,
  showAllCategoryOption: false,
  onSelectResult: () => {},
  onSelectCategory: () => {},
  offsetTop: 168,
};

export default StructuredSearch;
