import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useParams } from "react-router-dom";
import Sbt from "src/components/Sbt";
import SbtFullText from 'src/components/SbtFullText';


function SbtFullTextPrintPage() {
  let { id } = useParams();

  useEffect(() => {
    document.title = "Skriv Ut | Infosoc";
    setTimeout(() => {
      window.print();
    }, 1000)
  }, [id]);

  return (
    <Box sx={{ flexGrow: 1, marginTop: 3 }}  >
      <SbtFullText id={id} hideButtons={true}/>
    </Box>
  );
}

export default SbtFullTextPrintPage;
