import React from 'react';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function DialogClose({ onClose }) {

    return (
        <Box sx={{
            position: 'absolute',
            right: 12,
            top: 12
        }}>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    marginLeft: 2,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        </Box>
    )
}

export default DialogClose;