
export const DATE_OPTIONS = [' - Alla datum -', 'Senaste 30 dagarna', 'Senaste 60 dagarna', 'Senaste 90 dagarna', 'Senaste halvåret', 'Senaste året',
'Senaste 2 åren', 'Senaste 3 åren', 'Senaste 5 åren', 'Senaste 10 åren'
];

export const DAYS_IN_YEAR = 365;
export const DATE_OPTION_VALUES = [-1, 30, 60, 90, 182, DAYS_IN_YEAR, DAYS_IN_YEAR * 2, DAYS_IN_YEAR * 3, DAYS_IN_YEAR * 5, DAYS_IN_YEAR * 10];

export const WORD_MATCH_OPTIONS = ['Innehåller minst ett ord', 'Innehåller alla ord', 'Innehåller exakt fras'];
export const WORD_MATCH_VALUES = ['ett', 'alla', 'exakt'];

export const SORT_OPTIONS = ['Sortera efter relevans', 'Sortera efter datum'];
