import React, { useState } from "react";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import ManualIcon from '@mui/icons-material/VideocamOutlined';
import FaqIcon from '@mui/icons-material/QuestionAnswerOutlined';
import ContactUsDialog from "./ContactUsDialog";
import PreviewDialog from "./PreviewDialog";
import HelpPage from "src/pages/help/HelpPage";
import FaqPage from "src/pages/help/FaqPage";
import VideoFrame from "./VideoFrame";

import { Paper, Box, Typography, Stack, Link, Button } from '@mui/material';

function Footer() {

  const [showContactUsDialog, setShowContactUsDialog] = useState(false);
  const [showManual, setShowManual] = useState(false);
  const [showFaq, setShowFaq] = useState(false);

  return (
    <Paper sx={{
      zIndex: 2000,
      marginTop: 'calc(10% + 60px)',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      boxShadow: '0px 1px 11px 2px rgba(80,80,80,0.22)',
    }} component="footer" square variant="outlined">

      <Stack direction="row" spacing={1}>
        <Box mr={2} ml={2}><Typography style={{ marginTop: 4, fontWeight: 'bold' }} variant="subtitle1">Hjälp</Typography></Box>
        <Link href="tel:036-30 79 00" style={{textDecoration: 'none'}}>
          <Button color="secondary" startIcon={<PhoneIcon color="secondary" />} >036-30 79 00</Button>
        </Link>
        <Button color="secondary" onClick={() => setShowContactUsDialog(true)} startIcon={<EmailIcon color="secondary" />} >Kontakta oss</Button>
        <Button color="secondary" onClick={() => setShowManual(true)} startIcon={<ManualIcon color="secondary" />} >Användarmanual</Button>
        <Button color="secondary" onClick={() => setShowFaq(true)} startIcon={<FaqIcon color="secondary" />} >Vanliga frågor (FAQ)</Button>
      </Stack>

      <ContactUsDialog open={showContactUsDialog} onClose={() => { setShowContactUsDialog(false); }} initalOption='Juridisk rådgivning' />

      <PreviewDialog title='Användarmanual' maxWidth={'lg'} open={showManual} onClose={() => { setShowManual(false); }} link='/hjalp'>
       
          <VideoFrame src='https://infosoc-files-sthml.s3.eu-north-1.amazonaws.com/Filmer/Infosoc-Anva%CC%88ndarmanual-textad.mp4' title="Användarmanual" />
        
      </PreviewDialog>

      <PreviewDialog title='Vanliga frågor (FAQ)' open={showFaq} onClose={() => { setShowFaq(false); }} link='/faq'>
        <FaqPage />
      </PreviewDialog>

    </Paper>
  );
}

export default Footer;