import React from 'react';
import PropTypes from 'prop-types';
import FolderIcon from '@mui/icons-material/Folder';
import BookIcon from '@mui/icons-material/Book';
import CurrencyIcon from '@mui/icons-material/Euro';
import QuestionIcon from '@mui/icons-material/HelpOutline';
import GavelIcon from '@mui/icons-material/GavelRounded';
import AllIcon from '@mui/icons-material/ListOutlined';
import OrgIcon from '@mui/icons-material/People';
import ParagraphIcon from '@mui/icons-material/FormatAlignLeft';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import EmailIcon from '@mui/icons-material/Email';
import FormIcon from '@mui/icons-material/Assignment';
import InformationMaterialIcon from '@mui/icons-material/MenuBook';
import LegalCommentIcon from '@mui/icons-material/ChatOutlined';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import WordIcon from '@mui/icons-material/LocalPostOffice';
import UserIcon from '@mui/icons-material/Person';
import AdminIcon from '@mui/icons-material/Settings';
import DisabledIcon from '@mui/icons-material/PersonOff';
import StarIcon from '@mui/icons-material/Star';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import AnalysisIcon from '@mui/icons-material/LiveHelpOutlined';
import NewsletterIcon from '@mui/icons-material/EmailOutlined';
import LegalCommentsIcon from '@mui/icons-material/TextsmsOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import NoHitsIcon from '@mui/icons-material/Cancel';
import InvestigationIcon from '@mui/icons-material/ArticleOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SortIcon from '@mui/icons-material/SortOutlined';
import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import ArrowLeftIcon from '@mui/icons-material/ArrowBack';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';
import ExcludeIcon from '@mui/icons-material/Close';
import ReOrderIcon from '@mui/icons-material/MoveDown';
import LinkIcon from '@mui/icons-material/Link';
import AddIcon from '@mui/icons-material/Add';
import SvgIcon from '@mui/material/SvgIcon';
import ELitteratureIcon from '@mui/icons-material/TabletMac';

const ICON_MAP = {
	folder: { icon: FolderIcon, defaultColor: 'primary' },
	book: { icon: BookIcon, defaultColor: 'primary' },
	'currency-eur': { icon: CurrencyIcon, defaultColor: 'primary' },
	magnify: { icon: QuestionIcon, defaultColor: 'primary' },
	gavel: { icon: GavelIcon, defaultColor: 'primary' },
	'format-list-bulleted': { icon: AllIcon, defaultColor: 'primary' },
	fulltext: { icon: AllIcon, defaultColor: 'primary' },
	'account-multiple': { icon: OrgIcon, defaultColor: 'primary' },
	'format-section': { icon: ParagraphIcon, defaultColor: 'primary' },
	'book-open-variant': { icon: BookIcon, defaultColor: 'primary' },
	calendar: { icon: CalendarIcon, defaultColor: 'primary' },
	email: { icon: EmailIcon, defaultColor: 'primary' },
	'clipboard-text': { icon: FormIcon, defaultColor: 'primary' },
	form: { icon: FormIcon, defaultColor: 'primary' },
	'compass-outline': { icon: InformationMaterialIcon, defaultColor: 'primary' },
	'comment-processing-outline': { icon: LegalCommentIcon, defaultColor: 'primary' },
	pdf: { icon: PdfIcon, defaultColor: 'primary' },
	word: { icon: WordIcon, defaultColor: 'primary' },
	user: { icon: UserIcon, defaultColor: 'primary' },
	admin: { icon: AdminIcon, defaultColor: 'primary' },
	disabled: { icon: DisabledIcon, defaultColor: 'primary' },
	star: { icon: StarIcon, defaultColor: 'secondary' },
	notification: { icon: ErrorIcon, defaultColor: 'error' },
	'notification-confirmed': { icon: ErrorIcon, defaultColor: 'disabled' },
	delete: { icon: DeleteIcon, defaultColor: 'primary' },
	edit: { icon: EditIcon, defaultColor: 'primary' },
	analysis: { icon: AnalysisIcon, defaultColor: 'primary' },
	newsletter: { icon: NewsletterIcon, defaultColor: 'primary' },
	'email-outline': { icon: NewsletterIcon, defaultColor: 'primary' },
	legalcomment: { icon: LegalCommentsIcon, defaultColor: 'primary' },
	search: { icon: SearchIcon, defaultColor: 'primary' },
	attachment: { icon: PdfIcon, defaultColor: 'primary' },
	checkmark: { icon: CheckIcon, defaultColor: 'success' },
	'hits-no-match': { icon: NoHitsIcon, defaultColor: 'error' },
	investigation: { icon: InvestigationIcon, defaultColor: 'secondary' },
	'investigation-root-folder': { icon: FolderIcon, defaultColor: 'secondary' },
	'new-window': { icon: OpenInNewIcon, defaultColor: 'primary' },
	sort: { icon: SortIcon, defaultColor: 'primary' },
	arrowdown: { icon: ArrowDownIcon, defaultColor: 'primary' },
	arrowleft: { icon: ArrowLeftIcon, defaultColor: 'primary' },
	arrowright: { icon: ArrowRightIcon, defaultColor: 'primary' },
	exclude: { icon: ExcludeIcon, defaultColor: 'error' },
	reorder: { icon: ReOrderIcon, defaultColor: 'primary' },
	link: { icon: LinkIcon, defaultColor: 'primary' },
	add: { icon: AddIcon, defaultColor: 'primary' },
	ebook: { icon: ELitteratureIcon, defaultColor: 'primary' },
	sbt_plus: {
		icon: ({ color }) => (
			<SvgIcon htmlColor={color} viewBox='0 0 24 24'>
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M2 21H12C12.55 21 13 21.45 13 22C13 22.55 12.55 23 12 23H2C1.45 23 1 22.55 1 22C1 21.45 1.45 21 2 21ZM5.24 8.07L8.07 5.24L20.8 17.97C21.58 18.75 21.58 20.02 20.8 20.8C20.02 21.58 18.75 21.58 17.97 20.8L5.24 8.07ZM13.73 2.41L16.56 5.24C17.34 6.02 17.34 7.29 16.56 8.07L15.14 9.49L9.49 3.83L10.9 2.42C11.68 1.63 12.95 1.63 13.73 2.41ZM3.83 9.48L9.49 15.14L8.08 16.55C7.3 17.33 6.03 17.33 5.25 16.55L2.42 13.72C1.64 12.94 1.64 11.67 2.42 10.89L3.83 9.48Z'
						fill={color}
					/>
					<path
						d='M20 8.5C20 8.22386 20.2239 8 20.5 8V8C20.7761 8 21 8.22386 21 8.5V12.5C21 12.7761 20.7761 13 20.5 13V13C20.2239 13 20 12.7761 20 12.5V8.5Z'
						fill={color}
					/>
					<path
						d='M18 10.5C18 10.2239 18.2239 10 18.5 10H22.5C22.7761 10 23 10.2239 23 10.5V10.5C23 10.7761 22.7761 11 22.5 11H18.5C18.2239 11 18 10.7761 18 10.5V10.5Z'
						fill={color}
					/>
				</svg>
			</SvgIcon>
		),
		defaultColor: '#0e90d2',
	},
};

const InfosocIcon = ({ type, color, opacity, style, props }) => {
	const IconComponent = ICON_MAP[type] ? ICON_MAP[type].icon : null;
	const defaultColor = ICON_MAP[type] ? ICON_MAP[type].defaultColor : 'primary';
	const iconColor = color || defaultColor;
	const iconOpacity = opacity || 1;

	// Check if IconComponent is a function (for custom SVG components)
	if (typeof IconComponent === 'function') {
		return <IconComponent color={iconColor} style={{ opacity: iconOpacity, ...style }} {...props} />;
	}

	// For standard MUI icons, use React.createElement with style prop
	return IconComponent
		? React.createElement(IconComponent, { color: iconColor, style: { opacity: iconOpacity, ...style }})
		: null;
};

InfosocIcon.propTypes = {
	type: PropTypes.string.isRequired,
	color: PropTypes.string,
	opacity: PropTypes.number,
};

InfosocIcon.propTypes = {
	type: PropTypes.string.isRequired,
	color: PropTypes.string,
	opacity: PropTypes.number,
};

export default InfosocIcon;
