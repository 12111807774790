import React, { useState, useEffect } from 'react';
import { get, post } from '../services/http-service';
import { recursiveFind, recursiveFindDepth } from '../services/folder-utils';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FavoriteIcon from '@mui/icons-material/Star';
import { TreeItem, SimpleTreeView } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfosocIcon from './InfosocIcon';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import IconButton from '@mui/material/IconButton';
import AddNewFolder from './AddNewFolder';
function FavoriteDialog({ open, onClose, moveOriginFolderId }) {
	const [root, setRoot] = useState({ children: [], id: '-1', intId: -1 });
	const [selectedNodeId, setSelectedNodeId] = useState('-1');
	const [showCreateFolder, setShowCreateFolder] = useState(false);

	const load = async () => {
		let response = await get(`/favoritefolders/tree`);

		response.children = [{ id: 'C-1', intId: -1, name: 'Mina favoriter', children: [] }].concat(
			response.children
		);

		setRoot(response);
	};

	useEffect(() => {
		if (open) {
			load();
		}

		return () => {
			setShowCreateFolder(false);
			setSelectedNodeId('-1');
		};
	}, [open]);

	const renderTree = (node) => {
		if (!node.intId) {
			return <></>;
		}

		const renderIcon = (props) => {
			const { node } = props;

			if (node.intId === -1) {
				return <InfosocIcon type='star' />;
			}
			return null;
		}

		return (
			<TreeItem
				itemId={'' + node.intId}
				label={node.name}
				disabled={node.intId === moveOriginFolderId && node.children.length === 0}
				key={node.id}
				slots={{
					collapseIcon: ExpandMoreIcon,
					expandIcon: ChevronRightIcon,
					endIcon: renderIcon,
				}}

				slotProps={{
					endIcon: { node },
				}}
			>
				{node.children.map((child) => renderTree(child))}
			</TreeItem>
		);
	};

	const onSubmit = async () => {
		const selected = recursiveFind(root, parseInt(selectedNodeId));
		if (selected) {
			onClose(selectedNodeId, selected.name);
		}
	};

	const selectedDepth = recursiveFindDepth(root, parseInt(selectedNodeId));
	const allowCreateFolder = selectedDepth < 3;

	return (
		<Dialog open={open} scroll='paper' onClose={() => onClose()}>
			<DialogTitle id='scroll-dialog-title'>
				<Grid container direction='row' alignItems='center'>
					{moveOriginFolderId === 0 && (
						<Grid item>
							<Box mt={1} mr={1}>
								<FavoriteIcon color='secondary' />
							</Box>
						</Grid>
					)}
					<Grid item color='secondary'>
						<Typography variant='h6' color='secondary'>
							{moveOriginFolderId === 0 ? 'Spara i favoriter' : 'Flytta favoriter till katalog'}
						</Typography>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent dividers sx={{ scrollbarGutter: 'stable' }}>
				{root && root.hasOwnProperty('children') && (
					<SimpleTreeView
						defaultSelected={'-1'}
						onItemFocus={(args, nodeId) => setSelectedNodeId(`${nodeId}`)}		
						sx={{ minHeight: 240, maxHeight: 400, flexGrow: 1, width: 400 }}
					>
						{root.children.map((c) => renderTree(c))}
					</SimpleTreeView>
				)}
			</DialogContent>
			{showCreateFolder && allowCreateFolder && (
				<DialogContent dividers>
					<AddNewFolder
						parentFolder={recursiveFind(root, parseInt(selectedNodeId))}
						type='favorite'
						onComplete={(parentId) => {
							setShowCreateFolder(false);
							load();
							setSelectedNodeId(`${parentId}`);
						}}
					/>
				</DialogContent>
			)}
			<DialogActions>
				<IconButton
					variant='contained'
					onClick={() => setShowCreateFolder(!showCreateFolder)}
					color='primary'
					title='Skapa ny katalog'
					disabled={!allowCreateFolder}
				>
					<CreateNewFolderIcon />
				</IconButton>
				<div style={{ flex: '1 0 0' }} />
				<Button variant='contained' disabled={!selectedNodeId} onClick={onSubmit}>
					{moveOriginFolderId === 0 ? 'Spara' : 'Flytta'}
				</Button>
				<Button onClick={() => onClose()}>Avbryt</Button>
			</DialogActions>
		</Dialog>
	);
}

FavoriteDialog.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool,
	moveOriginFolderId: PropTypes.number,
};

FavoriteDialog.defaultProps = {
	onClose: () => {},
	open: false,
	moveOriginFolderId: 0,
};

export default FavoriteDialog;
