import React, { useState, useEffect } from 'react';
import { post } from '../services/http-service';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import DialogClose from './DialogClose';
import { toast } from 'react-toastify';
import { Typography, Link } from '@mui/material';

const FIELDS = [
	{
		key: 'email',
		label: 'E-postadress',
		required: true,
		value: '',
		valid: null,
	},
	{
		key: 'firstname',
		label: 'Förnamn',
		required: true,
		value: '',
		valid: null,
	},
	{
		key: 'lastname',
		label: 'Efternamn',
		required: true,
		value: '',
		valid: null,
	},
	{
		key: 'company',
		label: 'Företag/Organisation',
		required: true,
		value: '',
		valid: null,
	},
	{ key: 'department', label: 'Avdelning', value: '', valid: null },
];

function RequestLoginDialog({ open, onClose, showForgotPassword, onError }) {
	const [errorMessage, setErrorMessage] = useState('');

	const [fields, setFields] = useState(FIELDS);

	useEffect(() => {
		setErrorMessage('');
	}, [open]);

	const changeField = (index, value) => {
		const newFields = [...fields];

		newFields[index].value = value;

		setFields(newFields);
	};

	const validateFields = () => {
		let tempFields = [...fields];
		tempFields.forEach((field) => {
			field.valid =
				!field.required || (field.required && field.value.length > 0);
		});

		setFields(tempFields);

		const isValid = tempFields.every((field) => field.valid);
		let errorMessage = '';

		if (!isValid) {
			errorMessage = 'Vänligen fyll i alla obligatoriska fält';
		}

		setErrorMessage(errorMessage);

		return isValid;
	};

	const onSubmit = async () => {
		if (!validateFields()) {
			return;
		}

		const form = new FormData();

		fields.forEach((f) => {
			form.append(f.key, f.value);
		});

		const response = await post('/login/requestIndividual', form);

		if (response.valid) {
			toast.success(
				<Typography variant="body1" gutterBottom sx={{ minWidth: 200 }}>
					Ditt personliga konto är nu beställt! <br />
					Vi skickar instruktioner till din e-postadress så snart som
					möjligt.
				</Typography>,
				{
					autoClose: 7500,
				}
			);

			setErrorMessage('');
			onClose();
		} else {
			let message = 'Något gick fel. Vänligen försök igen.';
			if (response.message === 'already_exist') {
				message = (
					<Typography variant="body1" gutterBottom>
						Kontot finns redan registerat, vänligen se{' '}
						<Link href="/glomtlosen">glömt lösenord</Link> för att
						återställa ditt konto.
					</Typography>
				);
			}

			toast.warn(message, {
				autoClose: 7500,
			});

			onClose();
		}
	};

	return (
		<Dialog
			open={open}
			scroll="paper"
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle id="scroll-dialog-title">
				Beställ personlig inloggning
			</DialogTitle>
			<DialogClose onClose={onClose} />

			<Box width={600}>
				<DialogContent dividers>
					{errorMessage && (
						<Box mb={2}>
							<Alert variant="filled" severity="error">
								{errorMessage}
							</Alert>
						</Box>
					)}

					<FormGroup>
						{fields.map((field, index) => (
							<Box key={index}>
								<TextField
									onChange={(e) =>
										changeField(index, e.target.value)
									}
									value={field.value}
									margin="normal"
									required={field.required}
									fullWidth
									label={field.label}
									error={field.valid === false}
								/>
							</Box>
						))}
					</FormGroup>
				</DialogContent>
			</Box>

			<DialogActions>
				<Button variant="contained" onClick={onSubmit}>
					Skicka
				</Button>

				<Button onClick={onClose}>Avbryt</Button>
			</DialogActions>
		</Dialog>
	);
}

RequestLoginDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
};

export default RequestLoginDialog;
