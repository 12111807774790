import React, { useState, useEffect } from 'react';
import { post } from '../services/http-service';
import {
	Button,
	Box,
	InputLabel,
	Select,
	Dialog,
	ListItemText,
	MenuItem,
	OutlinedInput,
	FormControl,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import storageService from 'src/services/storage-service';
import Alert from '@mui/material/Alert';
import DialogClose from './DialogClose';

const FIELDS = [
	{ key: 'company', label: 'Kommun/företag', required: true, value: '' },
	{ key: 'email', label: 'E-postadress', required: true, value: '' },
	{ key: 'unit', label: 'Förvaltning/enhet', value: '' },
	{ key: 'name', label: 'Namn', value: '' },
	{ key: 'phone', label: 'Telefon', value: '' },
	{ key: 'users', label: 'Antal användare', value: '' },
	{ key: 'orgLicense', label: 'Förvaltningslicens', checkbox: true, value: false },
	{ key: 'freeTrial', label: 'Prova gratis', checkbox: true, value: false },
];

function OfferDialog({ open, onClose, title, selected = '', items = [] }) {
	const [errorMessage, setErrorMessage] = useState('');
	const [fields, setFields] = useState(FIELDS);
	const [selectedItems, setSelectedItems] = useState([]);

	useEffect(() => {
		setErrorMessage('');
		const user = storageService.load();
		FIELDS[0].value = user.organizationName;
		FIELDS[1].value = user.email.indexOf('@') > 0 ? user.email : '';
		FIELDS[3].value = user.displayName;

		// Default selected item
		if (selected && selected.length > 0) {
			setSelectedItems([selected]);
		} else if (items && items.length > 0) {
			setSelectedItems([items[0]]);
		}
	}, [open]); // eslint-disable-line react-hooks/exhaustive-deps

	const changeField = (index, value) => {
		const newFields = [...fields];
		newFields[index].value = value;
		setFields(newFields);
	};

	const updateSelectedItems = (event) => {
		const {
			target: { value },
		} = event;

		setSelectedItems(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		);
	};

	const onSubmit = async () => {
		if (selectedItems.length === 0) {
			return setErrorMessage(`Val av ${title.toLowerCase()} är obligatoriskt`);
		}

		for (let i = 0; i < 2; i++) {
			if (!fields[i].value) {
				return setErrorMessage(`${FIELDS[i].label} är obligatoriskt`);
			}
		}

		const form = new FormData();

		form.append('product.name', title);

		selectedItems.forEach((item, index) => {
			form.append(`product.items[${index}]`, item);
		});

		fields.forEach((f) => {
			form.append(f.key, f.value);
		});

		await post('/quote/new', form, true);

		setErrorMessage('');
		onClose(true);
	};

	if (!open) {
		return null;
	}

	// Capitalize first letter
	title = title.charAt(0).toUpperCase() + title.slice(1);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			scroll='paper'
			aria-labelledby='scroll-dialog-title'
			aria-describedby='scroll-dialog-description'
			maxWidth='sm'
			fullWidth
			sx={{
				marginBottom: '24px',
			}}
		>
			<DialogTitle id='scroll-dialog-title'>Offert för {title.toLowerCase()}</DialogTitle>

			<DialogClose onClose={onClose} />

			<DialogContent dividers>
				{errorMessage && (
					<Box mb={2}>
						<Alert variant='filled' severity='error'>
							{errorMessage}
						</Alert>
					</Box>
				)}

				<FormGroup>
					{items.length > 0 && (
						<FormControl sx={{ mt: 2, mb: 1, width: '100%' }}>
							<InputLabel id='offer-dialog-product-items-label'>{`${title} *`}</InputLabel>
							<Select
								labelId='offer-dialog-product-items-label'
								multiple
								required
								disabled={items.length === 1}
								value={selectedItems}
								onChange={updateSelectedItems}
								input={<OutlinedInput label={`${title} *`} />}
								renderValue={(selected) => selected.join(', ')}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 40 * 7.5 + 8,
										},
									},
								}}
								sx={{
									'.MuiSelect-select': {
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
									},
								}}
							>
								{items.map((item) => (
									<MenuItem key={item} value={item} sx={{ height: 40 }}>
										<Checkbox checked={selectedItems.indexOf(item) > -1} />
										<ListItemText primary={item} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					{fields.map((field, index) => (
						<Box key={index}>
							{!field.checkbox && (
								<TextField
									onChange={(e) => changeField(index, e.target.value)}
									value={field.value}
									variant='outlined'
									size='medium'
									margin='normal'
									required={field.required}
									fullWidth
									label={field.label}
								/>
							)}
							{field.checkbox && (
								<FormControlLabel
									key={'B' + index}
									control={
										<Checkbox
											checked={field.value}
											onChange={(event) => changeField(index, event.target.checked)}
										/>
									}
									label={field.label}
								/>
							)}
						</Box>
					))}
				</FormGroup>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={onSubmit}>
					Beställ
				</Button>
				<Button onClick={onClose}>Avbryt</Button>
			</DialogActions>
		</Dialog>
	);
}

OfferDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	title: PropTypes.string,
	selected: PropTypes.string,
	items: PropTypes.array,
};

export default OfferDialog;
