
import { createTheme } from '@mui/material/styles';

export default createTheme({
    palette: {
      primary: {
        main: '#0e90d2'
      },
      secondary: {
        main: '#e68125'
      }
    }
  });