
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { post } from "src/services/http-service";
import storageService from "src/services/storage-service";
import { useNavigate } from 'react-router-dom';
import Loading from "src/components/Loading";

function AutoLoginPage({ onSuccess }) {

    const [search] = useSearchParams();
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState(null);
    const licenseKey = search.get('licensNyckel') || search.get('licensnyckel') || search.get('licensekey');

    const load = async () => {
        let form = new FormData();

        form.append('licenseKey', licenseKey);
        let loginResponse = await post('/login', form);

        if (loginResponse.status === 'success') {
            storageService.save(loginResponse);
            onSuccess();
            navigate("/");
        }
        else {
            setErrorMessage('Inloggningen misslyckades. Kontrollera att du skrivit in din licensnyckel korrekt.');
        }
    }

    useEffect(() => {
        load();
    }, licenseKey);


    if(!errorMessage){
        return <Loading />
    }

    return (
        <Box m={4}>
            <Typography variant="h6">{errorMessage}</Typography>
        </Box>

    )
}

export default AutoLoginPage;