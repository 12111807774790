import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import HeaderIcon from '../../components/HeaderIcon';
import TipsIcon from '@mui/icons-material/LightbulbOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';

function Tips({ entries, loading, onSelected }) {

  return (
    <Paper variant="outlined" style={{boxShadow : 'rgb(200 200 200 / 50%) 1px 2px 25px 2px'}}>
      <Box mt={2}>
        <Box ml={2} mr={2}>
          <HeaderIcon text="Juristen tipsar" icon={<TipsIcon color="secondary" />} tooltip={<Box ml={1}>Juristen tipsar förser dig som användare med genvägar till extra intressant och aktuellt material i rättsdatabasen som våra experter lyft fram. Det kan handla om en ny analys eller fråga, ett nytt eller gammalt men likväl intressant rättsfall eller en lagkommentar som experten av någon anledning velat belysa extra.</Box>} />
        </Box>
        <nav aria-label="Juristen tipsar">
          <List dense>

            {loading && (<Box ml={2} mr={2}>
              <Skeleton variant="text" height={40} />
              <Skeleton variant="text" height={40} />
              <Skeleton variant="text" height={40} />
              <Skeleton variant="text" height={40} />
            </Box>)}

            {entries.map((entry) => (
              <ListItem disablePadding key={entry.title} onClick={() => onSelected(entry.id, entry.title)}>
                <ListItemButton>
                  <ListItemText disableTypography
                    primary={<Typography variant="body2" color="primary">{entry.title}</Typography>} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </nav>
      </Box>
    </Paper>
  );
}


Tips.propTypes = {
  entries: PropTypes.array,
  loading: PropTypes.bool,
  onSelected: PropTypes.func,
};

export default Tips;
