import {
    Routes,
    Route,
    NavLink,
    Navigate,
    useNavigate,
} from 'react-router-dom';

import storageService from 'src/services/storage-service';

const ProtectedRoute = ({ children }) => {
    const login = storageService.load();

    if (!login.accessKey) {
        return <Navigate to="/loggain" replace />;
    }

    return children;
};

export default ProtectedRoute;