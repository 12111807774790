import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Toolbar } from '@mui/material/';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import SbtIcon from '@mui/icons-material/GavelRounded';
import SblIcon from '@mui/icons-material/Book';
import LegalCommentsIcon from '@mui/icons-material/TextsmsOutlined';
import QuestionIcon from '@mui/icons-material/LiveHelpOutlined';
import NewsletterIcon from '@mui/icons-material/EmailOutlined';
import FormIcon from '@mui/icons-material/Assignment';
import InformationMaterialIcon from '@mui/icons-material/MenuBook';
import FavoriteIcon from '@mui/icons-material/Star';
import InvestigationIcon from '@mui/icons-material/ArticleOutlined';
import WatchlistIcon from '@mui/icons-material/Notifications';
import UserIcon from '@mui/icons-material/AccountCircle';
import HeaderIcon from './HeaderIcon';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useLocation } from 'react-router-dom';
import OfferDialog from './OfferDialog';

const drawerWidth = 230;

const MAIN_MENU = {
	text: 'Meny',
	tooltip: (
		<Box ml={1}>
			Under Menyn navigerar du dig fram bland basens olika delar; Rättsfall, Lagar, Lagkommentarer,
			E-litteratur, Frågor & analyser, Nyhetsbrev, Blanketter och Informationsmaterial.
		</Box>
	),
	items: [
		{ label: 'Start', icon: <HomeIcon color='primary' />, path: '/' },
		{
			label: 'Rättsfall',
			icon: <SbtIcon color='primary' />,
			path: '/rattsfall',
			plusSign: 'featureSbtPlus',
		},
		{ label: 'Lagar', icon: <SblIcon color='primary' />, path: '/lagar' },
		{
			label: 'Lagkommentarer',
			icon: <LegalCommentsIcon color='primary' />,
			path: '/lagkommentarer',
			feature: 'featureLegalComments',
		},
		{
			label: 'Frågor & Analyser',
			icon: <QuestionIcon color='primary' />,
			path: '/fragor-analyser',
		},
		{ label: 'Nyhetsbrev', icon: <NewsletterIcon color='primary' />, path: '/nyhetsbrev' },
		{ label: 'Blanketter', icon: <FormIcon color='primary' />, path: '/blanketter' },
		{
			label: 'Informationsmaterial',
			icon: <InformationMaterialIcon color='primary' />,
			path: '/informationsmaterial',
			mustHave: 'hasInformationMaterialAccess',
		},
	],
};

const MY_TOOLS_MENU = {
	text: 'Mina verktyg',
	tooltip: (
		<Box ml={1}>
			<Box mt={2}>
				I <b>Favoriter</b> kan du skapa mappar för att kunna spara ner allt material från
				rättsdatabasen så att du snabbare hittar det nästa gång du loggar in. Du kan även skapa
				undermappar i olika nivåer för att strukturera upp ditt innehåll bättre.
			</Box>
			<Box mt={2}>
				<b>Utredningar</b> gör det möjligt att skapa egna utredningar i basen genom att kopiera
				valfri text från rättsdatabasen olika delar och sammanställa i ett och samma dokument. Lägg
				därtill att du har möjlighet att komplettera detta dokument med egenförfattad text.
			</Box>
			<Box mt={2}>
				<b>Bevakningar</b> möjliggör dels en lagbevakningsfunktion som innebär att man framöver får
				notiser inne i rättsdatabasen och dessutom kan få e-post från Infosoc när bevakad lag eller
				lagrum får ny lydelse. Dels en rättsfallsbevakningsfunktion som innebär att man framöver får
				notiser inne i rättsdatabasen och dessutom kan få e-post när det kommer ett nytt rättsfall
				på valt tema eller lagrum.
			</Box>
		</Box>
	),
	items: [
		{ label: 'Favoriter', icon: <FavoriteIcon color='secondary' />, path: '/favoriter' },
		{ label: 'Utredningar', icon: <InvestigationIcon color='secondary' />, path: '/utredningar' },
		{
			label: 'Bevakningar',
			icon: <WatchlistIcon color='secondary' />,
			path: '/bevakningar',
			count: 'totalWatchListNotificationCount',
		},
		{
			label: 'Användare',
			icon: <UserIcon color='secondary' />,
			path: '/anvandare',
			mustHave: 'admin',
		},
	],
};

function LeftMenu({ currentUser }) {
	const { pathname } = useLocation();

	const theme = useTheme();
	const [miniMode, setMiniMode] = useState(false);
	const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const showMiniMenu = miniMode ? miniMode : smallScreen;
	const width = showMiniMenu ? 56 : drawerWidth;

	const [offerDialogSelected, setOfferDialogSelected] = useState(null);

	const isAllowedMenuItem = (menuItem) => {
		return !menuItem.feature || !currentUser || currentUser[menuItem.feature];
	};

	const toggleMiniMode = () => {
		setMiniMode(!miniMode);
	};

	const getSelected = (input) => <span style={{ fontWeight: 'bold' }}>{input}</span>;

	const getText = (menuItem, selected) => {
		if (isAllowedMenuItem(menuItem)) {
			let countText = '';

			if (menuItem.count && currentUser && currentUser[menuItem.count] > 0) {
				countText = ` (${currentUser[menuItem.count]})`;
			}

			if (selected) {
				return getSelected(menuItem.label + countText);
			}

			return menuItem.label + countText;
		}

		return <span style={{ color: '#999' }}>{menuItem.label}</span>;
	};

	const click = (event, menuItem) => {
		if (!isAllowedMenuItem(menuItem)) {
			event.preventDefault();
			setOfferDialogSelected(menuItem.label);
		}
	};

	const renderList = (menu, showMiniMenu, pathname) => (
		<List dense>
			{!showMiniMenu && (
				<ListItem>
					<HeaderIcon text={menu.text} tooltip={menu.tooltip} />
				</ListItem>
			)}

			{menu.items
				.filter((m) => !currentUser || !m.mustHave || currentUser[m.mustHave])
				.map((menuItem) => (
					<ListItem key={menuItem.label} disablePadding>
						<ListItemButton
							selected={menuItem.path === pathname}
							onClick={(event) => click(event, menuItem)}
							component={Link}
							to={menuItem.path}
						>
							<ListItemIcon>
								{showMiniMenu && (
									<Tooltip
										arrow
										title={<span style={{ fontSize: '20px' }}>{menuItem.label}</span>}
										placement='right'
									>
										{menuItem.icon}
									</Tooltip>
								)}

								{!showMiniMenu && menuItem.icon}
							</ListItemIcon>

							{!showMiniMenu && (
								<Box>
									<ListItemText
										color=''
										primary={getText(menuItem, menuItem.path === pathname)}
										selected={menuItem.path === pathname}
									/>
								</Box>
							)}
						</ListItemButton>
					</ListItem>
				))}
		</List>
	);

	return (
		<Drawer
			open={false}
			variant='permanent'
			sx={{
				width,
				flexShrink: 0,
				[`& .MuiDrawer-paper`]: { width, boxSizing: 'border-box' },
			}}
		>
			<Toolbar />
			<Box sx={{ overflowY: 'auto', overflowX: 'hidden' }} mt={pathname === '/sokning' ? 4 : 0}>
				{renderList(MAIN_MENU, showMiniMenu, pathname)}
				<Divider />
				{renderList(MY_TOOLS_MENU, showMiniMenu, pathname)}
			</Box>

			<OfferDialog
				open={offerDialogSelected !== null}
				onClose={() => setOfferDialogSelected(null)}
				title={offerDialogSelected}
				selected={offerDialogSelected}
			/>

			<Box position={'fixed'} bottom={0} ml={1} paddingBottom={6}>
				<Tooltip
					arrow
					placement='right'
					title={
						<span style={{ fontSize: '20px' }}>
							{miniMode ? 'Visa fullständig meny' : 'Förminska meny'}
						</span>
					}
				>
					<IconButton onClick={toggleMiniMode}>
						{miniMode ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</Tooltip>
			</Box>
		</Drawer>
	);
}

export default LeftMenu;
