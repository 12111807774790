import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Stack, Box, AppBar, Paper, TextField, Checkbox, Typography, FormControlLabel, Button, Alert, Badge, Divider, Toolbar } from '@mui/material';
import { get } from "src/services/http-service";
import DenseList from 'src/components/DenseList';
import Sbt from 'src/components/Sbt';
import Newsletter from 'src/components/Newsletter';
import { useNavigate } from 'react-router-dom';
import { post } from "src/services/http-service";
import AlarmIcon from '@mui/icons-material/Error';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PreviewDialog from 'src/components/PreviewDialog';
import InfosocIcon from 'src/components/InfosocIcon';
import { ConstructionOutlined } from '@mui/icons-material';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { toast } from 'react-toastify';
import EditSblWatchListEntryDialog from 'src/components/EditSblWatchListEntryDialog';
import AddWatchDialog from 'src/components/AddWatchDialog';

function WatchlistPage() {

  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState('sbt');
  const [previewSbtId, setPreviewSbtId] = useState(-1);

  const [categoryEntries, setCategoryEntries] = useState([]);
  const [lawplaceEntries, setLawplaceEntries] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const [sblLawPlaces, setSblLawPlaces] = useState([]);

  const [selectedCategoryId, setSelectedCategoryId] = useState(-1);
  const [selectedLawplaceEntryId, setSelectedLawplaceEntryId] = useState(-1);
  const [selectedNotificationId, setSelectedNotificationId] = useState(-1);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [emailMonitor, setEmailMonitor] = useState(false);

  const [selectedSblLawplaceEntryId, setSelectedSblLawplaceEntryId] = useState(-1);

  const [selectedWatchlist, setSelectedWatchlist] = useState(null);

  const [editWatchListEntry, setEditwatchListEntry] = useState(null);

  const [showAddWatchDialog, setShowAddWatchDialog] = useState(false);

  document.title = 'Bevakningar | Infosoc';

  const load = async () => {
    const response = await get('/watchlist/root');
    setTabs(response.entries);

    loadFolders(response.entries.length === 2 ? 'sbt' : 'sba');
  }

  useEffect(() => {
    load();
  }, []);

  const loadFolders = async (tab) => {

    if (tab === 'sbt') {
      const sbtResponse = await get('/watchlist/sbt');

      setCategoryEntries(sbtResponse.categoryEntries);
      setLawplaceEntries(sbtResponse.lawplaceEntries);
      setNotifications(sbtResponse.notifications);
    }
    else {
      const sblResponse = await get('/watchlist/sbl');
      setSblLawPlaces(sblResponse.entries);
    }

    setSelectedTab(tab);
  }

  const selectCategory = async (categoryId) => {
    setSelectedCategoryId(categoryId);
    setSelectedLawplaceEntryId(-1);

    const tokens = categoryId.split('-');

    const response = await get(`/watchlist/sbt/category/${tokens[1]}/notifications`);
    setNotifications(response.notifications);

    setEmailMonitor(response.sendEmail);
  }

  const selectLawPlaceEntry = async (id) => {
    setSelectedLawplaceEntryId(id);
    setSelectedCategoryId(-1);

    const tokens = id.split('-');

    const response = await get(`/watchlist/sbt/lawplace/${tokens[1]}/notifications`);
    setNotifications(response.notifications);

    setEmailMonitor(response.sendEmail);
  }

  const selectNotification = async (notification) => {
    setPreviewSbtId(notification.sbtDocumentId);
    setSelectedNotificationId(notification.id);

    if (!notification.confirmed) {
      await post(`/watchlist/notifications/${notification.id}/confirm`);
      await reload();
    }
  }

  const reload = async () => {
    const response = await get('/watchlist/root');
    setTabs(response.entries);

    if (selectedTab === 'sbt') {
      await loadFolders(selectedTab);
    }

    if (selectedCategoryId !== -1) {
      await selectCategory(selectedCategoryId);
    }

    if (selectedLawplaceEntryId !== -1) {
      await selectLawPlaceEntry(selectedLawplaceEntryId);
    }
  }

  const changeType = async (tab) => {
    loadFolders(tab);
  }

  const deleteNotification = async (event, notification) => {
    event.stopPropagation();
    await post(`/watchlist/notifications/${notification.id}/delete`, {}, true);
    await reload();
  }

  const getTitle = () => {

    if (selectedTab === 'sba' && selectedWatchlist) {
      return `${selectedWatchlist.sblDocumentTitle} ${selectedWatchlist.link}`;
    }

    if (selectedCategoryId !== -1) {
      return categoryEntries.filter(c => c.id === selectedCategoryId)[0].name;
    }
    else if (selectedLawplaceEntryId !== -1) {
      return lawplaceEntries.filter(c => c.id === selectedLawplaceEntryId)[0].name;
    }
  }

  const deleteFolder = async () => {
    if (selectedTab === 'sba') {
      await post(`/watchlist/sbt/lawplace/${selectedWatchlist.id}/delete`, {}, true);
      setSelectedWatchlist(null);
      setSelectedSblLawplaceEntryId(-1);
      toast.success('Bevakningen raderad');
    }
    else {
      if (selectedCategoryId !== -1) {
        await post(`/watchlist/sbt/category/${selectedCategoryId.split('-')[1]}/delete`, {}, true);
        setSelectedCategoryId(-1);
      }
      else if (selectedLawplaceEntryId !== -1) {
        await post(`/watchlist/sbt/lawplace/${selectedLawplaceEntryId.split('-')[1]}/delete`, {}, true);
        setSelectedLawplaceEntryId(-1);
      }
    }

    const response = await get('/watchlist/root');
    setTabs(response.entries);

    await loadFolders(selectedTab);
  }

  const toggleEmailMonitor = async (checked) => {

    setEmailMonitor(checked);

    if (selectedCategoryId !== -1) {
      await post(`/watchlist/sbt/category/${selectedCategoryId.split('-')[1]}/email/${checked}`, {}, true);
    }
    else {
      await post(`/watchlist/sbt/lawplace/${selectedLawplaceEntryId.split('-')[1]}/email/${checked}`, {}, true);
    }

    toast.success(checked ? 'Du bevakar nu via e-post' : 'E-post bevakning borttagen');
  }

  const selectSblLawPlaceEntry = async (id) => {
    const response = await get(`/watchlist/${id.split('-')[1]}`);
    setSelectedWatchlist(response);
    setSelectedSblLawplaceEntryId(id);
  }

  const getNotificationTitle = (notification) => {
    return <Box mb={1}>
      <Stack direction="row">
        <Box pt={0.5} pr={1}><InfosocIcon type={notification.confirmed ? 'notification-confirmed' : 'notification'} /></Box>
        <Typography variant="h6">{notification.title}</Typography>
      </Stack>
      <Typography variant="subtitle">{notification.subTitle}</Typography>
    </Box>;
  }

  const getNotificationContent = (notification) => {

    return <Box>
      <Typography variant="subtitle">{notification.preamble}</Typography>
      <Box mt={1}>
        <Stack direction="row">
          <Box ><InfosocIcon type={notification.labelIcon} /></Box>
          <Box pt={0.2} pl={1} sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle">{notification.labelTitle}</Typography>
          </Box>
          <Button size='small' variant="outlined" onClick={(event) => deleteNotification(event, notification)}>Radera</Button>
        </Stack>
      </Box>
    </Box>;

  }

  const updateWatchListEntry = (entry) => {
    if (entry) {
      setSelectedWatchlist(entry);
    }
    setEditwatchListEntry(null);
  }

  const closeAddDialog = (type) => {
    setShowAddWatchDialog(false);
    if (type) {
      loadFolders(type);
    }
  }

  const hasSelectedArea = selectedCategoryId !== -1 || selectedLawplaceEntryId !== -1;

  const watchlistFields = [
    { key: 'description', label: 'Beskrivning' },
    { key: 'organization', label: 'Avdelning' },
    { key: 'responsible', label: 'Ansvarig' },
    { key: 'routines', label: 'Rutin' }];


  return (
    <Box mt={10} ml={2} >

      <Tabs value={selectedTab} onChange={(event, newValue) => changeType(newValue)}>
        {tabs.map((t, index) => (
          <Tab key={index} label={<Badge badgeContent={t.count} color="error"><Box pr={2}>{t.name}</Box></Badge>} value={t.id} />
        ))}
      </Tabs>

      <Box mt={2}>
        <Stack direction="row">
          <Button startIcon={<InfosocIcon type="add" />} variant="outlined" onClick={() => setShowAddWatchDialog(true)}>Ny bevakning</Button>
        </Stack>
      </Box>

      <Stack direction="row">

        {selectedTab === 'sbt' && <>

          <Box mt={1} sx={{ width: 350 }} mb={10}>
            <Paper >
              <Box pt={2} ml={2}>
                <Typography variant="subtitle1"><b>Teman</b></Typography>
              </Box>
              <List component="nav" dense>
                {categoryEntries.map((entry, index) => (
                  <ListItemButton
                    key={index}
                    selected={selectedCategoryId === entry.id}
                    onClick={() => selectCategory(entry.id)}
                  >
                    <ListItemIcon>
                      <InfosocIcon type={entry.icon} />
                    </ListItemIcon>
                    <ListItemText primary={<Badge badgeContent={entry.count} color="error">
                      <Box pr={2}>{entry.name}</Box>
                    </Badge>} />

                  </ListItemButton>
                ))}
              </List>
              <Box pt={1} ml={2}>
                <Typography variant="subtitle1"><b>Lagrum</b></Typography>
              </Box>
              <List component="nav" dense>
                {lawplaceEntries.map((entry, index) => (
                  <ListItemButton
                    key={index}
                    selected={selectedLawplaceEntryId === entry.id}
                    onClick={() => selectLawPlaceEntry(entry.id)}
                  >
                    <ListItemIcon>
                      <InfosocIcon type={entry.icon} />
                    </ListItemIcon>
                    <ListItemText primary={<Badge badgeContent={entry.count} color="error">
                      <Box pr={2}>{entry.name}</Box>
                    </Badge>} />

                  </ListItemButton>
                ))}
              </List>

            </Paper>
          </Box>



          <Box sx={{ width: 1 }}>
            <Box>
              {hasSelectedArea && <Box ml={8} mt={2} mr={2}>
                <Stack direction="row">
                  <Box pt={0.5} pr={1}><InfosocIcon type="folder" /></Box>
                  <Typography variant="h6">{getTitle()}</Typography>
                </Stack>
                <Stack direction="row">
                  <Box pt={0.2} pl={0} sx={{ flexGrow: 1 }}>
                    <FormControlLabel control={<Checkbox checked={emailMonitor} onChange={(event) => toggleEmailMonitor(event.target.checked)} />} label="Bevaka via e-post" />
                  </Box>
                  <Button startIcon={<InfosocIcon type="delete" />} variant="outlined" onClick={() => setShowConfirmDialog(true)}>Sluta bevaka</Button>
                </Stack>
              </Box>
              }

              {notifications.length === 0 && (selectedCategoryId !== -1 || selectedLawplaceEntryId !== -1) && <Box ml={8} mt={2}>
                <Typography style={{ color: '#666' }} variant="subtitle2">Inga nya rättsfall har kommit in under valt {selectedCategoryId === -1 ? 'lagrum' : 'tema'}</Typography>
              </Box>
              }

              <List component="nav" >
                {notifications.map((notification, index) => (
                  <ListItemButton
                    key={index}
                    selected={selectedNotificationId === notification.id}
                    onClick={() => selectNotification(notification)}
                  >
                    <ListItemIcon>
                      <InfosocIcon type={'alert'} />
                    </ListItemIcon>
                    <ListItemText primary={getNotificationTitle(notification)} secondary={getNotificationContent(notification)} />

                  </ListItemButton>
                ))}
              </List>
            </Box>
          </Box>
        </>
        }

        {selectedTab === 'sba' && <>
          <Paper>
            <Box mt={1} sx={{ width: 350 }}>
              <Box pt={1} ml={2}>
                <Typography variant="subtitle1"><b>Lagrum</b></Typography>
              </Box>
              <List component="nav" dense>
                {sblLawPlaces.map((entry, index) => (
                  <ListItemButton
                    key={index}
                    selected={selectedSblLawplaceEntryId === entry.id}
                    onClick={() => selectSblLawPlaceEntry(entry.id)}
                  >
                    <ListItemIcon>
                      <InfosocIcon type={entry.icon} />
                    </ListItemIcon>
                    <ListItemText primary={entry.name} />
                  </ListItemButton>
                ))}
              </List>

            </Box>
          </Paper>
          {selectedWatchlist && <Box sx={{ width: 0.8 }} ml={4} mt={2}>
            <Box ml={8} mt={2} mr={2}>
              <Stack direction="row">
                <Box pt={0.5} pr={1}><InfosocIcon type="book" /></Box>
                <Typography flexGrow={1} variant="h6">{getTitle()}</Typography>
              </Stack>
              <Box mt={1} mb={2}>
                <Stack direction="row" spacing={2}>
                  <Button startIcon={<InfosocIcon type="edit" />} variant="outlined" onClick={() => setEditwatchListEntry(selectedWatchlist)}>Redigera</Button>
                  <Button startIcon={<InfosocIcon type="delete" />} variant="outlined" onClick={() => setShowConfirmDialog(true)}>Sluta bevaka</Button>
                </Stack>
              </Box>
              <Divider />
              {selectedWatchlist.lastChanged && <Box mt={2}>
                <Stack direction="row">
                  <Box pt={0.5} pr={1}><AlarmIcon color="error" /></Box>
                  <Typography flexGrow={1} variant="h6">Senast ändrad {selectedWatchlist.lastChanged}</Typography>
                </Stack>
              </Box>}
              <Box mt={4}>
                {selectedWatchlist.emails && <Typography>E-post skickas till: <b>{selectedWatchlist.emails}</b></Typography>}
                {!selectedWatchlist.emails && <Typography color="error">Bevakas inte via e-post</Typography>}
              </Box>
              {watchlistFields.filter(f => selectedWatchlist[f.key]).map((field, index) => (
                <Box mt={2} key={index}>
                  <Typography variant="subtitle1">{field.label}</Typography>
                  <Typography variant="body1">{selectedWatchlist[field.key] || '- saknas -'}</Typography>
                </Box>
              ))}
            </Box>
          </Box>}
        </>
        }

      </Stack>

      <PreviewDialog open={previewSbtId !== -1} onClose={() => setPreviewSbtId(-1)} title={''} link={`/rattsfall/${previewSbtId}`}>
        {previewSbtId !== -1 && <Box mr={4}><Sbt id={previewSbtId} /></Box>}
      </PreviewDialog>

      <ConfirmDialog
        title={`Sluta bevaka ${showConfirmDialog ? getTitle() : ''}`}
        text='Är du säker?' open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={() => deleteFolder()}
        saveButtonLabel="Ja"
      />

      <EditSblWatchListEntryDialog watchListEntry={editWatchListEntry} open={editWatchListEntry != null} onClose={(entry) => updateWatchListEntry(entry)} />
      <AddWatchDialog open={showAddWatchDialog} onClose={closeAddDialog} />
    </Box>
  );
}


export default WatchlistPage;