const LOGIN_DB_KEY = 'login.databas.infosoc.se',
	DB_KEY = 'db.databas.infosoc.se',
	ALL_LAWS_KEY = 'infosoc.se.all_laws';

class LocalStorageService {
	LOGIN_DB_KEY() {
		return LOGIN_DB_KEY;
	}

	DB_KEY() {
		return DB_KEY;
	}

	ALL_LAWS_KEY() {
		return ALL_LAWS_KEY;
	}

	/**
	 * GET an [object] from window.localStorage
	 *
	 * @param {string} key
	 *
	 * @returns {object} value
	 */
	get(key) {
		try {
			return JSON.parse(localStorage.getItem(key));
		} catch (err) {
			console.error(err);
		}
	}

	/**
	 * SET an [object] in window.localStorage
	 *
	 * @param {string} key
	 *
	 * @returns {object} value
	 */
	set(key, value) {
		try {
			localStorage.setItem(key, JSON.stringify(value));
			return localStorage.getItem(key);
		} catch (err) {
			console.error(err);
		}
	}

	/**
	 * REMOVE an [object] from window.localStorage
	 *
	 * @param {string} key
	 *
	 * @returns {boolean} removed
	 */
	static remove(key) {
		try {
			localStorage.removeItem(key);
			return true;
		} catch (err) {
			console.error(err);
		}
	}

	/**
	 * Get DB object
	 *
	 * @returns {object} db
	 */
	getDBs() {
		return localStorage.getItem(DB_KEY);
	}
}

const service = new LocalStorageService();
export { service as LocalStorageService, LOGIN_DB_KEY, DB_KEY, ALL_LAWS_KEY };
export default LocalStorageService;