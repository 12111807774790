import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useParams } from "react-router-dom";
import Sbt from "src/components/Sbt";
import SbtFullText from 'src/components/SbtFullText';


function SbtFullTextPage() {
  let { id } = useParams();

  

  return (
    <Box sx={{ flexGrow: 1, marginTop: '90px' }}>
      <SbtFullText id={id} />
    </Box>
  );
}


export default SbtFullTextPage;
