

export const getSblLink = (chapterTitle, paragraphTitle) => {

    let chapterStart = chapterTitle.indexOf(' kap ');
    if (chapterStart < 0) {
        chapterStart = chapterTitle.indexOf(' kap.');
    }

    const chapter = chapterStart >= 0 ? chapterTitle.substr(0, chapterStart) : '';

    let paragraphStart = paragraphTitle.indexOf('§');

    const paragraph = paragraphStart>=0? paragraphTitle.substr(0, paragraphStart) : '';
    const paragraphLabel = paragraph? `kap ${paragraph.trim()} §` : '';

    return { title: `${chapter.trim()} ${paragraphLabel}`, url: `k=${chapter.trim()}&p=${paragraph.trim()}&r=` };
}
