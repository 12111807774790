
import React, { useEffect, useState } from 'react';
import { Stack, Box, Paper, Checkbox, Typography, FormGroup, Button, IconButton, Link } from '@mui/material';
import DenseList from 'src/components/DenseList';
import { get, post } from "src/services/http-service";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import StarIcon from '@mui/icons-material/Star';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoveIcon from '@mui/icons-material/DriveFileMove';

import InfosocIcon from 'src/components/InfosocIcon';
import PreviewDialog from 'src/components/PreviewDialog';
import Sbt from 'src/components/Sbt';
import SblDialog from 'src/components/SblDialog';
import TextInputDialog from "src/components/TextInputDialog";
import ConfirmDialog from 'src/components/ConfirmDialog';
import FolderIcon from '@mui/icons-material/Folder';

import FavoriteDialog from 'src/components/FavoriteDialog';

import { toast } from 'react-toastify';

function FavoritesPage() {

  const [loading, setLoading] = useState(true);
  const [linkParams, setLinkParams] = useState(null);

  const [selectedFolder, setSelectedFolder] = useState({ name: 'Mina favoriter', entries: [] });
  const [folders1, setFolders1] = useState([]);
  const [folders2, setFolders2] = useState([]);
  const [folders3, setFolders3] = useState([]);
  const [folders4, setFolders4] = useState([]);
  const [folders1SelectedIndex, setFolders1SelectedIndex] = useState(0);
  const [folders2SelectedIndex, setFolders2SelectedIndex] = useState(-1);
  const [folders3SelectedIndex, setFolders3SelectedIndex] = useState(-1);
  const [selectedParentFolderId, setSelectedParentFolderId] = useState(-1);
  const [selectedFavoriteId, setSelectedFavoriteId] = useState(-1);

  const [showFavoriteDialog, setShowFavoriteDialog] = useState(false);

  const [selectedFolder1, setSelectedFolder1] = useState(null);
  const [selectedFolder2, setSelectedFolder2] = useState(null);
  const [selectedFolder3, setSelectedFolder3] = useState(null);

  const [previewSbtId, setPreviewSbtId] = useState(-1);
  const [previewSblId, setPreviewSblId] = useState(-1);
  const [previewTitle, setPreviewTitle] = useState('');


  const [reloadColumn, setReloadColumn] = useState(0);
  const [textInputMode, setTextInputMode] = useState('');
  const [showTextInput, setShowTextInput] = useState(false);
  const [textInputTitle, setTextInputTitle] = useState('');
  const [textInputLabel, setTextInputLabel] = useState('');
  const [textInitalValue, setTextInitalValue] = useState('');
  const [textInputErrorMessage, setTextInputErrorMessage] = useState('');

  const [showConfirm, setShowConfirm] = useState(false);
  const [favoriteDeleteId, setFavoriteDeleteId] = useState(-1);

  document.title = 'Favoriter | Infosoc';

  const load = async () => {
    const response = await get('/loginfolders');
    setFolders1([{ id: -1, name: 'Mina favoriter', icon: 'star' }].concat(response.entries));

    const favResponse = await get('/favorites');
    setSelectedFolder(favResponse);

    setFolders1SelectedIndex(0);
    setFolders2SelectedIndex(-1);
    setFolders3SelectedIndex(-1);
    setFolders2([]);
    setFolders3([]);
    setSelectedFolder1(null);
    setSelectedFolder2(null);
    setSelectedFolder3(null);

    setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  const selectFolder1 = async (index, entry) => {

    if (index === 0) {
      return load();
    }

    setReloadColumn(0);

    setFolders1SelectedIndex(index);
    setFolders2SelectedIndex(-1);
    setFolders3SelectedIndex(-1);
    setSelectedFolder1(entry);
    setSelectedFolder2(null);
    setSelectedFolder3(null);
    setFolders2([]);
    setFolders3([]);

    const response = await get(`/loginfolders?parentId=${entry.id}`);
    setFolders2(response.entries);

    const favResponse = await get(`/favorites?parentId=${entry.id}`);
    setSelectedFolder(favResponse);
  }

  const selectFolder2 = async (index, entry) => {
    setReloadColumn(1);
    setFolders2SelectedIndex(index);
    setFolders3SelectedIndex(-1);
    setSelectedFolder2(entry);
    setSelectedFolder3(null);
    setFolders3([]);

    const response = await get(`/loginfolders?parentId=${entry.id}`);
    setFolders3([...response.entries]);

    const favResponse = await get(`/favorites?parentId=${entry.id}`);
    setSelectedFolder(favResponse);
  }

  const selectFolder3 = async (index, entry) => {
    setReloadColumn(2);
    setFolders3SelectedIndex(index);
    setSelectedFolder3(entry);

    const response = await get(`/loginfolders?parentId=${entry.id}`);
    setFolders4([...response.entries]);

    const favResponse = await get(`/favorites?parentId=${entry.id}`);
    setSelectedFolder(favResponse);
  }

  const selectFavorite = async (entry) => {

    setPreviewSbtId(-1);
    setPreviewSblId(-1);
    setPreviewTitle(entry.name);

    if (entry.type === 'lawreport') {
      setPreviewSbtId(entry.id);
    }
    else {
      setPreviewSblId(entry.id);

      const sblFavInfo = await get(`/lawdocuments/${entry.id}/favorite/${entry.favoriteId}`);
      setLinkParams(sblFavInfo.linkParams);
    }
  }

  const deleteFavorite = async (entry) => {
    setFavoriteDeleteId(entry.favoriteId);
    setShowConfirm(true);
  }

  const confirmSubmit = async () => {
    setFavoriteDeleteId(-1);

    if (favoriteDeleteId > 0) {
      await post(`/favorites/${favoriteDeleteId}/delete`, {}, true);

      const url = selectedFolder.folderId === -1 ? '/favorites' : `/favorites?parentId=${selectedFolder.folderId}`;

      const favResponse = await get(url);
      setSelectedFolder(favResponse);
    }
    else {
      const form = new FormData();
      await post(`/loginfolders/${selectedParentFolderId}/delete`, form, true);

      if (reloadColumn === 1) {
        selectFolder1(folders1SelectedIndex, selectedFolder1);
      }
      else if (reloadColumn === 2) {
        selectFolder2(folders2SelectedIndex, selectedFolder2);
      }
      else {
        load();
      }
    }
  }

  const showNewFolder = (parentId, reloadColumnIndex) => {
    setReloadColumn(reloadColumnIndex);
    setSelectedParentFolderId(parentId);
    setTextInputMode('create');
    setTextInputTitle('Ny katalog');
    setTextInputLabel('Katalognamn');
    setShowTextInput(true);
    setTextInitalValue('');
  }

  const renameFavorite = (entry) => {
    setSelectedFavoriteId(entry.favoriteId);
    setTextInputMode('edit-favorite');
    setTextInputTitle('Ändra namn');
    setTextInputLabel('Favoritnamn');
    setShowTextInput(true);
    setTextInitalValue(entry.name);
  }

  const renameFolder = (entry) => {
    setSelectedParentFolderId(entry.folderId);
    setTextInputMode('edit');
    setTextInputTitle('Ändra namn');
    setTextInputLabel('Katalognamn');
    setShowTextInput(true);
    setTextInitalValue(entry.name);
  }

  const deleteFolder = (entry) => {
    setSelectedParentFolderId(entry.folderId);
    setTextInputMode('delete');
    setShowConfirm(true);
  }

  const saveTextInput = async (input) => {

    setShowTextInput(false);

    if (!input) {
      return;
    }

    let form = new FormData();
    if (textInputMode === 'create') {
      form.append('name', input);
      form.append('parentId', selectedParentFolderId);
      form.append('type', 'favorite');

      await post('/loginfolders', form, true);
    }
    else if (textInputMode === 'edit') {
      form.append('name', input);
      await post(`/loginfolders/${selectedParentFolderId}`, form, true);
    }
    else if (textInputMode === 'edit-favorite') {
      await post(`/favorites/${selectedFavoriteId}?name=${input}`, {}, true);
    }

    if (reloadColumn === 1) {
      selectFolder1(folders1SelectedIndex, selectedFolder1);
    }
    else if (reloadColumn === 2) {
      selectFolder2(folders2SelectedIndex, selectedFolder2);
    }
    else {
      load();
    }
  }

  const toggleSelected = (index, selected) => {
    const cloned = { ...selectedFolder };
    cloned.entries[index].selected = selected;
    setSelectedFolder(cloned);
  }

  const moveToFolder = async (folderId, title) => {
    const favoriteIds = selectedFolder.entries.filter(e => e.selected).map(e => e.favoriteId);

    const form = new FormData();
    form.append('targetFolderId', folderId);
    form.append('favoriteIds', favoriteIds);
    await post('/favorites/move', form, true);

    load();

    setShowFavoriteDialog(false);

    toast.success(`Favoriter har flyttas till ${title}`);
  }


  if (loading) {
    return <></>;
  }

  const selectedCount = selectedFolder.entries.filter(e => e.selected).length;

  return <Box mt={8} ml={0}>
    <Box sx={{ flexGrow: 1 }} >
      <Stack direction="row">
        { }
        <Box minWidth={200} mt={2} borderRight={'1px solid rgba(0,0,0,0.12)'}>
          <Box mt={1} ml={2}>
            <Button size="small" variant="outlined" onClick={() => showNewFolder(-1, 0)} startIcon={<AddIcon />}>Ny katalog</Button>
          </Box>
          <DenseList offsetTop={154} entries={folders1} onSelect={(index, entry) => selectFolder1(index, entry)} selectedIndex={folders1SelectedIndex} />
        </Box>
        {folders1SelectedIndex > 0 &&
          <Box minWidth={200} mt={2} borderRight={'1px solid rgba(0,0,0,0.12)'}>
            <Box mt={1} ml={2}>
              <Button size="small" variant="outlined" onClick={() => showNewFolder(selectedFolder1.id, 1)} startIcon={<AddIcon />}>Ny katalog</Button>
            </Box>
            <DenseList offsetTop={154} entries={folders2} onSelect={(index, entry) => selectFolder2(index, entry)} selectedIndex={folders2SelectedIndex} />
          </Box>
        }
        {folders2SelectedIndex !== -1 &&
          <Box minWidth={200} mt={2} borderRight={'1px solid rgba(0,0,0,0.12)'}>
            <Box mt={1} ml={2}>
              <Button size="small" variant="outlined" onClick={() => showNewFolder(selectedFolder2.id, 2)} startIcon={<AddIcon />}>Ny katalog</Button>
            </Box>
            <DenseList offsetTop={154} entries={folders3} onSelect={(index, entry) => selectFolder3(index, entry)} selectedIndex={folders3SelectedIndex} />
          </Box>
        }
        <Box ml={2} mt={2}>

          <Stack direction="row">
            <Box pt={0.4} mr={1}>
              {folders1SelectedIndex === 0 ? <StarIcon color='secondary' /> : <FolderIcon color='primary' />}
            </Box>
            <Typography variant='h6'>{selectedFolder.name}</Typography>
          </Stack>

          <Box mt={2} mb={10}>
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" disabled={folders1SelectedIndex === 0} startIcon={<EditIcon />} onClick={() => renameFolder(selectedFolder)}>Ändra namn på katalog</Button>
              <Button variant="outlined" disabled={folders1SelectedIndex === 0} startIcon={<DeleteIcon />} onClick={() => deleteFolder(selectedFolder)}>Radera katalog</Button>
              <Button variant="outlined" disabled={selectedCount === 0} startIcon={<MoveIcon />} onClick={() => setShowFavoriteDialog(true)}>Flytta valda</Button>
            </Stack>

            <FormGroup>
              {selectedFolder.entries.length > 0 && <Box mt={2}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 500 }} size="small">
                    <TableBody>
                      {selectedFolder.entries.map((row, index) => (
                        <TableRow key={index} selected={row.selected}>
                          <TableCell>
                            <Checkbox
                              color="primary"
                              checked={row.selected}
                              onChange={(event) => toggleSelected(index, event.target.checked)}
                            />
                          </TableCell>
                          <TableCell><InfosocIcon type={row.icon} /></TableCell>
                          <TableCell width={450}>
                            <Link href="#" onClick={() => selectFavorite(row)}>
                              {row.name}
                            </Link>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton onClick={() => renameFavorite(row)}><EditIcon /></IconButton>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton onClick={() => deleteFavorite(row)}><DeleteIcon /></IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              }
            </FormGroup>
          </Box>
        </Box>
      </Stack>
    </Box >

    <PreviewDialog open={previewSbtId !== -1} onClose={() => setPreviewSbtId(-1)} link={`/rattsfall/${previewSbtId}`}>
      {previewSbtId !== -1 && <Box mr={4}><Sbt id={previewSbtId} /></Box>}
    </PreviewDialog>

    <SblDialog
      id={previewSblId}
      linkParams={linkParams}
      open={previewSblId !== -1}
      onClose={() => setPreviewSblId(-1)}
    />

    {showTextInput &&
      <TextInputDialog open={showTextInput}
      initalValue={textInitalValue}
      loading={loading}
      title={textInputTitle}
      inputLabel={textInputLabel}
      text=""
      saveButtonLabel="Spara"
      errorMessage={textInputErrorMessage}
      onClose={(input) => saveTextInput(input)}
      />
    }

    <ConfirmDialog
      open={showConfirm}

      title={favoriteDeleteId > 0 ? "Radera favorit" : "Radera katalog"}
      onClose={() => setShowConfirm(false)}
      onConfirm={() => confirmSubmit()}
      saveButtonLabel="OK"
      text="Är du säker?"
    />

    <FavoriteDialog
      moveOriginFolderId={selectedFolder.folderId}
      open={showFavoriteDialog}
      onClose={(selectedFavoriteFolderId, title) => selectedFavoriteFolderId ? moveToFolder(selectedFavoriteFolderId, title) : setShowFavoriteDialog(false)}
    />

  </Box >

}

export default FavoritesPage;