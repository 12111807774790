import React, { useState, useEffect } from 'react';
import { get } from "../services/http-service";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import storageService from 'src/services/storage-service';
import SelectAllIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/Clear';

import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import SblChanges from 'src/components/SblChanges';
import DialogCloseExpand from './DialogCloseExpand';

function SblChangesDialog({ id, open, onClose }) {

  let [title, setTitle] = useState('');

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={onClose}
      scroll="paper"
    >
      <DialogCloseExpand link={`/lag/${id}/andringar`} onClose={onClose}/>

      <Box mt={2} ml={2}>
        <Typography variant="h5">{title} | Ändringar</Typography>
      </Box>

      <Box mt={4}>
        {id > 0 && <SblChanges offsetTop={160} onLoaded={(newTitle) => setTitle(newTitle)} id={id} />}
      </Box>
    </Dialog>
  )
}

SblChangesDialog.propTypes = {
  id: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SblChangesDialog;