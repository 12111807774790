import React, { useRef } from 'react';
import { post } from '../services/http-service';
import { Button, TextField, FormGroup, Typography, styled } from '@mui/material/';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import PropTypes from 'prop-types';

function AddNewFolder({ parentFolder, onComplete, type }) {
	const inputRef = useRef(null);

	// Customizing TextField using the styled utility
	const StyledTextField = styled(TextField)({
		'& .MuiOutlinedInput-root': {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
		},
		flex: 1,
	});

	// Customizing Button using the styled utility
	const StyledButton = styled(Button)({
		marginTop: 16,
		marginBottom: 8,
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		textTransform: 'lowercase',
	});

	const submit = async () => {
		let input = inputRef.current.value;
		if (input.length > 0) {
			let form = new FormData();

			form.append('name', input);
			form.append('type', type);

			switch (type) {
				case 'favorite':
					form.append('parentId', parentFolder.intId);
					break;
				case 'investigation':
					form.append('parentId', parentFolder.id);
					break;
				default:
					break;
			}

			await post('/loginfolders', form, true);
			if (onComplete) {
				onComplete(form.get('parentId'));
			}
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			submit();
		}
	};

	if (!parentFolder) {
		return <></>;
	}

	return (
		<>
			<Typography variant='subtitle1' bold>
				Skapa ny katalog i <b>{parentFolder.name || parentFolder.title}</b>
			</Typography>
			<FormGroup row>
				<StyledTextField
					inputRef={inputRef}
					onKeyDown={handleKeyPress}
					margin='normal'
					label='Ange namn'
					autoFocus
					required
				/>
				<StyledButton variant='contained' onClick={submit} title='Skapa katalog' disableElevation>
					<CreateNewFolderIcon />
				</StyledButton>
			</FormGroup>
		</>
	);
}

AddNewFolder.propTypes = {
	parentFolder: PropTypes.object.isRequired,
	type: PropTypes.oneOf(['favorite', 'investigation']).isRequired,
	onComplete: PropTypes.func,
};

AddNewFolder.defaultProps = {
	onComplete: () => {},
};

export default AddNewFolder;
