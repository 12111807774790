/**
 * @fileoverview Folder utility functions.
 * @module services/folder-util
 * 
 * A collection of utility functions for working with folders (Favorite, Investigation & watchlist).
 */

/**
 * Recursively find a folder by id.
 * 
 * @param {object} node The root node to search in.
 * @param {number} id The id of the folder to find.
 * 
 * @returns {object} The found folder or null if not found.
 */
const recursiveFind = (node, id) => {
  
  if (node.intId === id || node.key === id || node.id === id) {
    return node;
  }

  for (let i = 0; i < node.children.length; i++) {
    const found = recursiveFind(node.children[i], id);
    if (found) {
      return found;
    }
  }

  return null;
}

/**
 * Recursively find depth of a folder by id.
 * 
 * @param {object} node The root node to search in.
 * @param {number} id The id of the folder to find.
 * @param {number} depth The current depth.
 * 
 * @returns {object} The found folder or null if not found.
 */
const recursiveFindDepth = (node, id, depth = 0) => {
    if (node.intId === id || node.key === id || node.id === id) {
        return depth;
    }
    
    for (let i = 0; i < node.children.length; i++) {
        const found = recursiveFindDepth(node.children[i], id, depth + 1);
        if (found) {
        return found;
        }
    }
    
    return null;
}

export { recursiveFind, recursiveFindDepth };
