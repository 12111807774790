import React, { useState } from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import EditIcon from '@mui/icons-material/Edit';
import DatabaseDialog from "src/components/DatabaseDialog";
import Link from '@mui/icons-material/Link';
import PreviewDialog from 'src/components/PreviewDialog';
import Newsletter from 'src/components/Newsletter';
import { get } from "src/services/http-service";

function Banner({ entries, loading }) {

  const [showDialog, setShowDialog] = useState(false);
  const [newsletter, setNewsletter] = useState(null);

  const imageSuffix = entries.length < 3 ? '_wide' : '';
  const showMultipleBanner = entries.length > 4;

  const cols = entries.length < 5 ? entries.length : 1;
  const items = entries.length < 5 ? entries : [{
    title: entries.length + ' valda rättsområden',
    key: 'multiple',
    color: '#27aae2'
  }]

  if (loading) {
    return (
      <>
        <Box mb={4}>
          <Skeleton variant="rounded" height={40} />
        </Box>
        <Skeleton variant="rounded" height={200} />
      </>
    )
  }


  const showNewsletter = async (entry) => {
    const response = await get(`/newsletter/latest/${entry.key}`);
    setNewsletter(response);
  }

  const title = entries.length === 1 ? '1 valt rättsområde' : entries.length + ' valda rättsområden';

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        <Stack direction="row" spacing={1}>
          <span>{title}</span>
          <Button size="small" startIcon={<EditIcon />} disableElevation variant="outlined" onClick={() => setShowDialog(true)}>Ändra</Button>
        </Stack>
      </Typography>

      <Box display={{ xs: 'none', md: 'none', lg: 'block' }} >
        <ImageList cols={cols} gap={8} >
          {items.map((entry, index) => (
            <ImageListItem key={index} style={{border: '1px solid #aaa', borderRadius: '5px'}}>
              <img
                style={{ borderRadius: '5px 5px 0 0 ' }}
                src={`/start/db_${entry.key}${imageSuffix}.jpg`}
                alt={entry.title}
                loading="lazy"
              />
              {!showMultipleBanner && (
                <ImageListItemBar
                  position="below"
                  style={{ background: entry.color, color: '#fff', padding: '7px 0 0 10px', borderRadius: '0 0 5px 5px', textShadow: '1px 2px 2px rgb(0 0 0 / 10%)' }}
                  title={<Typography variant="h6">{entry.title}</Typography>}
                  subtitle={<div onClick={() => showNewsletter(entry) } style={{ textDecoration: 'underline', fontSize: '14px', cursor: 'pointer' }}>{'Se nyhetsbrev' + entry.newsletterTitle}</div>}
                />)}
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      <DatabaseDialog open={showDialog} onClose={() => setShowDialog(false)}/>
      <PreviewDialog open={newsletter} onClose={() => setNewsletter(null)} title={''} link={`/nyhetsbrev/${newsletter? newsletter.id : -1}`}>
        {newsletter && <Box mr={4}><Newsletter newsletter={newsletter} /></Box>}
      </PreviewDialog>
    </Box>
  );
}

Banner.propTypes = {
  entries: PropTypes.array,
  loading: PropTypes.bool,
};

export default Banner;
