import React, { useEffect, useState, useCallback } from 'react';
import { Box, Stack, CircularProgress } from '@mui/material';
import { TreeItem, SimpleTreeView } from '@mui/x-tree-view';
import { get } from 'src/services/http-service';
import DenseList from 'src/components/DenseList';
import SblDialog from 'src/components/SblDialog';
import Loading from 'src/components/Loading';
import { LocalStorageService } from 'src/services/local-storage-service';
import FolderIcon from '@mui/icons-material/Folder';

const MAX_DEPTH = 1;

function SblListPage() {
	const [registers, setRegisters] = useState([]);
	const [selectedRegisterId, setSelectedRegisterId] = useState(-1);
	const [expanded, setExpanded] = useState([]);
	const [lawDocuments, setLawDocuments] = useState([]);
	const [sblId, setSblId] = useState(-1);
	const [loading, setLoading] = useState(false);
	const [fetchAll, setFetchAll] = useState(false);

	document.title = 'Lagar | Infosoc';

	const getIcon = useCallback(
		() => <FolderIcon style={{ fontSize: 24 }} color="primary" />,
		[]
	);

	const renderTree = useCallback(
		(nodes, depth = 0) => {
			const isSelected = nodes.id.toString() === selectedRegisterId;

			return (
				<TreeItem
					key={nodes.id}
					itemId={nodes.id.toString()}
					sx={{
						marginLeft: depth,
					}}
					label={
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								fontSize: 14,
								fontWeight: isSelected ? 'bold' : 'normal',
							}}
						>
							{nodes.name}
						</Box>
					}
					slots={{
						icon: getIcon,
					}}
				>
					{nodes.children && depth < MAX_DEPTH
						? nodes.children.map((child) =>
								renderTree(child, depth + 1)
						  )
						: null}
				</TreeItem>
			);
		},
		[getIcon, selectedRegisterId]
	);

	const findNodeById = useCallback((nodes, id, currentDepth = 0) => {
		for (const node of nodes) {
			if (node.id.toString() === id) {
				return [node, currentDepth];
			}
			if (node.children) {
				const [childNode, childDepth] = findNodeById(
					node.children,
					id,
					currentDepth + 1
				);
				if (childNode) {
					return [childNode, childDepth];
				}
			}
		}
		return [null, -1];
	}, []);

	const getSelectableNodeId = useCallback((node, depth = 0) => {
		if (depth < MAX_DEPTH && node.children && node.children.length > 0) {
			return getSelectableNodeId(node.children[0], depth + 1);
		}
		return node.id.toString();
	}, []);

	const collectLeafNodes = useCallback((node) => {
		const leafNodes = [];
		const traverse = (currentNode) => {
			if (currentNode.children && currentNode.children.length > 0) {
				currentNode.children.forEach((child) => traverse(child));
			} else {
				leafNodes.push(currentNode);
			}
		};
		traverse(node);
		return leafNodes;
	}, []);

	const selectRegister = useCallback(
		async (id) => {
			setLoading(true);

			const [node, depth] = findNodeById(registers, id);

			let selectedId = id;

			// Automatically select the first child at depth 1
			// if the node is within MAX_DEPTH and has children
			if (
				node &&
				node.children &&
				node.children.length > 0 &&
				depth < MAX_DEPTH
			) {
				selectedId = node.children[0].id.toString();
			}
			setSelectedRegisterId(selectedId);

			const [selectedNode] = findNodeById(registers, selectedId);

			if (selectedNode) {
				const leafNodes = collectLeafNodes(selectedNode);
				let newLawDocuments = [];

				for (const leafNode of leafNodes) {
					const documents = await get(
						`/lawdocuments/${leafNode.id}/categories?fetchAll=${fetchAll}`
					);
					newLawDocuments.push(...documents.entries);
				}

				setLawDocuments(newLawDocuments);

				const focusedElement = document.activeElement;
				if (focusedElement) {
					focusedElement.blur();
				}
			}
			setLoading(false);
		},
		[findNodeById, registers, fetchAll, collectLeafNodes]
	);

	useEffect(() => {
		const initializeRegisters = async () => {
			const allLawsSelected =
				LocalStorageService.get(LocalStorageService.ALL_LAWS_KEY()) ??
				true;
			setFetchAll(allLawsSelected);

			const result = await get(`/law/registers?fetchAll=${fetchAll}`);
			setRegisters(result);

			const collectParentNodeIds = (nodes, depth = 0, parentIds = []) => {
				nodes.forEach((node) => {
					if (
						node.children &&
						node.children.length > 0 &&
						depth < MAX_DEPTH
					) {
						parentIds.push(node.id.toString());
						collectParentNodeIds(
							node.children,
							depth + 1,
							parentIds
						);
					}
				});
				return parentIds;
			};

			const parentIds = collectParentNodeIds(result);
			setExpanded(parentIds);
		};

		initializeRegisters();
	}, [fetchAll]);

	useEffect(() => {
		if (registers.length > 0) {
			selectRegister(registers[0].id.toString());
		}
	}, [registers, selectRegister]);

	if (registers.length === 0) {
		return (
			<Box mt={10} ml={2}>
				<Loading />
			</Box>
		);
	}

	return (
		<Box sx={{ flexGrow: 1 }} mt={10} ml={2}>
			<Stack direction="row">
				<Box minWidth={300}>
					<SimpleTreeView
						expandedItems={expanded}
						onItemSelectionToggle={(args, nodeId) => {
							selectRegister(nodeId);
						}}
						selectedItems={[selectedRegisterId]}
					>
						{registers.map((item) => renderTree(item))}
					</SimpleTreeView>
				</Box>
				<Box width={1} sx={{ position: 'relative' }}>
					{loading && (
						<Box
							sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
								backgroundColor: 'rgba(255, 255, 255, 0.7)',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								zIndex: 1,
							}}
						>
							<CircularProgress />
						</Box>
					)}
					<DenseList
						entries={lawDocuments}
						offsetTop={120}
						showFilter={true}
						onSelect={(index, entry) => setSblId(entry.id)}
					/>
				</Box>
			</Stack>
			<SblDialog
				open={sblId !== -1}
				onClose={() => setSblId(-1)}
				id={sblId}
			/>
		</Box>
	);
}

export default SblListPage;
