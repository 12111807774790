
import storageService from "./storage-service";

// CD3E5480-2CA64850-A19AF149-BB5C2B4C

const ENV = {
  url: `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_URL}`
}

const getHeaders = () => {

  const login = storageService.load();

  return {
    accesskey: login.accessKey,
    dbkey: login.dbKey
  };
}

export const UI_URL = `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_BASE_URL}`;
export const API_URL = `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_URL}`;

export const get = async (url, extraHeaders = {}) => {

  let response = await fetch(`${ENV.url}${url}`, { headers: { ...getHeaders(), ...extraHeaders }, method: 'GET' });

  if (response.ok) {
    return response.json();
  }

  return {};
};

export const downloadFile = async (url, fileName) => {
  await fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    })
    .catch(error => console.error(error));
}

export const post = async (url, body, rawResponse = false) => {
  let response = await fetch(`${ENV.url}${url}`, { headers: getHeaders(), method: 'POST', body })

  return rawResponse ? response : response.json();
}