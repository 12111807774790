import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import UserIcon from '@mui/icons-material/Person';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';

import ListItemIcon from '@mui/material/ListItemIcon';
import ChangePasswordIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';

import storageService from 'src/services/storage-service';

import DatabaseDialog from './DatabaseDialog';
import ChangePasswordDialog from './ChangePasswordDialog';
import SearchBar from './SearchBar';
import FeaturesDialog from './FeaturesDialog';

export default function TopMenu({ currentUser }) {
	const [anchorElUser, setAnchorElUser] = useState(null);
	const [showDbDialog, setShowDbDialog] = useState(false);
	const [showFeaturesDialog, setShowFeaturesDialog] = useState(false);
	const [showChangePassword, setShowChangePassword] = useState(false);

	const navigate = useNavigate();

	const login = storageService.load();
	const selectedDatabasesCount = String.prototype.concat(...new Set(login.dbKey)).length;
	const databaseTitle =
		selectedDatabasesCount === 1 ? '1 valt område' : selectedDatabasesCount + ' valda områden';
	const page = window.location.pathname;

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const logout = () => {
		storageService.clear();
		window.location.reload(false);
	};

	const isPlusMember =
		currentUser &&
		(currentUser.featureSbtPlus ||
			currentUser.featureELiterature ||
			currentUser.featureLegalComments);

	return (
		<AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: 'white' }}>
			<Toolbar disableGutters={true}>
				<Box ml={1} mr={2}>
					<img
						onClick={() => navigate('/')}
						style={{ position: 'absolute', top: 0, marginTop: 10, cursor: 'pointer' }}
						src='/logo.png'
						alt=''
					/>
				</Box>

				<SearchBar sticky={page === '/sokning'} />

				<Typography variant='h6' component='div' sx={{ flexGrow: 1 }} />

				<Box mr={1}>
					<Stack direction='row' spacing={2} style={{ color: '#444' }}>
						<Stack spacing={0}>
							<Typography variant='subtitle1' lineHeight={'unset'}>
								{login.displayName}
							</Typography>
							<Link
								onClick={() => setShowDbDialog(true)}
								style={{ cursor: 'pointer' }}
								variant='body2'
							>
								{databaseTitle}
							</Link>
						</Stack>

						<Badge
							badgeContent={<span style={{ color: '#FFF', fontSize: '15px' }}>+</span>}
							color={'secondary'}
							overlap='circular'
							sx={{
								'& .MuiBadge-badge': {
									backgroundColor: isPlusMember ? 'secondary' : '#757575',
								},
							}}
						>
							<Avatar onClick={handleOpenUserMenu} style={{ cursor: 'pointer' }}>
								<UserIcon />
							</Avatar>
						</Badge>
					</Stack>

					<Menu
						sx={{ mt: '45px' }}
						id='menu-appbar'
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						disableAutoFocusItem
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorElUser)}
						onClose={handleCloseUserMenu}
					>
						<MenuItem
							key='plus'
							onClick={() => {
								setShowFeaturesDialog(true);
								handleCloseUserMenu();
							}}
						>
							<Chip
								color={isPlusMember ? 'secondary' : 'default'}
								variant='outlined'
								label='Plustjänster'
								title='Översikt plustjänster'
								size='small'
								sx={{ borderRadius: 2, width: '100%', margin: '0 auto', cursor: 'pointer'}}
							/>
						</MenuItem>
						<MenuItem key='changepassword' onClick={handleCloseUserMenu}>
							<ListItemIcon>
								<ChangePasswordIcon fontSize='small' />
							</ListItemIcon>
							<Typography textAlign='center' onClick={() => setShowChangePassword(true)}>
								Ändra lösenord
							</Typography>
						</MenuItem>
						<MenuItem key='logout' onClick={logout}>
							<ListItemIcon>
								<LogoutIcon fontSize='small' />
							</ListItemIcon>
							<Typography textAlign='center'>Logga ut</Typography>
						</MenuItem>
					</Menu>
				</Box>
			</Toolbar>

			<DatabaseDialog open={showDbDialog} onClose={() => setShowDbDialog(false)} />
			<ChangePasswordDialog
				open={showChangePassword}
				onClose={() => setShowChangePassword(false)}
			/>
			<FeaturesDialog
				open={showFeaturesDialog}
				onClose={() => setShowFeaturesDialog(false)}
			/>
		</AppBar>
	);
}
