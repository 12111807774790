import React, { useState, useEffect } from 'react';
import { get, post } from "../services/http-service";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import storageService from 'src/services/storage-service';
import SelectAllIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/Clear';
import OfferDialog from "src/components/OfferDialog";
import { toast } from 'react-toastify';

import InfosocIcon from './InfosocIcon';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import DialogClose from './DialogClose';

function SearchHistoryDialog({ open, onClose }) {

  const [history, setHistory] = useState([]);

  const load = async () => {
    let response = await get("/search/history");
    setHistory(response);
  }

  useEffect(() => {
    if (open) {
      load();
    }
  }, [open]);

  const clearHistory = async () => {
    await post('/search/history/clear', {}, true);
    toast.success('Din sökhistorik är rensad');
    onClose();
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose()}
        scroll="paper"
      >
        <DialogTitle>Sökhistorik
          <DialogClose onClose={() => onClose()} />
        </DialogTitle>

        <Box width={600}>
          <List dense>
            {history.map((entry, index) => (<>
              <ListItemButton onClick={() => onClose(entry.searchQuery)} m={0} >
                <ListItem alignItems="flex-start"  >
                  <ListItemAvatar>
                    <InfosocIcon type="search" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={entry.searchQuery}
                    secondary={entry.createDate}
                  />
                </ListItem></ListItemButton>
              {index < history.length - 1 && <Divider />}
            </>)
            )}

          </List>
        </Box>

        <DialogActions>
          <Box m={2}>
            <Button startIcon={<DeleteIcon color='error' />} variant="outlined" color="error" onClick={clearHistory}>Rensa sökhistorik</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )

}

SearchHistoryDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SearchHistoryDialog;