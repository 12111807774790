import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import DialogCloseExpand from './DialogCloseExpand';
import { Grid } from '@mui/material';

function PreviewDialog({
	children,
	open,
	onClose,
	title: initialTitle,
	link,
	maxWidth,
}) {
	const [title, setTitle] = useState(initialTitle || '');

	// Function to handle title update
	const handleTitleUpdate = (newTitle) => {
		setTitle(newTitle);
	};

	const addCallbackToChildren = (children) => {
		if (!children) {
			return children;
		}

		let isSingleChild =
			!Array.isArray(children) && React.isValidElement(children);
		let childArray = React.Children.toArray(children);

		let modifiedChildren = childArray.map((child) => {
			if (!React.isValidElement(child)) {
				return child;
			}

			// Check if child has its own children and handle them recursively
			const childProps = { ...child.props };

			if (child.props && child.props.children) {
				childProps.children = addCallbackToChildren(
					child.props.children
				);
			} else {
				// Add the onTitleUpdate prop to the child
				childProps.onTitleUpdate = handleTitleUpdate;
			}

			return React.cloneElement(child, childProps);
		});

		// If it was a single child, return only the modified child instead of an array
		return isSingleChild ? modifiedChildren[0] : modifiedChildren;
	};

	return (
		<Dialog
			fullWidth={maxWidth === 'xl'}
			maxWidth={maxWidth}
			open={!!open}
			onClose={(e, r) => {
				if (e.target.tagName !== 'INPUT') {
					onClose();
				}
			}}
			scroll="paper"
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle sx={{ m: 0, p: 2 }}>
				<Grid container m={2} justifyContent="space-evenly">
					<Grid item xs={8}>
						{title && <Typography variant="h5">{title}</Typography>}
					</Grid>
					<Grid item xs={4}>
						<DialogCloseExpand link={link} onClose={onClose} />
					</Grid>
				</Grid>
			</DialogTitle>

			<Box ml={4} mr={4} mb={4}>
				{addCallbackToChildren(children)}
			</Box>
		</Dialog>
	);
}

PreviewDialog.propTypes = {
	open: PropTypes.bool,
	link: PropTypes.string,
	onClose: PropTypes.func,
};

PreviewDialog.defaultProps = {
	open: false,
	link: '',
	onClose: () => {},
	maxWidth: 'xl',
};

export default PreviewDialog;
