import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "src/services/http-service";
import { Typography, Stack, Box, Divider, } from '@mui/material/';

function InvestigationPrintPage() {

  let { id } = useParams();
  const [title, setTitle] = useState('');
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);

  const load = async () => {

    const response = await get(`/investigations/${id}`);
    setEntries(response.entries);
    setTitle(response.title);

    setLoading(false);

    document.title = "Skriv Ut | Infosoc";
    setTimeout(() => {
      window.print();
    }, 50)
  }

  useEffect(() => {
    load();
  }, [id]);

  if (loading) {
    return <></>;
  }

  return <Box m={2}>
    <Box mb={2}>
      <Typography variant="h5">{title}</Typography>
    </Box>
    {entries.map((entry, index) => <Box mt={1} key={index}>
      <Typography variant="subtitle1"><b>{entry.title}</b></Typography>
      <Typography>{entry.text}</Typography>
      <Box mt={1}>
        <Divider />
      </Box>
    </Box>)}
  </Box>

}

export default InvestigationPrintPage;