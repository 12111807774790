import React, { useEffect, useState, useCallback } from 'react';
import {
	Box,
	TextField,
	Stack,
	Paper,
	Select,
	Button,
	IconButton,
	MenuItem,
	Collapse,
	Tooltip,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash.debounce';
import { get } from 'src/services/http-service';
import InfosocIcon from './InfosocIcon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PreviewDialog from 'src/components/PreviewDialog';
import Sbt from 'src/components/Sbt';
import SblDialog from 'src/components/SblDialog';
import SearchHistoryDialog from './SearchHistoryDialog';
import SearchHistoryIcon from '@mui/icons-material/History';
import PropTypes from 'prop-types';
import {
	DATE_OPTION_VALUES,
	DATE_OPTIONS,
	WORD_MATCH_OPTIONS,
	WORD_MATCH_VALUES,
	SORT_OPTIONS,
} from 'src/services/search-service';
import HeaderIcon from './HeaderIcon';

const ICON_LOOKUP = {
	0: 'search',
	1: 'gavel',
	2: 'book',
	3: 'gavel',
	4: 'analysis',
	5: 'newsletter',
	6: 'form',
	7: 'legalcomment',
};

function SearchBar({ sticky }) {
	const [hideSuggestions, setHideSuggestions] = useState(false);
	const [showSearchOptions, setShowSearchOptions] = useState(false);
	const [date, setDate] = useState(' - Alla datum -');
	const [wordMatch, setWordMatch] = useState('Innehåller alla ord');
	const [sortOption, setSortOption] = useState('Sortera efter relevans');

	const [sbtId, setSbtId] = useState(-1);
	const [sblId, setSblId] = useState(-1);

	const [showSearchHistoryDialog, setShowSearchHistoryDialog] = useState(false);

	const [query, setQuery] = useState('');
	const [suggestions, setSuggestions] = useState([]);
	const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);

	const [excludeWords, setExcludeWords] = useState('');

	const [search, setSearch] = useSearchParams();

	const navigate = useNavigate();

	const searchQuery = search.get('q') || '';

	useEffect(() => {
		setQuery(searchQuery);
	}, [searchQuery]);

	useEffect(() => {
		if (sticky) {
			setShowSearchOptions(true);
		} else {
			setShowSearchOptions(false);
		}
	}, [sticky]);

	const updateQueryBounced = async (query) => {
		if (query.trim().length === 0) {
			setSuggestions([]);
			setSelectedSuggestionIndex(-1);
		}

		const response = await get(`/typeahead?q=${query}`);

		const newSuggestions = response.options.map((o) => {
			const tokens = o.split(' ');
			return {
				id: parseInt(tokens[0]),
				type: parseInt(tokens[1]),
				sbtPlus: tokens[2] === 'true',
				name: tokens.slice(3).join(' '),
			};
		});

		setSuggestions(newSuggestions);
		setSelectedSuggestionIndex(0);
	};

	const updateQuery = useCallback(debounce(updateQueryBounced, 500), []);

	const performSearch = (hideOptions, query) => {
		if (query.trim().length === 0) {
			return;
		}

		setHideSuggestions(true);

		if (hideOptions && !sticky) {
			setShowSearchOptions(false);
		}

		const daysAgo = DATE_OPTION_VALUES[DATE_OPTIONS.findIndex((o) => o === date)];
		const match = WORD_MATCH_VALUES[WORD_MATCH_OPTIONS.findIndex((o) => o === wordMatch)];
		const sort = sortOption === 'Sortera efter relevans' ? 'relevans' : 'datum';
		const sid = (Math.random() + 1).toString(36).substring(2);

		navigate({
			pathname: '/sokning',
			search: `?q=${query}&dagar=${daysAgo}&innehaller=${match}&excludera=${excludeWords}&sortera=${sort}&sid=${sid}`,
		});
	};

	useEffect(() => {
		if (sticky) {
			performSearch(true, query);
			setHideSuggestions(true);
		}
	}, [sticky, date, wordMatch, sortOption]); // eslint-disable-line react-hooks/exhaustive-deps

	const openSuggestion = (index) => {
		if (index === 0 || suggestions.length === 0) {
			return performSearch(true, query);
		}

		const suggestion = suggestions[index];

		if (suggestion.type === 2 || suggestion.type === 6) {
			setSblId('' + suggestion.id);
		} else if (suggestion.type === 5) {
			setSblId('' + suggestion.id);
		} else {
			setSbtId('' + suggestion.id);
		}

		setSelectedSuggestionIndex(index);
	};

	const keyPressed = (e) => {
		if (e.key === 'Escape') {
			if (!sticky) {
				setShowSearchOptions(false);
			}
			setHideSuggestions(true);

			// unfocus
			e.target.blur();
		}

		if (e.key === 'Enter') {
			openSuggestion(selectedSuggestionIndex);
		}

		if (e.key === 'ArrowDown' && selectedSuggestionIndex < suggestions.length - 1) {
			setSelectedSuggestionIndex(selectedSuggestionIndex + 1);
			e.preventDefault();
		}
		if (e.key === 'ArrowUp' && selectedSuggestionIndex > 0) {
			setSelectedSuggestionIndex(selectedSuggestionIndex - 1);
			e.preventDefault();
		}
	};

	const searchHistoryClose = (selectedQuery) => {
		setShowSearchHistoryDialog(false);

		if (selectedQuery) {
			setQuery(selectedQuery);
			return performSearch(true, selectedQuery);
		}
	};

	return (
		<Box style={{ marginLeft: 220 }}>
			<Stack direction='row'>
				<Collapse in={showSearchOptions} collapsedSize={60}>
					<Box style={{ marginTop: 10, position: 'relative' }}>
						<Stack direction='row'>
							<TextField
								value={query}
								className={showSearchOptions ? 'inputNoBorderRadiusBottom' : ''}
								onKeyDown={(e) => keyPressed(e)}
								onChange={(e) => {
									updateQuery(e.target.value);
									setHideSuggestions(false);
									setQuery(e.target.value);
								}}
								placeholder='Sök i hela Infosoc rättsdatabas'
								style={{ transition: 'width 0.5s', width: '100%', backgroundColor: sticky ? '#e8f0fd': '' }}
								size='small'
								onFocus={() => {
									setShowSearchOptions(true);
									setHideSuggestions(false);
								}}
							/>
							<Button
								disableElevation
								style={{
									marginLeft: -5,
									borderRadius: showSearchOptions ? '0' : '0 10px 10px 0',
								}}
								variant='contained'
								color='primary'
								startIcon={<SearchIcon />}
								onClick={() => performSearch(true, query)}>
								Sök
							</Button>
						</Stack>

						{showSearchOptions && (
							<Box mb={0} mt={0} style={{ marginTop: -1 }}>
								<Select
									style={{ borderRadius: 0 }}
									MenuProps={{
										style: { zIndex: 35000 },
									}}
									size='small'
									value={date}
									onChange={(event) => {
										setDate(event.target.value);
									}}>
									{DATE_OPTIONS.map((option, index) => (
										<MenuItem key={index} value={option}>
											{option}
										</MenuItem>
									))}
								</Select>
								<Select
									style={{ borderRadius: 0 }}
									MenuProps={{
										style: { zIndex: 35000 },
									}}
									size='small'
									value={wordMatch}
									onChange={(event) => {
										setWordMatch(event.target.value);
									}}>
									{WORD_MATCH_OPTIONS.map((option, index) => (
										<MenuItem key={index} value={option}>
											{option}
										</MenuItem>
									))}
								</Select>

								<Select
									style={{ borderRadius: 0 }}
									MenuProps={{
										style: { zIndex: 35000 },
									}}
									size='small'
									value={sortOption}
									onChange={(event) => {
										setSortOption(event.target.value);
									}}>
									{SORT_OPTIONS.map((option, index) => (
										<MenuItem key={index} value={option}>
											{option}
										</MenuItem>
									))}
								</Select>
								<TextField
									onKeyDown={(e) => keyPressed(e)}
									className='inputNoBorderRadius'
									onChange={(e) => setExcludeWords(e.target.value)}
									value={excludeWords}
									size='small'
									label='Exludera ord'
								/>
							</Box>
						)}

						{!hideSuggestions && suggestions.length > 0 && (
							<Paper
								style={{
									borderRadius: 0,
									position: 'absolute',
									top: 79,
									left: 0,
									background: '#fff',
									border: '1px solid #ddd',
									zIndex: 1000,
									width: 'calc(100% - 2px)',
								}}>
								<List dense onKeyDown={(e) => keyPressed(e)}>
									{suggestions.map((suggestion, index) => (
										<ListItem key={index} disablePadding>
											<ListItemButton
												onClick={() => openSuggestion(index)}
												selected={selectedSuggestionIndex === index}>
												<ListItemIcon>
													{suggestion.sbtPlus && (
														<InfosocIcon type='sbt_plus' />
													)}

													{!suggestion.sbtPlus && (
														<InfosocIcon type={ICON_LOOKUP[suggestion.type]} />
													)}
												</ListItemIcon>

												<Box>
													<ListItemText
														primary={suggestion.name}
														selected={index === selectedSuggestionIndex}
													/>
												</Box>
											</ListItemButton>
										</ListItem>
									))}
								</List>
							</Paper>
						)}
					</Box>
				</Collapse>
				{(!showSearchOptions || sticky) && (
					<Box height={24} mt={'10px'} ml={1}>
						<Tooltip arrow title='Sökhistorik' placement='right'>
							<IconButton onClick={() => setShowSearchHistoryDialog(true)}>
								<SearchHistoryIcon />
							</IconButton>
						</Tooltip>
					</Box>
				)}
				<Box height={24} mt={'14px'} ml={1}>
					<HeaderIcon
						text='Sökning'
            			event='search_guide_open_event'
						tooltip={
							<Box ml={1} pr={1}>
								<Box mt={2}>
									I Infosoc fritextsök kan du söka igenom allt innehåll i de databaser du är
									inloggad i, se därför till att du är inloggad på det område du vill söka inom
									(detta gör du längst upp till höger på sidan). Sökfunktionen visar som
									utgångspunkt endast träffar som innehåller samtliga ord du söker på, vilket
									innebär att du får färre träffar ju fler ord du söker på. Önskar du ett bredare
									träffresultat kan du under sökrutan välja "Innehåller minst ett ord". Där kan du
									även välja att söka på en exakt fras.
								</Box>
								<Box mt={2}>
									Om du vill söka på ett mål nr är sökfunktionen anpassad att kunna söka på flera
									olika sätt t ex ”2011-1587”, ”1587-11” eller ”1587-2011”. Innehåller mål nr någon
									bokstav skriver du den för sig, följt av mellanslag t ex ”M 3453-17”. Önskar du
									söka på en lag/föreskrift skriver du in numret i sökrutan t ex ”2009:400” eller
									”SKOLFS 2011:123”.
								</Box>
								<Box mt={2}>
									Ha för vana att kika på sökförslagen som dyker upp under sökrutan innan du trycker
									på ”Sök”, om du söker på nånting som innehåller nummer, t ex ett rättsfall eller
									en lag, är det stor chans att den kommer dyka upp som förslag redan innan du
									tryckt på ”Sök”, eller innan du ens skrivit färdigt din sökterm.
								</Box>
								<Box mt={2}>
									Efter utförd sökning är det att rekommendera att filtrera dina sökresultat i rutan
									till vänster. Är du t ex ute efter ett Rättsfall klickar du helt enkelt i denna
									ruta så att endast rättsfallsträffar visas. Därunder har du sedan också möjlighet
									att filtrera på olika sorters domstolar.
								</Box>
							</Box>
						}
            iconOnly
					/>
				</Box>
			</Stack>

			{showSearchOptions && !sticky && (
				<div className='search-backdrop' onClick={() => setShowSearchOptions(false)} />
			)}

			{!hideSuggestions && sticky && (
				<div className='search-backdrop' onClick={() => setHideSuggestions(true)} />
			)}

			<PreviewDialog open={sbtId !== -1} onClose={() => setSbtId(-1)} link={`/rattsfall/${sbtId}`}>
				{sbtId !== -1 && (
					<Box mr={4}>
						<Sbt id={sbtId} />
					</Box>
				)}
			</PreviewDialog>

			<SblDialog open={sblId !== -1} onClose={() => setSblId(-1)} id={sblId} />

			<SearchHistoryDialog open={showSearchHistoryDialog} onClose={searchHistoryClose} />
		</Box>
	);
}

SearchBar.propTypes = {
	sticky: PropTypes.bool,
};

SearchBar.defaultProps = {
	sticky: false,
};

export default SearchBar;
