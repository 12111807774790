
import React, { useEffect, useState, useCallback } from 'react';
import { Paper, Box, Stack, Typography, Grid } from '@mui/material';
import { get } from "src/services/http-service";
import PropTypes from 'prop-types';
import DenseList from './DenseList';
import Loading from './Loading';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

function SblChanges({ id, sfs, onLoaded, future, offsetTop }) {
  let [title, setTitle] = useState('');
  let [loading, setLoading] = useState(true);
  let [selectedIndex, setSelectedIndex] = useState(0);
  let [entries, setEntries] = useState([]);

  let [preamble, setPreamble] = useState('');
  let [changeEntries, setChangeEntries] = useState([]);

  const DIFF_METHOD = DiffMethod.WORDS;
  const CUSTOM_STYLES = {
    variables: {
      light: {
        diffViewerBackground: '#fff',
        emptyLineBackground: '#fff',
        addedBackground: '#eefff3',
        removedBackground: '#ffd4d280',
        wordAddedBackground: '#d3fadc',
        wordRemovedBackground: '#ffe3e6',
      },
    },
    marker: {
      display: 'none',
    },
    contentText: {
      fontSize: '14px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      padding: '0px 4px 0px 4px',
    }
  }

  const onSelectChange = useCallback(async (index) => {
    setSelectedIndex(index);

    const response = await get(`/lawdocuments/${id}/changes/${index}?future=${future}`);

    setChangeEntries(response.entries);
    setPreamble(response.preamble);
  }, [id, future]);

  useEffect(() => {
    const load = async () => {
      const response = await get(`/lawdocuments/${id}/changes?future=${future}`);
      const mappedEntries = response.entries.map(e => ({ name: e, icon: 'calendar' }));
      setEntries(mappedEntries);
      setTitle(response.title);
  
      onLoaded(response.title);
  
      if (mappedEntries.length > 0) {
  
        let startIndex = 0;
        if (sfs) {
          startIndex = mappedEntries.findIndex(m => m.name.indexOf(sfs) >= 0);
        }
  
        await onSelectChange(startIndex < 0 ? 0 : startIndex);
      }

      setLoading(false);
    }

    load();
  }, [id, sfs, future, onLoaded, onSelectChange]);

  if (loading) {
    return <Box mt={4} ml={2}><Loading /></Box>
  }

  const replaceHtml = (input) => {
    let result = input;

    // Replace <br> with \n
    result = result.replaceAll('<br>', '\n').trimStart();
    result = result.replaceAll('<br/>', '\n').trimStart();
    result = result.replaceAll('<br />', '\n').trimStart();
    result = result.replaceAll('&nbsp;', '').trimStart();
    result = result.replaceAll('&#8211;', '-');
    result = result.replaceAll('\n ', '\n');

    return result;
  }

  const loadDiffViewer = (changeEntry) => {
    const regex = /([0-9]{1,3}[a-zA-Z]{0,2}\s*([$,§]|ART)\s*(\[)[^\]]*\])/g;
    // check if title exists
    let testTitleBefore = changeEntry.before.match(regex);
    let testTitleAfter = changeEntry.after.match(regex);
    if (testTitleBefore || testTitleAfter) {
      // get title
      let titleBefore = testTitleBefore ? testTitleBefore[0] : '';
      let titleAfter = testTitleAfter ? testTitleAfter[0] : '';
      
      // remove title from content
      let contentBefore = changeEntry.before.replace(titleBefore, '');
      let contentAfter = changeEntry.after.replace(titleAfter, '');

      // remove brackets from titles
      titleBefore = titleBefore.replace('[', '').replace(']', '');
      titleAfter = titleAfter.replace('[', '').replace(']', '');

      // Match reference blocks "/(?<!\n) \[[0-9]{1,10}:[0-9]{1,10}\]/g"
      // add a line break before them and remove the prescending space
      contentBefore = contentBefore.replaceAll(/(?<!\n) (\[[0-9]{1,10}:[0-9]{1,10}\])/g, '\n$1');
      contentAfter = contentAfter.replaceAll(/(?<!\n) (\[[0-9]{1,10}:[0-9]{1,10}\])/g, '\n$1');

      let styles = CUSTOM_STYLES;
      
      if (titleBefore === '' && titleAfter !== '') {
        styles = Object.assign({}, CUSTOM_STYLES, {
          variables: {
            light: {
              diffViewerBackground: '#fff',
              emptyLineBackground: '#fff',
              addedBackground: '#eefff3',
              removedBackground: '#fff',
              wordAddedBackground: '#d3fadc',
              wordRemovedBackground: '#ffe3e6',
            },
          }
        });
      }
      
      if (titleBefore !== '' && titleAfter === '') {
        styles = Object.assign({}, CUSTOM_STYLES, {
          variables: {
            light: {
              diffViewerBackground: '#fff',
              addedBackground: '#fff',
              emptyLineBackground: '#fff',
              removedBackground: '#ffd4d280',
              wordAddedBackground: '#d3fadc',
              wordRemovedBackground: '#ffe3e6',
            },
          }
        });
      }

      // render diff viewer with title and content
      return <>
        <span style={{height: '24px', display: 'block'}}/>
        <ReactDiffViewer
          compareMethod={DIFF_METHOD}
          showDiffOnly={false}
          splitView={true}
          hideLineNumbers={true}
          oldValue={replaceHtml(titleBefore)}
          newValue={replaceHtml(titleAfter)}
          styles={Object.assign({}, styles, {
            contentText: {
              fontSize: '14pt',
              fontWeight: '200',
              fontFamily: 'Roboto',
              padding: '2px 4px 12px 4px',
              letterSpacing: '0.01071em',
              color: 'rgba(0, 0, 0, 0.87)',
            }
          })}
        />
        <ReactDiffViewer
          compareMethod={DIFF_METHOD}
          showDiffOnly={false}
          splitView={true}
          hideLineNumbers={true}
          oldValue={replaceHtml(contentBefore)}
          newValue={replaceHtml(contentAfter)}
          styles={styles}
        />
      </>
    }

    // render diff viewer without title
    return <ReactDiffViewer
      compareMethod={DIFF_METHOD}
      showDiffOnly={false}
      splitView={true}
      hideLineNumbers={true}
      oldValue={replaceHtml(changeEntry.before)}
      newValue={replaceHtml(changeEntry.after)}
      styles={CUSTOM_STYLES}
    />
  }

  return (
    <Stack direction="row">
      <Box height={`calc(100vh - ${offsetTop}px)`} display="flex" flexDirection="column" minWidth={270}>
        <Box flex={1} overflow="auto">
          <DenseList selectedIndex={selectedIndex} entries={entries} onSelect={(index, entry) => onSelectChange(index, entry)} />
        </Box>
      </Box>
      <Box height={`calc(100vh - ${offsetTop}px)`} display="flex" flexDirection="column" ml={2}>
        <Box flex={1} overflow="auto" mr={2} >
          {preamble && <Paper style={{ background: '#fff5d0' }} >
            <Box p={2} m={1}>
              <Typography variant="subtitle2" dangerouslySetInnerHTML={{ __html: preamble }} />
            </Box>
          </Paper>
          }
          <Grid container spacing={2} mt={2} mb={2}>
            <Grid item xs={6}>
              <Typography variant="h5" align='center'>Före</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" align='center'>Efter</Typography>
            </Grid>
          </Grid>

          {changeEntries.map((changeEntry, index) =>
            <React.Fragment key={index}>
              {changeEntry.chapter &&
                <Box mt={2} mb={2}>
                  <Typography variant="h4" style={{
                    color: '#233a66',
                    lineHeight: '30px',
                    paddingLeft: '4px',
                  }}>
                    {changeEntry.chapter}
                  </Typography>
                </Box>
              }
              {loadDiffViewer(changeEntry)}
            </React.Fragment>
          )}
        </Box>
      </Box>
    </Stack>
  );
}

SblChanges.propTypes = {
  id: PropTypes.string,
  onLoaded: PropTypes.func,
  future: PropTypes.bool,
  offsetTop: PropTypes.number,
  sfs: PropTypes.string,
}

SblChanges.defaultProps = {
  future: false,
  offsetTop: 130
}


export default SblChanges;