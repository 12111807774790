/**
 * DocumentTypes used in the application
 */
export const DocumentType = {
	SBT: 'sbt',
	SBT_PLUS: 'sbt_plus',
	SBL: 'sbl',
	LEGAL_COMMENT: 'legalcomment',
	ANALYSIS: 'analysis',
	PROP: 'prop',
	NEWSLETTER: 'newsletter',
	FORM: 'form',
	INFO: 'info',
};